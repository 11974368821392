import axios from 'axios';

export class DealService {

    getDeals(config) {
        return axios(config).then(res => res.data);
    }

    addDeals(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }

    updateDeals(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
    deleteDeal(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
    relistDeal(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
}