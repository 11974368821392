import React from 'react';
export const DashBoardCards = (props) => {

    return (
        <div className="card mb-0">
        <div className="flex justify-content-between mb-3">
            <div>
                <span className="block text-500 font-medium mb-3">{props.name}</span>
                <div className="text-900 font-medium text-xl">{props.numb}</div>
            </div>
            <div className={`flex align-items-center justify-content-center bg-${props.iconcolor}-100 border-round`} style={{width: '2.5rem', height: '2.5rem'}}>
                <i className={`pi ${props.icon} text-${props.iconcolor}-500 text-xl`}/>
            </div>
        </div>
        <span className="text-green-500 font-medium">{props.numb2}</span>
        <span className="text-500">{props.sectext}</span>
    </div>
    );
}
