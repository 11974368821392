import axios from 'axios';

export class PreSignedUrlService {

    generatePreSignedUrl(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }

    uploadOnGCP(url,objectUrl,type){
        const config = {
            headers: {
                'Content-Type' : type,
                'Access-Control-Allow-Origin': '*',
            }
        }
        return axios.put(url,objectUrl,config)
        .then(function (response) {
        })
        .catch(function (error) {
            console.log(error);
        });
    }
}