import React, { useState, useEffect, useRef } from 'react';
import {useHistory} from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { MultiSelect } from 'primereact/multiselect';
import "./customButton.css";
import { PreSignedUrlService } from '../service/PreSignedUrlService';
import { Toast } from 'primereact/toast';
import { CategoryService } from '../service/CategoryService';
import { BrandService } from '../service/BrandsService';
import axios from 'axios';
import {CustomLoader} from "../loader";
export const AddBrandsFormLayout = () => {
    const [name,setName] = useState("")
    const toast = useRef(null);
    const [image, setImage] = useState("")
    const [loader, setLoader] = useState(false);
    const [category, setCategory] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const categoryService = new CategoryService();
        const config = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'admin/getCategories',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        categoryService.getCategories(config).then(data => setCategory(data));
    }, []);
    const onCategoryChange = (e) => {
        setSelectedCategory(e.value);
    }
    const onTemplateUpload = (e) => {
        onUpload(e.files)
    }
    const onUpload = (e) => {
        setLoader(true);
        var data = new FormData();
        console.log(e[0]);
        data.append('image', e[0]);

        const config = {
            method: 'post',
            url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: data
        }
        axios(config)
        .then(function (response) {
            console.log(response.data);
            let {url} = response.data;

            setImage(url[0]);
            setLoader(false);
            toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
        })
        .catch(function (error) {
            console.log(error);
            setLoader(false);
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        });
        // const preSignedUrlService = new PreSignedUrlService();
        // preSignedUrlService.generatePreSignedUrl(config).then(data => {if (data.url !== "") {
        //     setImage(data.url);
        //     preSignedUrlService.uploadOnGCP(data.url,objectUrl,type ).then(resp =>{
        //         toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
        //     }).catch((error)=>{
        //         toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        //     })
        // }else{
        //     toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        // }});
    }
    const onSubmit = () =>{
        const brandService = new BrandService();
        let errors = {};

        if (!name) {
            errors.name = 'Name is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.name, life: 3000});
            return;
        }
        if (!image) {
            errors.image = 'image is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.image, life: 3000});
            return;
        }

        const brand = {
            "name": name,
            "images": image,
           // "category":selectedCategory['name'],
            "status":"Active"
        }
        const config = {
            method: 'post',
            url: process.env.REACT_APP_APIBASE+'admin/brands',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: brand
        }
        brandService.addBrand(config).then(data => {if (data.status === "success") {
            toast.current.show({severity:'success', summary: 'Success', detail:'Brand added successfully', life: 3000});
            history.push('/managebrands');
        }else{
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while adding a new brand', life: 3000});
        }});
  }
  const catMulti= [];
  category && category.map((data) => {
        let field={ label: data.name, value: data.name };
        catMulti.push(field);
    })



    return (
        <>

        {loader && CustomLoader()}
        <Toast ref={toast} />
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Add Brand</h5>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12">
                            <label htmlFor="brandName">Brand name</label>
                            <InputText id="brandName" type="text" onChange={(e)=>setName(e.target.value)} required/>
                        </div>
                       {/* <div className="field col-12">
                            <label htmlFor="brandCategory">Brand category</label>
                            <Dropdown value={selectedCategory} options={category} onChange={onCategoryChange} optionLabel="name" placeholder="Select a Category" />
                            {/*<MultiSelect
                                value={selectedCategory}
                                options={catMulti}
                                onChange={onCategoryChange}
                                style={{ minWidth: '12em' }}
                                filter={true}
                                placeholder="Select a Category"
                                id="category"
				            />*/}
                        {/*</div>*/}
                        <div className="field col-12">
                        <label htmlFor="brandImage">Brand image</label>
                        <FileUpload name="demo[]" customUpload={true} uploadHandler={onTemplateUpload} onUpload={onTemplateUpload}
                                    accept="image/png,image/jpg,image/jpeg" maxFileSize={1000000}
                                    emptyTemplate={<p className="p-m-3" style={{fontSize:"15px"}} >Drag and drop files to here to upload.</p>} auto/>
                        </div>
                        <div className="field col-12">
                        <Button className="p-button-rounded formButton" onClick={onSubmit}>Add brand</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
