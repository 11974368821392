import axios from 'axios';

export class BrandService {

    getBrands(config) {
        return axios(config).then(res => res.data);
    }

    addBrand(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
    updateBrand(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
    deleteBrand(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
    relistBrand(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
}