import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton';
import "./customButton.css"
import { PreSignedUrlService } from '../service/PreSignedUrlService';
import { Toast } from 'primereact/toast';
import { CategoryService } from '../service/CategoryService';
import { BrandService } from '../service/BrandsService';
import { DealService } from '../service/DealService';
import axios from 'axios';
export const GeneralFormLayout = () => {
   
    const [category, setCategory] = useState(null);
    const [brands, setBrands] = useState(null);
    const [setting, setSetting] = useState(null);
    const toast = useRef(null);
     const [getSetting, setData] = useState(null);
    
    useEffect(() => {
        const categoryService = new CategoryService();
        const config = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'admin/getCategories',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        categoryService.getCategories(config).then(data => setCategory(data));
        const brandsService = new BrandService();
        const config2 = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'admin/admin/brands',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        brandsService.getBrands(config2).then(data => setBrands(data));
        
        const dealsService = new DealService();
        const config3 = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'home/getadminsettings',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        dealsService.getDeals(config3).then(data =>{ 
           // console.log(data["data"][0]);
            setData(data["data"][0])
        });
    }, []);

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _deal = { ...getSetting };
        _deal[`est_delivery`] = val;
        setData(_deal)
    };

   
    const onSubmit = () =>{
        const dealService = new DealService();
        const d = setting
        const body = {
            "est_delivery": d,
            "status":"Active",
            "_id":"6228d7c9757e8404d8db3c15"
            
        }
        const config = {
            method: 'put',
            url: process.env.REACT_APP_APIBASE+'home/adminsettings/6228d7c9757e8404d8db3c15',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: getSetting
        }
        dealService.addDeals(config).then(data => {if (data.success === true) {
            toast.current.show({severity:'success', summary: 'Success', detail:'EST delivery added successfully', life: 3000});
        }else{
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while adding a new est delivery', life: 3000});
        }});
  }
    return (
        <>
        <Toast ref={toast} />
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Add General Settings</h5>
                    <div className="p-fluid formgrid grid">
                       <div className="field col-12">
                            <label htmlFor="dealCategory">EST Time(in days)</label>
                            <InputText id="dealCategory" type="text" value={getSetting &&  getSetting.est_delivery} onChange={(e)=>onInputChange(e, "est_delivery")} required autoFocus/>
                        </div>
                        <div className="field col-12">
                        <Button className="p-button-rounded formButton" onClick={onSubmit}>Update Setting</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
