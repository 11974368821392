import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { UserService } from "./service/UsersService";
import axios from "axios";
import { Toast } from "primereact/toast";
export const AppTopbar = (props) => {
  const [openProfile, setProfile] = useState(false);
  const [openSettings, setSettings] = useState(false);
  const [users, setUsers] = useState(null);
  const toast = useRef(null);
  const [formData, setFormData] = useState({});

  useLayoutEffect(() => {
    setUsers(props.profile);
    console.log(props.profile);
  }, [props.profile]);

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.newPassword) {
        errors.newPassword = "This field is required.";
      }

      if (!data.confirmPassword) {
        errors.confirmPassword = "This field is required.";
      }

      if (data.newPassword != data.confirmPassword) {
        errors.confirmPassword = "Both password doesn't match.";
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);

      let formData = {
        password: data.newPassword,
        token: props.profile.remember_token,
      };

      var config = {
        method: "post",
        url: process.env.REACT_APP_APIBASE + "changepassword",
        headers: {
          "content-type": "application/json",
        },
        data: formData,
      };

      // console.log('config', props.profile);

      axios(config)
        .then(function (response) {
          formik.resetForm();
          setSettings(false);
          console.log(response.data);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Successfully changed passsword.",
            life: 3000,
          });
        })
        .catch(function (error) {
          console.log(error);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Sorry couldn't change password.",
            life: 3000,
          });
        });
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="layout-topbar">
        <Link to="/" className="layout-topbar-logo">
          <img
            src={
              props.layoutColorMode === "light"
                ? "assets/layout/images/sample.png"
                : "assets/layout/images/sample.png"
            }
            alt="logo"
          />
        </Link>

        <button
          type="button"
          className="p-link  layout-menu-button layout-topbar-button"
          onClick={props.onToggleMenuClick}
        >
          <i className="pi pi-bars" />
        </button>

        <button
          type="button"
          className="p-link layout-topbar-menu-button layout-topbar-button"
          onClick={props.onMobileTopbarMenuClick}
        >
          <i className="pi pi-ellipsis-v" />
        </button>

        <ul
          className={classNames("layout-topbar-menu lg:flex origin-top", {
            "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive,
          })}
        >
          <li>
            <button
              className="p-link layout-topbar-button"
              onClick={() => {
                setSettings(true);
              }}
            >
              <i className="pi pi-cog" />
              <span>Settings</span>
            </button>
          </li>
          <li>
            <button
              className="p-link layout-topbar-button"
              onClick={() => {
                setProfile(true);
              }}
            >
              <i className="pi pi-user" />
              <span>Profile</span>
            </button>
          </li>
          <li>
            <button
              className="p-link layout-topbar-button"
              onClick={() => {
                sessionStorage.clear();
                window.location.href =
                  "https://storage.googleapis.com/mbewebsite/index.html#/";
                window.location.reload();
              }}
            >
              <i className="pi pi-sign-out" />
              <span>Log out</span>
            </button>
          </li>
        </ul>
      </div>
      <Dialog
        visible={openProfile}
        style={{ width: "450px" }}
        header="Admin Profile"
        modal
        onHide={() => {
          setProfile(false);
        }}
      >
        <div className="flex align-items-center justify-content-left">
          <i className="pi pi-user mr-3" style={{ fontSize: "2rem" }} />
          <h1 style={{ fontSize: "1rem", fontWeight: 600 }}>
            Email:{" "}
            <span style={{ fontSize: "0.9rem", fontWeight: 500 }}>
              <pre>{users && users.email}</pre>
            </span>
          </h1>
          <br />
        </div>
        {/* <div className="flex align-items-center justify-content-left">
          <i className="pi pi-id-card mr-3" style={{ fontSize: "2rem" }} />
          <h1 style={{ fontSize: "1rem", fontWeight: 600 }}>
            Last login:{" "}
            <span style={{ fontSize: "0.9rem", fontWeight: 500 }}>
              {new Date().getDate()}/{new Date().getMonth() + 1}/
              {new Date().getFullYear()} at {new Date().getHours()}:
              {new Date().getMinutes()}:{new Date().getSeconds()}
            </span>
          </h1>
        </div> */}
      </Dialog>
      <Dialog
        visible={openSettings}
        style={{ width: "450px" }}
        header="Admin Settings"
        modal
        onHide={() => {
          setSettings(false);
        }}
      >
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="align-items-center justify-content-left">
            <h1 style={{ fontSize: "1rem", fontWeight: 600 }}>
              <label
                htmlFor="newPassword"
                className={classNames({
                  "p-error": isFormFieldValid("newPassword"),
                })}
              >
                New Password
              </label>
              <br />
              <br />
              <InputText
                id="newPassword"
                name="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("newPassword"),
                })}
                type="password"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  marginBottom: "12px",
                  minWidth: "100%",
                }}
              />
            </h1>
            {getFormErrorMessage("newPassword")}
          </div>
          <div className="align-items-center justify-content-left">
            <h1 style={{ fontSize: "1rem", fontWeight: 600 }}>
              <label
                htmlFor="confirmPassword"
                className={classNames({
                  "p-error": isFormFieldValid("confirmPassword"),
                })}
              >
                Confirm Password
              </label>
              <br />
              <br />
              <InputText
                id="confirmPassword"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("confirmPassword"),
                })}
                type="password"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  marginBottom: "12px",
                  minWidth: "100%",
                }}
              />
            </h1>
            {getFormErrorMessage("confirmPassword")}
          </div>
          <br />
          <br />
          <div className="flex align-items-center justify-content-center">
            <Button
              type="submit"
              label="Change Password"
              icon="pi pi-user"
              className="p-button-info"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                marginBottom: "12px",
                width: "100%",
              }}
            />
          </div>
        </form>
      </Dialog>
    </>
  );
};
