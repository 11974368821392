export const ProductTable = ({ c1, c2, c3 }) => {
  return (
    <table style={{ borderCollapse: "collapse", width: "100%" }}>
      <tbody>
        <tr>
          <td
            style={{
              backgroundColor: "#75c9fa",
              fontWeight: "bold",
              padding: 8,
              width: 120,
              fontSize: 14,
            }}
          >
            {c1}
          </td>
          <td style={{ backgroundColor: "#cdebfd", padding: 8, fontSize: 14 }}>
            {c2 && <span>{c3}</span>}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
