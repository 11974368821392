import React, { useState, useEffect, useRef } from "react";
import { ProgressBar } from "primereact/progressbar";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { CategoryService } from "../service/CategoryService";
import { ProductService } from "../service/ProductService";
import { BrandService } from "../service/BrandsService";
import { Dropdown } from "primereact/dropdown";
import { DealService } from "../service/DealService";
import { InputSwitch } from "primereact/inputswitch";
import * as XLSX from "xlsx";
import axios from "axios";
import { CustomLoader } from "../loader";
import { ProductTable } from "../components/ProductTable";
import templateFile from "../assets/template.xlsx";
import FileSaver from "file-saver";

const cross_gray = require("../assets/cross_gray.png");

export const Crud = () => {
  let emptyProduct = {
    name: "",
    images: [],
    description: "",
    specifications: "",
    category: null,
    price: 0,
    quantity: 0,
    rating: 0,
    inventoryStatus: "INSTOCK",
  };
  const [isExport, setIsExport] = useState(false);
  const [edit, setEditUpdate] = useState(false);
  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [relistProductDialog, setRelistProductDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [brands, setBrands] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [category, setCategory] = useState(null);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [keyFeature, setKeyFeature] = useState([]);
  const [deals, setDeals] = useState(null);
  const [productId, setProductId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [li, setLi] = useState([]);
  const [featured, setFeatured] = useState(false);
  const [dealofDay, setDealOfDay] = useState(false);
  const [selectedFeatured, setSelectedFeatured] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [currentProductDetails, setCurrentProductDetails] = useState(null);
  const [disableSave, setDisableSave] = useState(false);
  const [loader, setLoader] = useState(false);
  const [theInputKey, setTheInputKey] = useState(0);
  const dealType = currentProductDetails?.deal?.split(" ")[1];
  const dealPrice = currentProductDetails?.deal?.split(" ")[0];
  let disCountPrice;
  if (dealType === "%") {
    disCountPrice =
      currentProductDetails?.price -
      (currentProductDetails?.price * parseInt(dealPrice, 10)) / 100;
  } else if (dealType === "off") {
    disCountPrice = currentProductDetails?.price - parseInt(dealPrice, 10);
  } else {
    disCountPrice = currentProductDetails?.price;
  }

  const onDealChange = (e) => {
    // console.log(e.value);
    setSelectedDeal(e.value);
  };
  const onBrandChange = (e) => {
    // console.log('#e.value', e.value);
    setSelectedBrand(e.value);
  };
  const onFeaturedChange = (e) => {
    setSelectedFeatured(e.value);
  };

  useEffect(() => {
    const dealsService = new DealService();

    const config0 = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/deals",
      headers: {
        "Content-Type": "application/json",
      },
    };
    dealsService.getDeals(config0).then((data) => {
      let arr = [{ _id: null, status: "Not Active", name: "none" }];
      for (let d of data) {
        // console.log("sadsaddsads",d);
        if (d.status == "Active") {
          arr.push(d);
        }
      }
      setDeals(arr);
    });
    const categoryService = new CategoryService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/getCategories/active",
      headers: {
        "Content-Type": "application/json",
      },
    };
    categoryService.getCategories(config).then((data) => setCategory(data));
    const productService = new ProductService();

    const config22 = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/item",
      //url: process.env.REACT_APP_APIBASE+'admin/item/',
      headers: {
        "Content-Type": "application/json",
      },
    };
    productService
      .getProduct(config22)
      .then((data) => setProducts(data.reverse()));
    const brandsService = new BrandService();
    const config2 = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/brands",
      headers: {
        "Content-Type": "application/json",
      },
    };
    brandsService.getBrands(config2).then((data) => setBrands(data));
  }, []);

  let resetUploadInput = () => {
    let randomString = Math.random().toString(36);
    setTheInputKey(randomString);
  };

  useEffect(() => {
    resetUploadInput();

    const productService = new ProductService();

    const config22 = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/item",
      //url: process.env.REACT_APP_APIBASE+'admin/item/',
      headers: {
        "Content-Type": "application/json",
      },
    };
    productService
      .getProduct(config22)
      .then((data) => setProducts(data.reverse()));
  }, [
    productDialog,
    edit,
    deleteProductDialog,
    relistProductDialog,
    featured,
    dealofDay,
    isExport,
  ]);

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "NGN",
    });
  };

  const removeProductImage = (url) => {
    let images = product.images.filter((item, index) => item != url);
    product.images = images;

    setImages(images);
    setProduct({ ...product });
  };

  const openNew = () => {
    setDisableSave(false);
    setProduct(emptyProduct);
    setSelectedBrand(null);
    setSelectedDeal(null);
    setLi([]);
    setSubmitted(false);
    setProductDialog(true);
    setSelectedFeatured(null);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };
  const hideRelistProductDialog = () => {
    setRelistProductDialog(false);
  };

  const [images, setImages] = useState([]);
  const onTemplateUpload = (e) => {
    // console.log("file1 eee", e.files);

    // return;

    onUpload(e.files);
  };
  const onUpload = (files) => {
    setLoader(true);
    var data = new FormData();

    for (let i = 0; i < files.length; i++) {
      data.append("images", files[i]);
    }

    const config = {
      method: "post",
      url: process.env.REACT_APP_APIBASE + "home/util/presignedurl",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        resetUploadInput();

        // console.log(response.data);
        let { url } = response.data;

        let allImages = images;
        // console.log("url.length", url.length);
        url.map((item, index) => {
          allImages.push(item);
        });

        product.images = allImages;

        setProduct({ ...product });

        setImages(allImages);
        setLoader(false);

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Image uploaded successfully",
          life: 3000,
        });
      })
      .catch(function (error) {
        // console.log(error);
        setLoader(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while upload image",
          life: 3000,
        });
      });
    // for (let r = 0;r<e.length;r++){
    //     const data = {
    //         "fileName":e[r].name,
    //         "fileType":e[r].type.split("/")[1],
    //     }
    //     const objectUrl = e[r]
    //     const type = e[r].type
    //     const config = {
    //         method: 'post',
    //         url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
    //         headers: {
    //             'Content-Type' : 'application/json'
    //         },
    //         data: data
    //     }
    // const preSignedUrlService = new PreSignedUrlService();
    // preSignedUrlService.generatePreSignedUrl(config).then(data => {
    // if (data.url !== "") {
    //     setImage([]);
    //     const nI = []
    //     nI.push(data.url)
    //     setImage(nI);

    //     console.log(data.url);
    //     // preSignedUrlService.uploadOnGCP(data.url,objectUrl,type ).then(resp =>{
    //     //     toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
    //     // }).catch((error)=>{
    //     //     toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload images', life: 3000});
    //     // })
    // }else{
    //     toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload images', life: 3000});
    // }});
    // }
  };
  //product dialog on save
  const saveProduct = () => {
    setSubmitted(true);
    setDisableSave(true);
    const productService = new ProductService();
    let errors = {};
    if (!product.name) {
      setDisableSave(false);
      errors.name = "Name is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.name,
        life: 3000,
      });
      return;
    }
    if (!product.description) {
      setDisableSave(false);
      errors.description = "description is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.description,
        life: 3000,
      });
      return;
    }
    if (!selectedBrand) {
      setDisableSave(false);
      errors.brand = "brand is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.brand,
        life: 3000,
      });
      return;
    }
    if (!product.category) {
      setDisableSave(false);
      errors.category = "category is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.category,
        life: 3000,
      });
      return;
    }
    if (!product.price) {
      setDisableSave(false);
      errors.price = "price is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.price,
        life: 3000,
      });
      return;
    }
    if (!product.quantity) {
      setDisableSave(false);
      errors.quantity = "quantity is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.quantity,
        life: 3000,
      });
      return;
    }
    if (!product.shop_price) {
      setDisableSave(false);
      errors.price = "Shop Price is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.price,
        life: 3000,
      });
      return;
    }
    if (selectedDeal == null) {
      setDisableSave(false);
      errors.deal = "Please select either a deal or none from dropdown.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.deal,
        life: 3000,
      });
      return;
    }
    if (images && images.length === 0) {
      setDisableSave(false);
      errors.images = "image is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.images,
        life: 3000,
      });
      return;
    }

    if (title != "" && description != "") {
      li.push({ title: title, description: description });
    }
    const body = {
      name: product.name,
      description: product.description,
      brand: selectedBrand,
      featured: selectedFeatured && selectedFeatured == "Yes" ? true : false,
      category: product.category,
      keyFeatures: li,
      specifications: product.specifications,
      price: product.price,
      shop_price: product.shop_price,
      quantity: product.quantity,
      deal: selectedDeal,
      images: images,
    };
    // console.log(body);
    if (edit === true) {
      // console.log("log-updatingPost", body);
      const config = {
        method: "put",
        url: process.env.REACT_APP_APIBASE + "item/" + productId,
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      };
      productService.updateProduct(config).then((data) => {
        setDisableSave(false);
        // console.log("log-updatedData", data);
        if (data.status === "success") {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Product updated successfully",
            life: 3000,
          });
          setProductDialog(false);
          setProduct(emptyProduct);
          setEditUpdate(false);
          setProductId("");
          // setImages([]);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error while updating product",
            life: 3000,
          });
        }
      });
      return;
    }
    const config = {
      method: "post",
      url: process.env.REACT_APP_APIBASE + "admin/item",
      //url: process.env.REACT_APP_APIBASE+'admin/item',
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };
    productService.addProduct(config).then((data) => {
      if (data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Product added successfully",
          life: 3000,
        });
        setProductDialog(false);
        setProduct(emptyProduct);
        setImages([]);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while adding a new product",
          life: 3000,
        });
      }
    });
  };

  const editProduct = (product) => {
    // console.log("selectedProduct:", product);

    setDisableSave(false);
    setProduct({ ...product });
    setEditUpdate(true);
    setProductId(product._id);
    setSelectedBrand(product.brand);
    if (deals.length > 0) {
      let product_deal = deals.filter((item) => {
        if (item.name == product.deal) {
          return item;
        }
      });
      setSelectedDeal(product_deal[0]);
    }

    if (product && product.images) {
      setImages(product.images);
    } else {
      // setImage(images)
    }

    setLi(product.keyFeatures ? product.keyFeatures : false);
    setSelectedFeatured(product.featured == true ? "Yes" : "No");
    setProductDialog(true);
  };
  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setProductId(product._id);
    setDeleteProductDialog(true);
  };
  const confirmRelistProduct = (product) => {
    setProduct(product);
    setProductId(product._id);
    setRelistProductDialog(true);
  };
  const deleteProduct = () => {
    const productService = new ProductService();
    const config = {
      method: "delete",
      url: process.env.REACT_APP_APIBASE + "admin/unlist/item/" + productId,
      headers: {
        "Content-Type": "application/json",
      },
    };
    productService.deleteProduct(config).then((data) => {
      if (data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Product unlisted successfully",
          life: 3000,
        });
        setDeleteProductDialog(false);
        setProduct(emptyProduct);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while unlisting product",
          life: 3000,
        });
      }
    });
  };

  const relistProduct = () => {
    const productService = new ProductService();
    const config = {
      method: "put",
      url: process.env.REACT_APP_APIBASE + "admin/relist/item/" + productId,
      headers: {
        "Content-Type": "application/json",
      },
    };
    productService.relistProduct(config).then((data) => {
      if (data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Product relisted successfully",
          life: 3000,
        });
        setRelistProductDialog(false);
        setProduct(emptyProduct);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while relisting product",
          life: 3000,
        });
      }
    });
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };
  const downloadTemplate = () => {
    fetch(templateFile)
      .then((res) => res.blob())
      .then((blob) => FileSaver.saveAs(blob, "template.xlsx"));
  };

  const onCSVImport = () => {
    const file = selectedFile;
    // console.log(file);
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
    // console.log(file);
  };

  // handle import file producr
  const handleFileUpload = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // process CSV data
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));
    //console.log("CSV columns" +JSON.stringify(columns));
    // console.log("CSV LIST" + JSON.stringify(list));
    //setData(list);
    //setColumns(columns);
    let listArray = [];
    list.map((product, index) => {
      const body = {
        name: product.Name,
        description: product.Description,
        brand: product.Brand,
        featured: Boolean(product.Featured),
        category: product.Category,
        specifications: product.Specifications,
        price: Number(product.Price),
        quantity: Number(product.Availability),
        deal: product.Deal,
      };

      listArray.push(body);

      // console.log(body);
    });

    console.log(listArray);
    const config = {
      method: "post",
      url: process.env.REACT_APP_APIBASE + "admin/export/item",
      //url: process.env.REACT_APP_APIBASE+'admin/item/',
      headers: {
        "Content-Type": "application/json",
      },
      data: listArray,
    };
    const productService = new ProductService();
    // console.log(config);
    productService.addProduct(config).then((data) => {
      if (data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Product added successfully",
          life: 3000,
        });
        setProductDialog(false);
        setProduct(emptyProduct);
        setIsExport((state) => !state);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while adding a new product",
          life: 3000,
        });
      }
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };
    _product[`${name}`] = val;

    setProduct(_product);
  };
  const onCategoryChange = (e) => {
    let _product = { ...product };
    _product["category"] = e.value;
    setProduct(_product);
  };
  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };
    _product[`${name}`] = val;

    setProduct(_product);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <input
          type="file"
          accept=".csv,.xlsx,.xls"
          onChange={handleFileUpload}
        />
        <Button
          label="Import"
          icon="pi pi-upload"
          className="p-button-info"
          style={{ marginRight: "20px" }}
          onClick={onCSVImport}
        />
        {/*<FileUpload mode="basic" accept=".csv,.xlsx,.xls" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" customUpload={true} onUpload={handleFileUpload} /> */}
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-info"
          style={{ marginRight: "20px" }}
          onClick={exportCSV}
        />
        <Button
          label="Download Template"
          icon="pi pi-upload"
          className="p-button-info"
          onClick={downloadTemplate}
        />
      </React.Fragment>
    );
  };

  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Code</span>
        {rowData._id.substring(0, 8)}
      </>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span
          style={{
            fontSize: "12px",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => {
            setCurrentProductDetails(rowData);
            setOpenModal(true);
          }}
        >
          {rowData.name.substring(0, 20)}
        </span>
      </>
    );
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Image</span>
        {rowData.images && rowData.images.length > 0 && (
          <img
            src={rowData.images[0]}
            alt={rowData.name}
            className="shadow-2"
            width="90"
          />
        )}
      </>
    );
  };

  const priceBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Price</span>
        {rowData ? (rowData.price ? formatCurrency(rowData.price) : "") : ""}
      </>
    );
  };

  const shopBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Price</span>
        {rowData
          ? rowData.shop_price
            ? formatCurrency(rowData.shop_price)
            : ""
          : ""}
      </>
    );
  };

  const brandBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Brand</span>
        {rowData.brand.name}
      </>
    );
  };
  const categoryBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Category</span>
        {rowData.category.name}
      </>
    );
  };

  const ratingBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Reviews</span>
        {rowData.rating}
      </>
    );
  };

  const featuredBodyTemplate = (rowData) => {
    setFeatured(true);
    return (
      <>
        <span className="p-column-title">Featured</span>
        <InputSwitch
          checked={rowData.featured}
          onChange={(e) => setFeaturedProduct(e.value, rowData._id)}
        />
      </>
    );
  };

  const setFeaturedProduct = (e, id) => {
    const productService = new ProductService();
    const body = {
      featured: e,
    };
    const config = {
      method: "put",
      url: process.env.REACT_APP_APIBASE + "item/" + id,
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };
    productService.updateProduct(config).then((data) => {
      if (data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Featured Status Updated",
          life: 3000,
        });
        setFeatured(false);
        return;
      }
    });
  };

  const dealofDayBodyTemplate = (rowData) => {
    setDealOfDay(true);
    return (
      <>
        <span className="p-column-title">Deal of Day</span>
        <InputSwitch
          checked={rowData.dealofDay}
          onChange={(e) => setDealofDayProduct(e.value, rowData._id)}
        />
      </>
    );
  };
  const setDealofDayProduct = (e, id) => {
    const productService = new ProductService();
    const body = {
      dealofDay: e,
    };
    const config = {
      method: "put",
      url: process.env.REACT_APP_APIBASE + "item/" + id,
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };
    productService.updateProduct(config).then((data) => {
      if (data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Item Set as Deal of the Day",
          life: 3000,
        });
        setDealOfDay(false);
        return;
      }
    });
  };
  const activestatusBodyTemplate = (rowData) => {
    if (rowData.status == "Active") {
      return (
        <>
          <span className="p-column-title">Status</span>
          <span className={`product-badge status-instock`}>
            {rowData.status}
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className="p-column-title">Status</span>
          <span className={`product-badge status-lowstock`}>Not Active</span>
        </>
      );
    }
  };

  const quantitystatusBodyTemplate = (rowData) => {
    let qstatus = "OUTOFSTOCK";
    let qstatus1 = 0;
    if (rowData && rowData.quantity) {
      qstatus1 = rowData.quantity;
      if (rowData.quantity > 15) {
        qstatus = "INSTOCK";
      } else if (rowData.quantity > 0 && rowData.quantity < 15) {
        qstatus = "LOWSTOCK";
      } else if (rowData.quantity < 1) {
        qstatus = "OUTOFSTOCK";
      }
    }
    return (
      <>
        <span className="p-column-title">Availability</span>
        <span className={`product-badge status-${qstatus.toLowerCase()}`}>
          {qstatus1}
        </span>
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          style={{ marginBottom: 5 }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-1"
          onClick={() => editProduct(rowData)}
        />
        {rowData.status == "Active" && (
          <Button
            icon="pi pi-lock"
            className="p-button-rounded p-button-warning"
            onClick={() => confirmDeleteProduct(rowData)}
          />
        )}
        {rowData.status == "not active" && (
          <Button
            icon="pi pi-unlock"
            className="p-button-rounded p-button-info"
            onClick={() => confirmRelistProduct(rowData)}
          />
        )}
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Manage Products</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const productDialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        disabled={disableSave}
        onClick={saveProduct}
      />
    </>
  );
  const deleteProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </>
  );
  const relistProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideRelistProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={relistProduct}
      />
    </>
  );

  const [selectedCatF, setSelectedCatF] = useState(null);
  const catItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option.name}
      </span>
    );
  };
  const onCatChange = (e) => {
    dt.current.filter(
      e.value ? e.value.name : undefined,
      "category.name",
      "equals"
    );
    setSelectedCatF(e.value);
  };
  const catFilter = (
    <Dropdown
      value={selectedCatF}
      options={category}
      onChange={onCatChange}
      itemTemplate={catItemTemplate}
      optionLabel="name"
      placeholder="Select a Category"
      option
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );

  const [selectedBrandF, setSelectedBrandF] = useState(null);
  const brandItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option.name}
      </span>
    );
  };
  const onBrandFChange = (e) => {
    dt.current.filter(
      e.value ? e.value.name : undefined,
      "brand.name",
      "equals"
    );
    setSelectedBrandF(e.value);
  };
  const brandFilter = (
    <Dropdown
      value={selectedBrandF}
      options={brands}
      onChange={onBrandFChange}
      itemTemplate={brandItemTemplate}
      optionLabel="name"
      placeholder="Select a Brand"
      option
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );
  const featuredList = [{ name: "Yes" }, { name: "No" }];
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        {!products ? (
          <ProgressBar
            mode="indeterminate"
            style={{ height: "6px" }}
          ></ProgressBar>
        ) : (
          ""
        )}
        <div className="card">
          <Toast ref={toast} />
          {loader && CustomLoader()}
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={products}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            globalFilter={globalFilter}
            emptyMessage="No products found."
            header={header}
          >
            <Column
              field="name"
              header="Name"
              sortable
              body={nameBodyTemplate}
              style={{ fontSize: "12px" }}
            ></Column>
            <Column
              field="brand.name"
              header="Brand"
              // body={brandBodyTemplate}
              style={{ fontSize: "12px" }}
              filter
              filterElement={brandFilter}
            ></Column>
            <Column
              header="Image"
              body={imageBodyTemplate}
              style={{ fontSize: "12px", overflow: "hidden", width: 150 }}
            ></Column>
            <Column
              field="price"
              header="Price"
              body={priceBodyTemplate}
              sortable
              style={{ fontSize: "12px" }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              body={(rowData) => {
                const dealType = rowData?.deal?.split(" ")[1];
                const dealPrice = rowData?.deal?.split(" ")[0];
                let disCountPrice;
                if (dealType === "%") {
                  disCountPrice =
                    rowData?.price -
                    (rowData?.price * parseInt(dealPrice, 10)) / 100;
                } else if (dealType === "off") {
                  disCountPrice = rowData?.price - parseInt(dealPrice, 10);
                } else {
                  disCountPrice = rowData?.price;
                }
                return rowData?.deal
                  ? // eslint-disable-next-line no-useless-concat
                    disCountPrice + " NGN"
                  : rowData?.price + " NGN";
              }}
            ></Column>
            {/* <Column
              field="shop_price"
              header="Shop Price"
              body={shopBodyTemplate}
              style={{ fontSize: "12px" }}
            ></Column> */}
            <Column
              field="category.name"
              header="Category"
              body={categoryBodyTemplate}
              style={{ fontSize: "12px" }}
              filter
              filterElement={catFilter}
            ></Column>
            {/*<Column field="rating" header="Ratings" body={ratingBodyTemplate} sortable style={{fontSize:"12px"}}></Column>*/}
            <Column
              field="deal"
              header="Deal"
              body={(rowData) => {
                return (
                  <>
                    <span className="p-column-title">Deal</span>
                    {rowData.deal}
                  </>
                );
              }}
              sortable
              style={{ fontSize: "12px" }}
            ></Column>
            <Column
              field="featured"
              header="Featured"
              body={featuredBodyTemplate}
              style={{ fontSize: "12px" }}
            ></Column>
            {/*<Column field="dealofDay" header="Deal of Day" body={dealofDayBodyTemplate} style={{fontSize:"12px"}}></Column>*/}
            <Column
              field="quantity"
              header="Availability"
              body={quantitystatusBodyTemplate}
              sortable
              style={{ fontSize: "12px" }}
            ></Column>
            <Column
              field="status"
              header="Status"
              body={activestatusBodyTemplate}
              style={{ fontSize: "12px" }}
            ></Column>
            <Column
              body={actionBodyTemplate}
              style={{ fontSize: "12px" }}
            ></Column>
          </DataTable>

          <Dialog
            visible={productDialog}
            style={{ width: "50vw" }}
            header="Product Details"
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={hideDialog}
          >
            <div className="productImageWrap">
              {product.images &&
                product.images.length > 0 &&
                product.images.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "inline-block",
                        marginLeft: "10px",
                        marginTop: 5,
                      }}
                    >
                      <span
                        style={{
                          right: 0,
                          marginLeft: "-12px",
                          cursor: "pointer",
                          position: "relative",
                          top: -3,
                        }}
                        onClick={() => removeProductImage(item)}
                      >
                        <img src={cross_gray} alt={cross_gray} width={20} />
                      </span>
                      <img
                        src={item}
                        alt=""
                        style={{ float: "left" }}
                        width="200"
                        className="mt-0 mx-auto mb-5 block shadow-2"
                      />
                    </div>
                  );
                })}
            </div>
            <div className="field">
              <label htmlFor="name">Name</label>
              <InputText
                id="name"
                value={product.name}
                onChange={(e) => onInputChange(e, "name")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !product.name,
                })}
              />
              {submitted && !product.name && (
                <small className="p-invalid">Name is required.</small>
              )}
            </div>
            <div className="field">
              <label htmlFor="description">Description</label>
              <InputTextarea
                id="description"
                value={product.description}
                onChange={(e) => onInputChange(e, "description")}
                required
                rows={3}
                cols={20}
              />
            </div>
            <div className="field">
              <label htmlFor="description">Featured Product</label>
              <Dropdown
                value={selectedFeatured}
                options={["Yes", "No"]}
                onChange={onFeaturedChange}
                placeholder={
                  selectedFeatured ? selectedFeatured : "Select a Featured"
                }
              />
            </div>
            <div className="field">
              <label htmlFor="description">Brand</label>
              <Dropdown
                value={selectedBrand}
                options={brands}
                onChange={onBrandChange}
                optionLabel="name"
                placeholder={
                  selectedBrand ? selectedBrand["name"] : "Select a Brand"
                }
              />
            </div>

            <div className="field">
              <label className="mb-3">Category</label>
              <div className="formgrid grid">
                {category &&
                  category.map((data, index) => {
                    return (
                      <div className="field-radiobutton col-6" key={index}>
                        <RadioButton
                          inputId="category1"
                          name="category"
                          value={data}
                          onChange={onCategoryChange}
                          checked={product?.category?._id === data?._id}
                        />
                        <label htmlFor="category1">{data["name"]}</label>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="field">
              <label htmlFor="specifications">Specifications</label>
              <InputTextarea
                id="specifications"
                value={product.specifications}
                onChange={(e) => onInputChange(e, "specifications")}
                required
                rows={3}
                cols={20}
              />
            </div>
            <div className="formgrid grid">
              <div className="field col">
                <label htmlFor="price">Price</label>
                <InputNumber
                  id="price"
                  value={product.price}
                  onValueChange={(e) => onInputNumberChange(e, "price")}
                  mode="currency"
                  currency="NGN"
                  locale="en-US"
                />
              </div>
              <div className="field col">
                <label htmlFor="quantity">Quantity</label>
                <InputNumber
                  id="quantity"
                  value={product.quantity}
                  onValueChange={(e) => onInputNumberChange(e, "quantity")}
                  integeronly
                />
              </div>
            </div>
            <div className="formgrid grid">
              <div className="field col">
                <label htmlFor="price">Cost</label>
                <InputNumber
                  id="shop_price"
                  value={product.shop_price}
                  onValueChange={(e) => onInputNumberChange(e, "shop_price")}
                  mode="currency"
                  currency="NGN"
                  locale="en-US"
                />
              </div>
            </div>

            <div className="field">
              <label htmlFor="deal">Deal</label>
              <Dropdown
                value={selectedDeal}
                options={deals}
                onChange={onDealChange}
                optionLabel="name"
                placeholder={
                  selectedDeal && selectedDeal["name"] != ""
                    ? selectedDeal["name"]
                    : "Select a Deal"
                }
              />
            </div>
            <FileUpload
              name="demo[]"
              key={theInputKey || ""}
              customUpload={true}
              multiple
              uploadHandler={onTemplateUpload}
              onUpload={onTemplateUpload}
              accept="image/png,image/jpg,image/jpeg"
              maxFileSize={1000000}
              emptyTemplate={
                <p className="p-m-3" style={{ fontSize: "15px" }}>
                  Drag and drop files to here to upload.
                </p>
              }
              auto
            />
          </Dialog>

          <Dialog
            header="Product Details"
            visible={openModal}
            style={{ width: "50vw" }}
            onHide={() => setOpenModal(false)}
            modal
          >
            <div style={{ textAlign: "center" }}>
              {currentProductDetails &&
                currentProductDetails?.images.map((item, index) => {
                  return (
                    <img
                      style={{
                        width: 180,
                        margin: 10,
                        objectFit: "contain",
                      }}
                      src={item}
                    />
                  );
                })}
            </div>
            <ProductTable
              c1="Name"
              c2={currentProductDetails && currentProductDetails?.name}
              c3={currentProductDetails?.name}
            />
            <ProductTable
              c1="Brand"
              c2={currentProductDetails && currentProductDetails?.brand}
              c3={currentProductDetails?.brand.name}
            />
            <ProductTable
              c1="Catergory"
              c2={currentProductDetails && currentProductDetails?.category}
              c3={currentProductDetails?.category.name}
            />
            <ProductTable
              c1="Price"
              c2={currentProductDetails && currentProductDetails?.price}
              c3={
                currentProductDetails?.deal
                  ? disCountPrice + " NGN"
                  : currentProductDetails?.price + " NGN"
              }
            />
            <ProductTable
              c1="Cost"
              c2={currentProductDetails && currentProductDetails?.shop_price}
              c3={currentProductDetails?.shop_price + " NGN"}
            />
            <ProductTable
              c1="Deals"
              c2={currentProductDetails && currentProductDetails?.deal}
              c3={currentProductDetails?.deal}
            />
            <ProductTable
              c1="Quantity"
              c2={currentProductDetails && currentProductDetails?.quantity}
              c3={currentProductDetails?.quantity}
            />{" "}
            <ProductTable
              c1="Rating"
              c2={currentProductDetails && currentProductDetails?.rating}
              c3={currentProductDetails?.rating}
            />
          </Dialog>

          <Dialog
            visible={deleteProductDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteProductDialogFooter}
            onHide={hideDeleteProductDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {product && (
                <span>
                  Are you sure you want to unlist <b>{product.name}</b> from
                  user app?
                </span>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={relistProductDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={relistProductDialogFooter}
            onHide={hideRelistProductDialog}
          >
            <div className="flex align-items-center justify-content-center">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {product && (
                <span>
                  Are you sure you want to relist <b>{product.name}</b> on user
                  app?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
