import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect, useRef } from 'react';
import {useHistory} from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton';
import "./customButton.css"
import { PreSignedUrlService } from '../service/PreSignedUrlService';
import { Toast } from 'primereact/toast';
import { CategoryService } from '../service/CategoryService';
import { BrandService } from '../service/BrandsService';
import { DealService } from '../service/DealService';
import { ProductService } from '../service/ProductService';
import axios from 'axios';

export const ShippingFormLayout = () => {
    const history = useHistory();
    const [radio, setRadio] = useState('Brand');
    const [category, setCategory] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedTypeD,setSelectedTypeD]=useState(null);
    const [brands, setBrands] = useState(null);
    const [deal, setDeal] = useState(null);
    const [priceVal, setPrice] = useState(null);
    const toast = useRef(null);
    const [image, setImage] = useState("")
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [product, setProducts] = useState(null);
    const [selectedSingle, setSelectedSingle] = useState(null);
    const onCategoryChange = (e) => {
        setSelectedCategory(e.value);
    }
    const onTypeDChange = (e) => {
        setSelectedTypeD(e.value);
    }
    const onBrandChange = (e) => {
        setSelectedBrand(e.value);
    }
    const onSingleChange = (e) => {
        setSelectedSingle(e.value);
    }
    useEffect(() => {
        const categoryService = new CategoryService();
        const config = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'admin/getCategories',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        categoryService.getCategories(config).then(data => setCategory(data));
        const brandsService = new BrandService();
        const config2 = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'admin/admin/brands',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        brandsService.getBrands(config2).then(data => setBrands(data));
        const productService = new ProductService();

            const config22 = {
                method: "get",
                url: process.env.REACT_APP_APIBASE+"admin/item",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            productService.getProduct(config22).then((data) => setProducts(data));
    }, []);

    const onTemplateUpload = (e) => {
        onUpload(e.files)
    }
    const onUpload = (e) => {
        var data = new FormData();
        data.append('image', e[0]);

        const config = {
            method: 'post',
            url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: data
        }
        axios(config)
        .then(function (response) {
            console.log(response.data);
            let {url} = response.data;

            setImage(url);
            toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
        })
        .catch(function (error) {
            console.log(error);
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        });
        // const data = {
        //     "fileName":e[0].name,
        //     "fileType":e[0].type.split("/")[1],
        // }
        // const objectUrl = e[0]
        // const type = e[0].type
        // const config = {
        //     method: 'post',
        //     url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
        //     headers: {
        //         'Content-Type' : 'application/json'
        //     },
        //     data: data
        // }
        // const preSignedUrlService = new PreSignedUrlService();
        // preSignedUrlService.generatePreSignedUrl(config).then(data => {if (data.url !== "") {
        //     setImage(data.url);
        //     preSignedUrlService.uploadOnGCP(data.url,objectUrl,type ).then(resp =>{
        //         toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
        //     }).catch((error)=>{
        //         toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        //     })
        // }else{
        //     toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        // }});
    }
    const onSubmit = () =>{
        const dealService = new DealService();
        const t = selectedTypeD;
        const price = priceVal;
        let errors = {};
        if (!radio) {
            errors.radio = 'Type is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.radio, life: 3000});
            return;
        }
        // if (!selectedBrand || !selectedCategory || !selectedSingle) {
        //     errors.product = 'Product is required.';
        //     toast.current.show({severity:'error', summary: 'Error', detail: errors.product, life: 3000});
        //     return;
        // }

        if (!price) {
            errors.price = 'price is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.price, life: 3000});
            return;
        }


        if (!selectedTypeD) {
            errors.price = 'price type is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.price, life: 3000});
            return;
        }



        let product;
        if(radio == "Brand"){

            if (!selectedBrand) {
                errors.brand = 'Brand is required.';
                toast.current.show({severity:'error', summary: 'Error', detail: errors.brand, life: 3000});
                return;
            }else{
                product = selectedBrand.toString();
            }
        }else if(radio == "Category"){

            if (!selectedCategory) {
                errors.category = 'Catagory is required.';
                toast.current.show({severity:'error', summary: 'Error', detail: errors.category, life: 3000});
                return;
            }else{
                product = selectedCategory.toString();
            }
        }else if(radio == "Single"){

            if (!selectedSingle) {
                errors.product = 'Products is required.';
                toast.current.show({severity:'error', summary: 'Error', detail: errors.product, life: 3000});
                return;
            }else{
                product = selectedSingle.toString();
            }
        }else{
            product ='';
        }
        const body = {
            "shipping_type":radio,
            "shipping_product":product,
            "shipping_value":price,
            "shipping_amount_type":t
        }
        const config = {
            method: 'post',
            url: process.env.REACT_APP_APIBASE+'home/shippingtax',
            //url: process.env.REACT_APP_APIBASE+'home/shippingtax',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: body
        }
        dealService.addDeals(config).then(data => {
            if (data.success === true) {
                toast.current.show({severity:'success', summary: 'Success', detail:'Shipping added successfully', life: 3000});
                history.push('/shipping');
            }else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Error while adding a new shipping', life: 3000});
            }
        });
  }

const brandMulti= [];
brands && brands.map((data) => {
    let field={ label: data.name, value: data.name };
    brandMulti.push(field);
})

const categoryMulti= [];
category && category.map((data) => {
    let field={ label: data.name, value: data.name };
    categoryMulti.push(field);
})

const productMulti= [];
product && product.map((data) => {
    let field={ label: data.name, value: data.name };
    productMulti.push(field);
})

    return (
        <>
        <Toast ref={toast} />
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Add Shippings</h5>
                    <div className="p-fluid formgrid grid">
                        <div className="p-field-radiobutton">
                            <RadioButton inputId="radio1" name="radio" value="Brand" onChange={(e) => setRadio(e.value)} checked={radio === 'Brand'} />
                            <label htmlFor="radio1">  Brand</label>
                        </div>
                        <div className="p-field-radiobutton" style={{marginLeft:"20px"}}>
                            <RadioButton inputId="radio2" name="radio" value="Category" onChange={(e) => setRadio(e.value)} checked={radio === 'Category'} />
                            <label htmlFor="radio2">  Category</label>
                        </div>
                        <div className="p-field-radiobutton" style={{marginLeft:"20px"}}>
                        <RadioButton inputId="radio3" name="radio" value="Single" onChange={(e) => setRadio(e.value)} checked={radio === 'Single'} style={{marginLeft:"15px"}}/>
                            <label htmlFor="radio3"> Single</label>
                        </div>
                        {radio==="Brand" && <div className="field col-12"  style={{marginTop:"20px"}} >
                            <label htmlFor="dealName">Brand</label>
                            {/*<Dropdown value={selectedBrand} options={brands} onChange={onBrandChange} optionLabel="name" placeholder="Select a Brand" />*/}

                            <MultiSelect
                                value={selectedBrand}
                                options={brandMulti}
                                onChange={onBrandChange}
                                style={{ minWidth: '12em' }}
                                filter={true}
                                placeholder="Select a Brand"
                                id="brand"
				            />
                        </div>}
                        {radio==="Category" && <div className="field col-12" style={{marginTop:"20px"}}>
                            <label htmlFor="dealName">Category</label>
                            {/*<Dropdown value={selectedCategory} options={category} onChange={onCategoryChange} optionLabel="name" placeholder="Select a Category" />*/}
                            <MultiSelect
                                value={selectedCategory}
                                options={categoryMulti}
                                onChange={onCategoryChange}
                                style={{ minWidth: '12em' }}
                                filter={true}
                                placeholder="Select a Category"
                                id="category"
				            />
                        </div>}
                        {radio==="Single" && <div className="field col-12" style={{marginTop:"20px"}}>
                         <label htmlFor="dealName">Single</label>
                            <MultiSelect
                                value={selectedSingle}
                                options={productMulti}
                                onChange={onSingleChange}
                                style={{ minWidth: '12em' }}
                                filter={true}
                                placeholder="Select Single Product "
				            />
                        </div>}
                        <div className="field col-12">

                        </div>
                        <div className="field col-12">
                            <label htmlFor="dealCategory">Shipping Price</label>
                            <InputText id="dealCategory" type="number" onChange={(e)=>setPrice(e.target.value)}/>
                        </div>
                        <div className="field col-12">
                            <label htmlFor="dealCategory">Price Type</label>
                            <Dropdown value={selectedTypeD} options={["fixed"]} onChange={onTypeDChange} placeholder="Select a Type" />
                        </div>

                        <div className="field col-12">
                        <Button className="p-button-rounded formButton" onClick={onSubmit}>Add Shippings</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
