import React, { useState, useEffect, useRef, useMemo } from "react";
import "./customButton.css";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { InputText } from "primereact/inputtext";
import { SpeedDial } from "primereact/speeddial";
import { Button } from "primereact/button";
import "./accordianCustom.css";
import axios from "axios";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { UserService } from "../service/UsersService";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { setActiveOrNewChat } from "../store/slices";
import socket from "../socket";

export const ChatLayout = () => {
  const toast = useRef(null);
  const [semail, setSEmail] = useState("");
  const [sname, setSName] = useState("");
  const [sid, setSid] = useState("");
  const [simage, setSImage] = useState("");
  const [currentChat, setcurrentChat] = useState("");
  const [currentC, setCurrentC] = useState([]);
  const [chatStatus, setChatStatus] = useState([]);
  const [cD, setCD] = useState();
  // const [socketUrl, setSocketUrl] = useState("wss://api.drop-deliveryapp.com/notification/v1/ws?roomid=broadcast");
  // const [socketCUrl, setSocketCUrl] = useState("wss://api.drop-deliveryapp.com/notification/v1/ws?roomid=");
  const messageHistory = useRef([]);
  const messagesEndRef = useRef(null);
  const [chatList, setChatList] = useState([]);
  const [achatList, setAChatList] = useState([]);
  const [archatList, setArChatList] = useState([]);
  // const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  // const { sendCMessage, lastCMessage, readyCState } = useWebSocket(socketCUrl);
  // messageHistory.current = useMemo(() => messageHistory.current.concat(lastMessage), [lastMessage]);
  // messageHistory.current = useMemo(() => messageHistory.current.concat(lastCMessage), [lastCMessage]);
  const [chatID, setChatID] = useState("message");
  const [adminMsg, setAdminMSG] = useState("");

  // const chatList = useSelector(state => state.chats);
  const dispatch = useDispatch();

  const getChats = () => {
    const userService = new UserService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "home/chats?status=New",
      headers: {
        "Content-Type": "application/json",
      },
    };

    userService.getUsers(config).then((data) => {
      console.log("getNewchat", data);
      dispatch(setActiveOrNewChat(data.data));
      setChatList(data.data);
    });
    const config1 = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "home/chats?status=Started",
      headers: {
        "Content-Type": "application/json",
      },
    };
    userService.getUsers(config1).then((data) => {
      // dispatch(setActiveOrNewChat(data.data));
      setAChatList(data.data);
    });
    const config2 = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "home/chats?status=Completed",
      headers: {
        "Content-Type": "application/json",
      },
    };
    userService.getUsers(config2).then((data) => setArChatList(data.data));
  };
  useEffect(() => {
    //console.log("currentChat", +currentChat);
    getChats();
    getCurrentChats();
    socket.on("connect", (socket) => {
      console.log("socketio connected");
      getChats();
      getCurrentChats();
    });
    socket.on("new_chat_arrived", (msg) => {
      console.log("msg", msg);
      if (chatID == msg.chat_id) {
        console.log("chatID", chatID);
        // if('chatId' in msg){
        //     setChatID(msg.chatId);
        // }else{
        //     console.log("message#######", msg);
        // }
        getChats();
        getCurrentChats();
      } else {
        if ("chat_id" in msg) {
          //only get all chat not current chat
          getChats();
          // getCurrentChats();
        }
      }
    });

    if (chatID != "message") {
      console.log("chatId", chatID);

      // const webSocket = new WebSocket(`wss://api.drop-deliveryapp.com/notification/v1/ws?roomid=${chatID}`);

      // webSocket.onopen = ()=>{
      //     getChats();
      //     getCurrentChats();
      //     console.log('#########socket connected ', chatID);
      // }
      // webSocket.onmessage = (message) => {
      //     // setMessages(prev => [...prev, message.data]);

      //     getChats();
      //     getCurrentChats();
      //     console.log(message);
      // };

      // socket.join('chatId_'+chatID);

      // io.on("connection", async (socket) => {

      //     socket.join('chatId_'+chatID);

      //     // and then later
      //     io.to(userId).emit("hi");
      // });

      // socket.on('chatId_'+chatID, (msg)=>{
      //     getChats();
      //     getCurrentChats();
      // });

      // console.log('chat page use effect');
      // return () => webSocket.close();
    }
  }, [chatID]); //currentC

  console.log("achatList.length", achatList.length);
  console.log("chatList.length", chatList.length);

  // const connectionStatus = {
  //     [ReadyState.CONNECTING]: "Connecting",
  //     [ReadyState.OPEN]: "Open",
  //     [ReadyState.CLOSING]: "Closing",
  //     [ReadyState.CLOSED]: "Closed",
  //     [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  // }[readyState];
  // const connectionCStatus = {
  //     [ReadyState.CONNECTING]: "Connecting",
  //     [ReadyState.OPEN]: "Open",
  //     [ReadyState.CLOSING]: "Closing",
  //     [ReadyState.CLOSED]: "Closed",
  //     [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  // }[readyCState];

  const acceptChat = (e) => {
    //console.log("accept chat")
    console.log("all data" + JSON.stringify(e));
    //console.log("all data id" +e._id);
    setSEmail(e.senderEmail);
    setSName(e.senderName);
    setSImage(e.chat[0].user.avatar);
    setcurrentChat(e._id);
    setCurrentC(e.chat);
    setSid(e.senderId);
    setChatStatus(e.status);
    setCD(e);
    // setSocketCUrl(`wss://api.drop-deliveryapp.com/notification/v1/ws?roomid=${e._id}`);
    //console.log("currentChat", +currentChat);
    setChatID(e._id);
    //console.log("chat id in Accept" +chatID);

    // console.log('websocket url', `wss://api.drop-deliveryapp.com/notification/v1/ws?roomid=${e._id}`);
  };

  const getCurrentChats = () => {
    const userService = new UserService();
    //console.log("currentChat", +currentChat);
    //console.log("chat id in cuurent" +chatID);
    //if(!isNaN(currentChat)){
    // const cc="623ad14cce89ed8e7ff3a4cd";
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "home/user/chat/" + currentChat,
      headers: {
        "Content-Type": "application/json",
      },
    };
    userService.getUsers(config).then((data) => {
      if (data.success == true) {
        // console.log("data is:", +data.data);
        setCurrentC(data.data["chat"]);
      } else {
        //console.log("data not get");
      }
      //setCurrentC(data.data);
    });
    // }
  };

  const startChat = (e) => {
    let token = sessionStorage.getItem("token");
    var decoded = jwt_decode(token);
    const d = {
      receiverId: decoded["id"],
    };
    const userService = new UserService();
    const config = {
      method: "put",
      url: process.env.REACT_APP_APIBASE + "home/message/" + currentChat,
      headers: {
        "Content-Type": "application/json",
      },
      data: d,
    };
    userService.UpdateChat(config).then((data) => {
      console.log("getchat called", data);
      getChats();
      getCurrentChats();
      setChatStatus("Started");
    });
  };

  const resolveChat = (e) => {
    const userService = new UserService();
    const d = {
      status: "Completed",
    };
    const config = {
      method: "put",
      url: process.env.REACT_APP_APIBASE + "home/message/" + currentChat,
      headers: {
        "Content-Type": "application/json",
      },
      data: d,
    };
    userService.UpdateChat(config).then((data) => {
      setChatStatus("Completed");
      getChats();
      getCurrentChats();

      let msg = { chatID, endofchat: true };
      socket.emit("message", msg);

      // dispatch(setActiveOrNewChat([]));
      // window.location.reload(false);
    });
  };

  const message = () => {
    let token = sessionStorage.getItem("token");
    var decoded = jwt_decode(token);
    const d = {
      senderId: decoded["id"],
      usermsg: adminMsg,
    };
    const userService = new UserService();
    const config = {
      method: "put",
      url: process.env.REACT_APP_APIBASE + "home/message/" + currentChat,
      headers: {
        "Content-Type": "application/json",
      },
      data: d,
    };
    userService.UpdateChat(config).then((data) => {
      let msg = { chatID };
      socket.emit("message", msg);

      if (data.success === true) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Messge added successfully",
          life: 3000,
        });
        getChats();
        getCurrentChats();
        setAdminMSG(" ");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while adding a new Message",
          life: 3000,
        });
      }
      //window.location.reload(false);
    });
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const items = [
    {
      label: "Add",
      icon: "pi pi-pencil",
      command: () => {
        toast.current.show({
          severity: "info",
          summary: "Add",
          detail: "Data Added",
        });
      },
    },
    {
      label: "Update",
      icon: "pi pi-refresh",
      command: () => {
        toast.current.show({
          severity: "success",
          summary: "Update",
          detail: "Data Updated",
        });
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        toast.current.show({
          severity: "error",
          summary: "Delete",
          detail: "Data Deleted",
        });
      },
    },
  ];
  //console.log("currentC" +JSON.stringify(currentC));
  //console.log("sid" +sid);
  return (
    <>
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 lg:col-5">
          <div className="card">
            <div>
              <img
                src={"assets/layout/images/sample.png"}
                alt="Logo"
                height="40"
                className="mr-2"
              />{" "}
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  marginTop: "10px",
                  position: "absolute",
                }}
              >
                Chat
              </span>
            </div>
            <Divider />
            <Accordion className="accordion-custom" activeIndex={0}>
              <AccordionTab
                header={
                  <React.Fragment>
                    <span style={{ fontSize: "14px" }}>
                      New Conversations
                      <span style={{ marginLeft: "7px" }}>
                        <Badge
                          value={
                            chatList
                              ? chatList.length > 0
                                ? chatList.length
                                : "0"
                              : "0"
                          }
                          severity="success"
                          className="p-mr-2"
                        ></Badge>
                      </span>
                    </span>
                  </React.Fragment>
                }
              >
                <div className="grid">
                  {chatList && chatList.length > 0 ? (
                    chatList.map((data, index) => {
                      return (
                        <>
                          <div
                            className="col-12 custHover"
                            onClick={() => {
                              console.log(
                                "data.chat[0].user.avatar",
                                data.chat[0].user.avatar
                              );
                              acceptChat(data);
                            }}
                            key={index}
                          >
                            <div className="grid">
                              <div className="col-4">
                                <Avatar
                                  image={
                                    data.chat[0].user.avatar
                                      ? data.chat[0].user.avatar
                                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZldEPvFgz5ysckNAa5ztdf4Exw00bb1a9qA&usqp=CAU"
                                  }
                                  className="p-mr-2"
                                  size="large"
                                  shape="circle"
                                />{" "}
                              </div>
                              <div className="col-8">
                                <div
                                  style={{
                                    marginTop: "20px",
                                    marginLeft: "0px",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {data.senderName}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <h1 style={{ fontSize: "14px", textAlign: "center" }}>
                      No new chats available currently
                    </h1>
                  )}
                </div>
              </AccordionTab>
              <AccordionTab
                header={
                  <React.Fragment>
                    <span style={{ fontSize: "14px" }}>
                      Active Conversations{" "}
                      <span style={{ marginLeft: "7px" }}>
                        <Badge
                          value={
                            achatList
                              ? achatList.length > 0
                                ? achatList.length
                                : "0"
                              : "0"
                          }
                          severity="success"
                          className="p-mr-2"
                        ></Badge>
                      </span>
                    </span>
                  </React.Fragment>
                }
              >
                <div className="grid">
                  {achatList && achatList.length > 0 ? (
                    achatList.map((data, index) => {
                      return (
                        <>
                          <div
                            className="col-12 custHover"
                            onClick={() => {
                              acceptChat(data);
                              console.log("click 2");
                            }}
                            key={index}
                          >
                            <div className="grid">
                              <div className="col-4">
                                <Avatar
                                  image={
                                    data.chat[0].user.avatar
                                      ? data.chat[0].user.avatar
                                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZldEPvFgz5ysckNAa5ztdf4Exw00bb1a9qA&usqp=CAU"
                                  }
                                  className="p-mr-2"
                                  size="large"
                                  shape="circle"
                                />{" "}
                              </div>
                              <div className="col-8">
                                <div
                                  style={{
                                    marginTop: "20px",
                                    marginLeft: "0px",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {data.senderName}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <h1 style={{ fontSize: "14px", textAlign: "center" }}>
                      No active chats available currently
                    </h1>
                  )}
                </div>
              </AccordionTab>
              <AccordionTab
                header={
                  <React.Fragment>
                    <span style={{ fontSize: "14px" }}>
                      Archived Conversations{" "}
                      <span style={{ marginLeft: "7px" }}>
                        {" "}
                        <Badge
                          value={
                            archatList
                              ? archatList.length > 0
                                ? archatList.length
                                : "0"
                              : "0"
                          }
                          severity="success"
                          className="p-mr-2"
                        ></Badge>
                      </span>
                    </span>
                  </React.Fragment>
                }
              >
                <div className="grid">
                  {archatList && archatList.length > 0 ? (
                    archatList.map((data, index) => {
                      return (
                        <>
                          <div
                            className="col-12 custHover"
                            onClick={() => {
                              acceptChat(data);
                              console.log("click 3");
                            }}
                            key={index}
                          >
                            <div className="grid">
                              <div className="col-4">
                                <Avatar
                                  image={
                                    data.chat[0].user.avatar
                                      ? data.chat[0].user.avatar
                                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZldEPvFgz5ysckNAa5ztdf4Exw00bb1a9qA&usqp=CAU"
                                  }
                                  className="p-mr-2"
                                  size="large"
                                  shape="circle"
                                />{" "}
                              </div>
                              <div className="col-8">
                                <div
                                  style={{
                                    marginTop: "20px",
                                    marginLeft: "0px",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {data.senderName}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <h1 style={{ fontSize: "14px", textAlign: "center" }}>
                      No archived chats available currently
                    </h1>
                  )}
                </div>
              </AccordionTab>
            </Accordion>
          </div>
        </div>
        <div className="col-12 lg:col-7">
          <pre>{chatStatus}</pre>
          {chatStatus && chatStatus != "" && (
            <div className="grid">
              <div className="col-12 lg:col-6">
                <div className="card">
                  <img
                    src={
                      simage
                        ? simage
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZldEPvFgz5ysckNAa5ztdf4Exw00bb1a9qA&usqp=CAU"
                    }
                    width="100"
                    style={{
                      borderRadius: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "flex",
                    }}
                  />
                  <h1
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      justifyContent: "left",
                      marginLeft: "20px",
                    }}
                  >
                    <i
                      className="pi pi-envelope"
                      style={{ marginRight: "12px" }}
                    ></i>{" "}
                    <span style={{ fontSize: "14px", color: "grey" }}>
                      {semail ? semail : "Active chat email id"}
                    </span>
                  </h1>
                  <h1
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      justifyContent: "left",
                      marginLeft: "20px",
                    }}
                  >
                    <i
                      className="pi pi-user"
                      style={{ marginRight: "12px" }}
                    ></i>{" "}
                    <span style={{ fontSize: "14px", color: "grey" }}>
                      {sname ? sname : "Active chat name"}
                    </span>
                  </h1>
                </div>
              </div>
              <div className="col-12 lg:col-6">
                <div className="card">
                  {/* <Button label="Archive Chat" icon="pi pi-print" className="p-button-info"  style={{marginLeft:"auto",marginRight:"auto",display:"flex",marginBottom:"12px",width:"100%"}}/>

                        <Button label="Download Chat" icon="pi pi-arrow-down" className="p-button-warning"  style={{marginLeft:"auto",marginRight:"auto",display:"flex",marginBottom:"12px",width:"100%"}}/> */}

                  {chatStatus && chatStatus == "New" && (
                    <Button
                      label="Start Chat"
                      icon="pi pi-check"
                      className="p-button-success"
                      onClick={startChat}
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "flex",
                        marginBottom: "12px",
                        width: "100%",
                      }}
                    />
                  )}

                  {chatStatus && chatStatus == "Started" && (
                    <Button
                      label="End Chat"
                      icon="pi pi-trash"
                      className="p-button-danger"
                      onClick={resolveChat}
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "flex",
                        width: "100%",
                      }}
                    />
                  )}
                  {chatStatus && chatStatus == "Started" && (
                    <>
                      {cD && cD.receiverjointime && (
                        <h1 style={{ fontSize: "12px", textAlign: "left" }}>
                          Chat Started on : <br /> <br />
                          {cD.receiverjointime.split("T")[0]}{" "}
                          {cD.receiverjointime.split("T")[0]}
                        </h1>
                      )}
                    </>
                  )}
                  {chatStatus && chatStatus == "Completed" && (
                    <>
                      {cD && cD.endchat && (
                        <h1 style={{ fontSize: "12px", textAlign: "left" }}>
                          Chat Resolved on : <br />
                          <br />
                          {cD.endchat.split("T")[0]} {cD.endchat.split("T")[0]}
                        </h1>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {chatStatus && (
            <div className="card1">
              <div className="grid">
                {chatStatus && chatStatus == "Completed" && (
                  <div
                    className="col-12"
                    style={{ maxHeight: "600px", overflow: "auto" }}
                  >
                    {currentC &&
                      sid &&
                      currentC.map((data, index) => {
                        return (
                          <>
                            {data._id && data.user._id === sid && (
                              <div className="row" key={index}>
                                <div
                                  className="card"
                                  style={{ maxWidth: "400px" }}
                                >
                                  {data.text}
                                </div>
                              </div>
                            )}
                            {data._id && data.user._id !== sid && (
                              <div
                                key={index}
                                className="row"
                                style={{
                                  justifyContent: "right",
                                  display: "flex",
                                }}
                              >
                                <div
                                  className="card"
                                  style={{
                                    maxWidth: "400px",
                                    backgroundColor: "black",
                                    color: "white",
                                  }}
                                >
                                  {data.text}
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    {currentC && sid === "" && (
                      <>
                        <h1 style={{ fontSize: "16px", textAlign: "center" }}>
                          No Chat Selected
                        </h1>
                      </>
                    )}
                  </div>
                )}
                {chatStatus && chatStatus == "Started" && (
                  <div
                    className="col-12"
                    style={{ maxHeight: "600px", overflow: "auto" }}
                  >
                    {currentC &&
                      sid &&
                      currentC.map((data, index) => {
                        return (
                          <>
                            {data._id && data.user._id === sid && (
                              <div
                                className="row sen"
                                id={data.user._id}
                                sid={sid}
                                key={index}
                              >
                                <div
                                  className="card"
                                  style={{ maxWidth: "400px" }}
                                >
                                  {data.text}
                                </div>
                              </div>
                            )}
                            {data._id && data.user._id !== sid && (
                              <div
                                key={index}
                                className="row rec"
                                id={data.user._id}
                                sid={sid}
                                style={{
                                  justifyContent: "right",
                                  display: "flex",
                                }}
                              >
                                <div
                                  className="card"
                                  style={{
                                    maxWidth: "400px",
                                    backgroundColor: "black",
                                    color: "white",
                                  }}
                                >
                                  {data.text}
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    {currentC && sid === "" && (
                      <>
                        <h1 style={{ fontSize: "16px", textAlign: "center" }}>
                          No Chat Selected
                        </h1>
                      </>
                    )}
                  </div>
                )}
                <div className="col-12">
                  {chatStatus && chatStatus == "Started" && (
                    <div className="card">
                      {/* <SpeedDial model={items} radius={80} direction="up"  buttonClassName="p-button-success" style={{fontSize:"12px"}}/> */}
                      <span>
                        <InputText
                          id="msg"
                          required
                          autoFocus
                          style={{ width: "70%" }}
                          placeholder="Enter your message"
                          value={adminMsg}
                          onChange={(e) => {
                            setAdminMSG(e.target.value);
                          }}
                        />
                      </span>
                      <span style={{ marginLeft: "4%" }}>
                        <Button
                          label="Send"
                          icon="pi pi-arrow-right"
                          className="p-button-info"
                          onClick={message}
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
