import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { BrandService } from "../service/BrandsService";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { CategoryService } from "../service/CategoryService";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { PreSignedUrlService } from "../service/PreSignedUrlService";
import { Divider } from "primereact/divider";
import { ProductTable } from "./ProductTable";

import axios from "axios";
import { CustomLoader } from "../loader";
export const BrandsTable = () => {
  let emptyBrand = {
    name: "",
    images: null,
    category: null,
  };
  let emptyProduct = {
    name: "",
    images: null,
    category: "",
    deal: "",
    brand: "",
    price: "",
    quantity: "",
  };
  const [brands, setBrands] = useState(null);
  const [deleteBrandDialog, setDeleteBrandDialog] = useState(false);
  const [relistBrandDialog, setRelistBrandDialog] = useState(false);
  const [activeBrandDialog, setActiveBrandDialog] = useState(false);
  const [brandDetails, setBrandDetails] = useState(emptyBrand);
  const toast = useRef(null);
  const [category, setCategory] = useState(null);
  const [edit, setEditUpdate] = useState(false);
  const [images, setImage] = useState("");
  const [bid, setBid] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalP, setOpenModalP] = useState(false);
  const [setInventory, setInventoryDetails] = useState(null);
  const [catname, setCatname] = useState(null);
  const [currentProductDetails, setCurrentProductDetails] = useState(null);
  const [loader, setLoader] = useState(false);
  const dealType = currentProductDetails?.deal?.split(" ")[1];
  const dealPrice = currentProductDetails?.deal?.split(" ")[0];
  let disCountPrice;
  if (dealType === "%") {
    disCountPrice =
      currentProductDetails?.price -
      (currentProductDetails?.price * parseInt(dealPrice, 10)) / 100;
  } else if (dealType === "off") {
    disCountPrice = currentProductDetails?.price - parseInt(dealPrice, 10);
  } else {
    disCountPrice = currentProductDetails?.price;
  }
  useEffect(() => {
    const brandsService = new BrandService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/admin/brands",
      headers: {
        "Content-Type": "application/json",
      },
    };
    brandsService.getBrands(config).then((data) => setBrands(data));
    const categoryService = new CategoryService();
    const config1 = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/getCategories",
      headers: {
        "Content-Type": "application/json",
      },
    };
    categoryService.getCategories(config1).then((data) => setCategory(data));
  }, []);
  useEffect(() => {
    const brandsService = new BrandService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/admin/brands",
      headers: {
        "Content-Type": "application/json",
      },
    };
    brandsService.getBrands(config).then((data) => setBrands(data));
  }, [
    deleteBrandDialog,
    relistBrandDialog,
    edit,
    brandDetails,
    activeBrandDialog,
    setInventory,
  ]);

  const activestatusBodyTemplate = (rowData) => {
    if (rowData.status == "Active") {
      return (
        <>
          <span className={`product-badge status-instock`}>
            {rowData.status}
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className={`product-badge status-outofstock`}>Not Active</span>
        </>
      );
    }
  };
  const hideActiveBrandDialog = () => {
    setActiveBrandDialog(false);
  };

  const hideDeleteBrandDialog = () => {
    setDeleteBrandDialog(false);
  };

  const hideRelistBrandDialog = () => {
    setRelistBrandDialog(false);
  };
  const onCategoryChange = (e) => {
    let _brand = { ...brandDetails };
    _brand["category"] = e.value;
    setBrandDetails(_brand);
  };
  const deleteBrand = () => {
    const brandDetailsService = new BrandService();
    const config = {
      method: "delete",
      url: process.env.REACT_APP_APIBASE + "admin/brands/" + bid,
      headers: {
        "Content-Type": "application/json",
      },
    };
    brandDetailsService.deleteBrand(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Brand deleted successfully",
          life: 3000,
        });
        setDeleteBrandDialog(false);
        setBrandDetails(emptyBrand);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while deleted brand",
          life: 3000,
        });
      }
    });
  };

  const activeBrand = () => {
    const brandDetailsService = new BrandService();
    const config = {
      method: "put",
      url: process.env.REACT_APP_APIBASE + "admin/brand/" + bid,
      headers: {
        "Content-Type": "application/json",
      },
      data: { status: "Active" },
    };
    brandDetailsService.deleteBrand(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Brand Active successfully",
          life: 3000,
        });
        setActiveBrandDialog(false);
        setBrandDetails(emptyBrand);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while active brand",
          life: 3000,
        });
      }
    });
  };

  const relistProduct = () => {
    const brandDetailsService = new BrandService();
    const config = {
      method: "put",
      url: process.env.REACT_APP_APIBASE + "admin/brand/" + bid,
      headers: {
        "Content-Type": "application/json",
      },
      data: { status: "Not Active" },
    };
    brandDetailsService.relistBrand(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Brand relisted successfully",
          life: 3000,
        });
        setRelistBrandDialog(false);
        setBrandDetails(emptyBrand);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while relisting brand",
          life: 3000,
        });
      }
    });
  };
  const [brandUpdateDialog, setBrandUpdateDialog] = useState(false);
  const updateBrand = () => {
    const brandService = new BrandService();
    let errors = {};

    if (!brandDetails.name) {
      errors.name = "Name is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.name,
        life: 3000,
      });
      return;
    }
    if (!images) {
      errors.image = "image is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.image,
        life: 3000,
      });
      return;
    }

    const body = {
      name: brandDetails.name,
      // "category":brandDetails.category,
      images: images,
    };
    if (edit === true) {
      const config = {
        method: "put",
        url: process.env.REACT_APP_APIBASE + "admin/brand/update/" + bid,
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      };
      brandService.updateBrand(config).then((data) => {
        if (data.status === "success") {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Brand updated successfully",
            life: 3000,
          });
          setBrandUpdateDialog(false);
          setBrandDetails(emptyBrand);
          setEditUpdate(false);
          setBid("");
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error while updating brand",
            life: 3000,
          });
        }
      });
      return;
    }
  };

  const brandUpdateDialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          setBrandUpdateDialog(false);
        }}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={updateBrand}
      />
    </>
  );

  const deleteBrandDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteBrandDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteBrand}
      />
    </>
  );
  const activeBrandDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideActiveBrandDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={activeBrand}
      />
    </>
  );
  const relistBrandDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideRelistBrandDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={relistProduct}
      />
    </>
  );

  const editBrand = (brand) => {
    setBrandDetails({ ...brand });
    setEditUpdate(true);
    setBid(brand._id);
    setImage(brand.images);
    setBrandUpdateDialog(true);
  };
  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _brand = { ...brandDetails };
    _brand[`${name}`] = val;
    setBrandDetails(_brand);
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editBrand(rowData)}
        />
        {rowData.status == "Active" && (
          <Button
            icon="pi pi-lock"
            className="p-button-rounded p-button-warning mr-2"
            onClick={() => {
              setBrandDetails(rowData);
              setBid(rowData["_id"]);
              setActiveBrandDialog(true);
            }}
          />
        )}
        {rowData.status == "Not Active" && (
          <Button
            icon="pi pi-unlock"
            className="p-button-rounded p-button-info mr-2"
            alt="Active"
            onClick={() => {
              setBrandDetails(rowData);
              setBid(rowData["_id"]);
              setRelistBrandDialog(true);
            }}
          />
        )}
        {/* {  <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2"  onClick={() => {setDeleteBrandDialog(rowData);setBid(rowData["_id"]);setDeleteBrandDialog(true);}} />} */}
      </div>
    );
  };

  const [selectedStatF, setSelectedStatF] = useState(null);
  const dt = useRef(null);
  const statItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option}
      </span>
    );
  };
  const onStatChange = (e) => {
    dt.current.filter(e.value, "status", "equals");
    setSelectedStatF(e.value);
  };
  const statFilter = (
    <Dropdown
      value={selectedStatF}
      options={["Active", "Not Active"]}
      onChange={onStatChange}
      itemTemplate={statItemTemplate}
      placeholder="Select a Status"
      option
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );
  const [selectedCatF, setSelectedCatF] = useState(null);
  const catItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option.name}
      </span>
    );
  };
  const onCatChange = (e) => {
    dt.current.filter(e.value ? e.value.name : e.value, "category", "equals");
    setSelectedCatF(e.value);
  };
  const catFilter = (
    <Dropdown
      value={selectedCatF}
      options={category}
      onChange={onCatChange}
      itemTemplate={catItemTemplate}
      optionLabel="name"
      placeholder="Select a Category"
      option
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );
  const onTemplateUpload = (e) => {
    onUpload(e.files);
  };
  const onUpload = (e) => {
    setLoader(true);
    var data = new FormData();
    data.append("image", e[0]);

    const config = {
      method: "post",
      url: process.env.REACT_APP_APIBASE + "home/util/presignedurl",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        let { url } = response.data;

        setImage(url[0]);
        setLoader(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Image uploaded successfully",
          life: 3000,
        });
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while upload image",
          life: 3000,
        });
      });

    // const data = {
    //     "fileName":e[0].name,
    //     "fileType":e[0].type.split("/")[1],
    // }
    // const objectUrl = e[0]
    // const type = e[0].type
    // const config = {
    //     method: 'post',
    //     url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
    //     headers: {
    //         'Content-Type' : 'application/json'
    //     },
    //     data: data
    // }
    // const preSignedUrlService = new PreSignedUrlService();
    // preSignedUrlService.generatePreSignedUrl(config).then(data => {if (data.url !== "") {
    //     setImage(data.url);
    //     preSignedUrlService.uploadOnGCP(data.url,objectUrl,type ).then(resp =>{
    //         toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
    //     }).catch((error)=>{
    //         toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
    //     })
    // }else{
    //     toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
    // }});
  };
  const [globalFilter, setGlobalFilter] = useState("");
  const reset = () => {
    setGlobalFilter("");
    dt.current.reset();
  };
  const header = (
    <div className="table-header">
      <Button
        type="button"
        label="Clear"
        className="p-button-outlined"
        icon="pi pi-filter-slash"
        onClick={reset}
      />
      <span className="p-input-icon-left" style={{ float: "right" }}>
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search"
          style={{ marginRight: "15px" }}
        />
      </span>
    </div>
  );

  const getProduct = (rowData) => {
    //setInventoryDetails(null);
    const id = rowData._id;
    setCatname(rowData.name);
    const brandDetailsService = new BrandService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "home/item?brand=" + id,
      //url: process.env.REACT_APP_APIBASE+'home/item?category='+category,
      headers: {
        "Content-Type": "application/json",
      },
    };
    brandDetailsService.deleteBrand(config).then((data) => {
      if (data) {
        //toast.current.show({ severity: "success", summary: "Success", detail: "Brand deleted successfully", life: 3000 });
        //setOpenModal(false);
        setInventoryDetails(data);
      } else {
        //toast.current.show({ severity: "error", summary: "Error", detail: "Error while deleted brand", life: 3000 });
      }
    });
  };

  const onOpenModal = (rowData) => {
    setOpenModal(true);
    setInventoryDetails(emptyProduct);
    getProduct(rowData);
    setOpenModalP(false);
  };

  const setCurrentProduct = (rowData) => {
    setCurrentProductDetails(rowData);
    setOpenModal(false);
    setOpenModalP(true);
  };

  return (
    <>
      {loader && CustomLoader()}
      <Toast ref={toast} />
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <h5>Brands</h5>
            <DataTable
              ref={dt}
              value={brands}
              className="datatable-responsive"
              rows={10}
              paginator
              globalFilter={globalFilter}
              header={header}
              dataKey="_id"
            >
              <Column
                header="Image"
                body={(data) => <img src={data.images} width="50" />}
              />
              <Column
                field="name"
                header="Name"
                body={(rowData) => (
                  <>
                    <span
                      style={{
                        fontSize: "12px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        onOpenModal(rowData);
                      }}
                    >
                      {rowData && rowData.name}
                    </span>
                  </>
                )}
                sortable
                style={{ fontSize: "12px" }}
              />
              {/*<Column field="category" header="Category"
                             body={(rowData) => (
                                <>
                                    <span style={{ fontSize: "12px" }}  onClick={() => {
                                                onOpenModal(rowData);

                                            }}
                                            >{rowData && rowData.category}</span>
                                </>
                            )}
                                        style={{ fontSize: "12px" }} filter filterElement={catFilter}/>*/}
              <Column
                field="added.at"
                header="Date"
                body={(rowData) => (
                  <>
                    <span style={{ fontSize: "12px" }}>
                      {rowData && new Date(rowData.added.at).toLocaleString()}
                    </span>
                  </>
                )}
                sortable
                style={{ fontSize: "12px" }}
              />
              <Column
                field="status"
                header="Status"
                body={activestatusBodyTemplate}
                filter
                filterElement={statFilter}
              />
              <Column body={actionBodyTemplate}></Column>
            </DataTable>
          </div>
        </div>
      </div>
      <Dialog
        header="Inventory Details"
        visible={openModal}
        style={{ width: "70vw" }}
        onHide={() => setOpenModal(false)}
        modal
      >
        {setInventory && setInventory["data"] && (
          <DataTable value={setInventory["data"]} spinner>
            <Column
              field="name"
              header="Name"
              body={(rowData) => (
                <>
                  <span
                    style={{
                      fontSize: "12px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCurrentProduct(rowData);
                    }}
                  >
                    {rowData && rowData.name}
                  </span>
                </>
              )}
              sortable
              style={{ fontSize: "12px" }}
            ></Column>
            <Column
              field="price"
              header="Price"
              sortable
              style={{ fontSize: "12px" }}
              body={(rowData) => {
                const dealType = rowData?.deal?.split(" ")[1];
                const dealPrice = rowData?.deal?.split(" ")[0];
                let disCountPrice;
                if (dealType === "%") {
                  disCountPrice =
                    rowData?.price -
                    (rowData?.price * parseInt(dealPrice, 10)) / 100;
                } else if (dealType === "off") {
                  disCountPrice = rowData?.price - parseInt(dealPrice, 10);
                } else {
                  disCountPrice = rowData?.price;
                }
                return rowData?.deal
                  ? disCountPrice + " NGN"
                  : rowData?.price + " NGN";
              }}
            ></Column>
            <Column
              field="category"
              body={(rowData) => <>{rowData.category.name}</>}
              header="Category"
              style={{ fontSize: "12px" }}
            ></Column>
            <Column
              field="brand"
              body={(rowData) => <>{rowData.brand.name}</>}
              header="Brand"
              style={{ fontSize: "12px" }}
            ></Column>
            <Column
              field="quantity"
              header="Inventory"
              sortable
              style={{ fontSize: "12px" }}
            ></Column>
            <Column
              field="deal"
              header="Deal"
              style={{ fontSize: "12px" }}
            ></Column>
          </DataTable>
        )}
      </Dialog>

      <Dialog
        header="Product Details"
        visible={openModalP}
        style={{ width: "50vw" }}
        onHide={() => setOpenModalP(false)}
        modal
      >
        <div style={{ textAlign: "center" }}>
          {currentProductDetails &&
            currentProductDetails?.images.map((item, index) => {
              return (
                <img
                  style={{
                    width: 180,
                    margin: 10,
                    objectFit: "contain",
                  }}
                  src={item}
                />
              );
            })}
        </div>
        <ProductTable
          c1="Name"
          c2={currentProductDetails && currentProductDetails?.name}
          c3={currentProductDetails?.name}
        />
        <ProductTable
          c1="Brand"
          c2={currentProductDetails && currentProductDetails?.brand}
          c3={currentProductDetails?.brand.name}
        />
        <ProductTable
          c1="Catergory"
          c2={currentProductDetails && currentProductDetails?.category}
          c3={currentProductDetails?.category.name}
        />
        <ProductTable
          c1="Price"
          c2={currentProductDetails && currentProductDetails?.price}
          c3={
            currentProductDetails?.deal
              ? disCountPrice + " NGN"
              : currentProductDetails?.price + " NGN"
          }
        />
        <ProductTable
          c1="Cost"
          c2={currentProductDetails && currentProductDetails?.shop_price}
          c3={currentProductDetails?.shop_price + " NGN"}
        />
        <ProductTable
          c1="Deals"
          c2={currentProductDetails && currentProductDetails?.deal}
          c3={currentProductDetails?.deal}
        />
        <ProductTable
          c1="Quantity"
          c2={currentProductDetails && currentProductDetails?.quantity}
          c3={currentProductDetails?.quantity}
        />{" "}
        <ProductTable
          c1="Rating"
          c2={currentProductDetails && currentProductDetails?.rating}
          c3={currentProductDetails?.rating}
        />
        {/* {currentProductDetails &&  currentProductDetails.name && <h1 style={{fontSize:"12px",color:"#000"}}>Name: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentProductDetails.name}</span></h1>}
                {currentProductDetails &&  currentProductDetails.brand && <h1 style={{fontSize:"12px",color:"#000"}}>Brand: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentProductDetails.brand.name}</span></h1>}
                {currentProductDetails &&  currentProductDetails.price && <h1 style={{fontSize:"12px",color:"#000"}}>Price: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentProductDetails.price}</span></h1>}
                {currentProductDetails &&  currentProductDetails.category && <h1 style={{fontSize:"12px",color:"#000"}}>Category: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentProductDetails.category.name}</span></h1>}
                {currentProductDetails &&  currentProductDetails.deal && <h1 style={{fontSize:"12px",color:"#000"}}>Deal: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentProductDetails.name}</span></h1>}
                {currentProductDetails &&  currentProductDetails.quantity && <h1 style={{fontSize:"12px",color:"#000"}}>Quantity: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentProductDetails.quantity}</span></h1>}
                {currentProductDetails &&  currentProductDetails.shop_price && <h1 style={{fontSize:"12px",color:"#000"}}>Shop Price: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentProductDetails.shop_price}</span></h1>}
                {currentProductDetails &&   <h1 style={{fontSize:"12px",color:"#000"}}>Rating: <span style={{fontSize:"12px",color:"#bcb7b7"}}>{currentProductDetails.rating}</span></h1>} */}
      </Dialog>

      <Dialog
        visible={brandUpdateDialog}
        style={{ width: "550px" }}
        header="Brand Details"
        modal
        className="p-fluid"
        footer={brandUpdateDialogFooter}
        onHide={() => {
          setBrandUpdateDialog(false);
        }}
      >
        {brandDetails.images && (
          <img
            src={brandDetails.images}
            alt={brandDetails.images}
            width="150"
            className="mt-0 mx-auto mb-5 block shadow-2"
          />
        )}
        <div className="field col-12">
          <label htmlFor="brandImage">Brand image</label>
          <FileUpload
            name="demo[]"
            customUpload={true}
            uploadHandler={onTemplateUpload}
            onUpload={onTemplateUpload}
            accept="image/png,image/jpg,image/jpeg"
            maxFileSize={1000000}
            emptyTemplate={
              <p className="p-m-3" style={{ fontSize: "15px" }}>
                Drag and drop files to here to upload.
              </p>
            }
            auto
          />
        </div>
        <div className="field">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            value={brandDetails.name}
            onChange={(e) => onInputChange(e, "name")}
            required
            autoFocus
          />
        </div>
        {/*<div className="field">
                                <label className="mb-3">Category</label>
                                <div className="formgrid grid">
                                    {category &&
                                        category.map((data) => {
                                            return <div className="field-radiobutton col-6">
                                                        <RadioButton inputId="category1" name="category" value={data['name']} onChange={onCategoryChange} checked={brandDetails.category === data['name']} />
                                                        <label htmlFor="category1">{data['name']}</label>
                                                    </div>
                                        })}
                                </div>
                                </div>*/}
      </Dialog>
      <Dialog
        visible={activeBrandDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={activeBrandDialogFooter}
        onHide={hideActiveBrandDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {brandDetails && (
            <span>
              Are you sure you want to Inactive <b>{brandDetails.name}</b> ?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteBrandDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteBrandDialogFooter}
        onHide={hideDeleteBrandDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {brandDetails && (
            <span>
              Are you sure you want to delete <b>{brandDetails.name}</b> ?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={relistBrandDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={relistBrandDialogFooter}
        onHide={hideRelistBrandDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {brandDetails && (
            <span>
              Are you sure you want to active brand <b>{brandDetails.name}</b> ?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};
