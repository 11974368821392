import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { OrderService } from "../service/OrderService";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { ProgressBar } from "primereact/progressbar";
import { InputText } from "primereact/inputtext";
import { ProductTable } from "./ProductTable";

import moment from "moment";
export const TransactionsTable = () => {
  const toast = useRef(null);
  const [orderDetails, setOrderDetails] = useState([]);
  const [currentOrderDetails, setCurrentOrderDetails] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    const orderService = new OrderService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/trans",
      headers: {
        "Content-Type": "application/json",
      },
    };
    orderService.getOrders(config).then((data) => {
      setOrderDetails(data.data);
    });

    if (orderDetails.length === 0) {
      if (dt !== null && dt.current != null) {
        onDateChangeUseeffect([
          moment().startOf("month").toDate(),
          moment().toDate(),
        ]);
      }
    }
  }, []);

  const [selectedStatusF, setSelectedStatusF] = useState(null);
  const [selectedDateF, setSelectedDateF] = useState(null);
  const dt = useRef(null);
  const statusesF = ["Success", "Pending", "Refund"];
  const dateF = ["ASC", "Desc"];
  const statusItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option}
      </span>
    );
  };
  const dateItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option}
      </span>
    );
  };
  const onStatusChange = (e) => {
    dt.current.filter(e.value, "paymentStatus", "equals");
    setSelectedStatusF(e.value);
  };
  const onDateSort = (e) => {
    dt.current.sort(
      (y, x) =>
        +moment(y.orderplaced.at).toDate() - +moment(x.orderplaced.at).toDate()
    );
    //dt.current.filter(dat2+":"+dat1, 'orderplaced.at', 'custom');
    setSelectedDateF(e.value);
  };
  const statusFilter = (
    <Dropdown
      value={selectedStatusF}
      options={statusesF}
      onChange={onStatusChange}
      itemTemplate={statusItemTemplate}
      placeholder="Select a Status"
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );
  const dateSort = (
    <Dropdown
      value={selectedDateF}
      options={dateF}
      onChange={onDateSort}
      itemTemplate={dateItemTemplate}
      placeholder="Sort Date"
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedDate, setSelectedDate] = useState([
    moment().startOf("month").toDate(),
    moment().toDate(),
  ]);

  const [selectedPTypeF, setSelectedPTypeF] = useState(null);
  const typesP = ["CARD", "COD"];
  const ptypeItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option}
      </span>
    );
  };
  const onPTypeChange = (e) => {
    dt.current.filter(e.value, "paymentType", "equals");
    setSelectedPTypeF(e.value);
  };
  const ptypeFilter = (
    <Dropdown
      value={selectedPTypeF}
      options={typesP}
      onChange={onPTypeChange}
      itemTemplate={ptypeItemTemplate}
      placeholder="Select a Type"
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );

  const formatDate = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return date.getFullYear() + "-" + month + "-" + day;
  };
  const [fs, setFs] = useState("");
  const codeFilter = (value, filter) => {
    // var date1 = new Date(value);
    // var date2 = new Date(filter.split(":")[0]);
    // var date3= new Date(filter.split(":")[1]);

    // return date1.getTime()>date2.getTime() && date1.getTime()< date3.getTime();

    var date1 = moment(value).toDate();
    var date2 = moment(filter.split(":")[0], "YYYY-MM-DD").toDate();
    var date3 = moment(filter.split(":")[1], "YYYY-MM-DD")
      .endOf("day")
      .toDate();

    console.log(value, filter.split(":")[0], filter.split(":")[1]);
    console.log(date1, date2, date3);

    return (
      date1.valueOf() > date2.valueOf() && date1.valueOf() < date3.valueOf()
    );
  };
  const onDateChangeUseeffect = (e) => {
    let dat2 = moment(e[0]).format("YYYY-MM-DD");
    let dat1 = moment(e[1]).endOf("day").format("YYYY-MM-DD");
    dt.current.filter(dat2 + ":" + dat1, "orderplaced.at", "custom");
  };
  const onDateChange = (e) => {
    if (e.value[1] !== null) {
      let dat2 = moment(e.value[1]).endOf("day").format("YYYY-MM-DD");
      dt.current.filter(fs + ":" + dat2, "orderplaced.at", "custom");
      setSelectedDate(e.value);
      return;
    }
    if (e.value[0] !== null && e.value[1] === null) {
      let dat2 = moment(e.value[0]).format("YYYY-MM-DD");
      setFs(dat2);
      setSelectedDate(e.value);
      return;
    }
  };

  const reset = () => {
    setSelectedStatusF(null);
    setSelectedDateF(null);
    setSelectedDate(undefined);
    setGlobalFilter("");
    dt.current.reset();
  };
  const header = (
    <div className="table-header">
      <Button
        type="button"
        label="Clear"
        className="p-button-outlined"
        icon="pi pi-filter-slash"
        onClick={reset}
      />
      <span className="p-input-icon-left" style={{ float: "right" }}>
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search"
          style={{ marginRight: "15px" }}
        />
        <Calendar
          readOnlyInput
          value={selectedDate}
          onChange={onDateChange}
          dateFormat="yy-mm-dd"
          showIcon
          selectionMode="range"
          className="p-column-filter"
          placeholder="Select Date"
          style={{
            marginRight: "5px",
            width: "257px",
            marginBottom: "5px",
            marginTop: "5px",
            marginLeft: "auto",
            fontSize: "12px !important",
          }}
        />
      </span>
    </div>
  );
  const dateFilter = (
    <Calendar
      readOnlyInput
      value={selectedDate}
      onChange={onDateChange}
      dateFormat="yy-mm-dd"
      selectionMode="range"
      className="p-column-filter"
      placeholder="Select Date"
      style={{ display: "none" }}
    />
  );

  return (
    <>
      {orderDetails && orderDetails.length === 0 && (
        <h1 style={{ fontSize: "16px" }}>Transactions</h1>
      )}
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <DataTable
              ref={dt}
              value={orderDetails}
              globalFilter={globalFilter}
              header={header}
              dataKey="_id"
              className="datatable-responsive"
              rows={10}
              paginator
              sorting={true}
            >
              <Column
                field="userDetails.name"
                header="Customer Name"
                style={{ fontSize: "12px" }}
                body={(rowData) => (
                  <>
                    <h1 style={{ fontSize: "12px" }}>
                      {rowData && rowData.userDetails.name}
                    </h1>
                  </>
                )}
              />
              <Column
                header="Payment Type"
                style={{ fontSize: "12px" }}
                field="paymentType"
                filter
                filterElement={ptypeFilter}
                body={(rowData) => (
                  <>
                    <h1 style={{ fontSize: "12px" }}>
                      {rowData && rowData.paymentType}
                    </h1>
                  </>
                )}
              />
              <Column
                header="Amount"
                sortable
                style={{ fontSize: "12px" }}
                field="totalCharges"
                body={(rowData) => (
                  <>
                    <h1 style={{ fontSize: "12px" }}>
                      {rowData &&
                        rowData.paymentsDetails &&
                        rowData.paymentsDetails.totalCharges}
                    </h1>
                  </>
                )}
              />
              <Column
                header="Currency"
                style={{ fontSize: "12px" }}
                body={(rowData) => (
                  <>
                    <h1 style={{ fontSize: "12px" }}>
                      {rowData &&
                        rowData.paymentsDetails &&
                        rowData.paymentsDetails.currency}
                    </h1>
                  </>
                )}
              />
              <Column
                field="paymentId"
                header="Transaction Reference ID"
                style={{ fontSize: "12px", overflowWrap: "break-word" }}
                body={(rowData) => (
                  <>
                    <h1 style={{ fontSize: "12px" }}>
                      {rowData && rowData.paymentId}
                    </h1>
                  </>
                )}
              />
              <Column
                header="Transaction Status"
                style={{ fontSize: "12px" }}
                filter
                filterElement={statusFilter}
                field="paymentStatus"
                body={(rowData) => (
                  <>
                    {rowData && rowData.paymentStatus == "Success" && (
                      <h1
                        className={`product-badge status-instock`}
                        style={{ fontSize: "12px" }}
                      >
                        {rowData.paymentStatus}
                      </h1>
                    )}
                    {rowData && rowData.paymentStatus == "Pending" && (
                      <h1
                        className={`product-badge status-lowstock`}
                        style={{ fontSize: "12px" }}
                      >
                        {rowData.paymentStatus}
                      </h1>
                    )}
                    {rowData && rowData.paymentStatus == "Refund" && (
                      <h1
                        className={`product-badge status-outofstock`}
                        style={{ fontSize: "12px" }}
                      >
                        {rowData.paymentStatus}
                      </h1>
                    )}
                  </>
                )}
              />
              <Column
                header="Date"
                sortable
                style={{ fontSize: "12px" }}
                field="orderplaced.at"
                filter
                filterElement={dateFilter}
                filterFunction={codeFilter}
                body={(rowData) => (
                  <>
                    <h1 style={{ fontSize: "12px" }}>
                      {rowData &&
                        rowData.orderplaced &&
                        moment(rowData.orderplaced.at).format("YYYY-MM-DD")}
                    </h1>
                  </>
                )}
              />
              <Column
                header="View"
                style={{ fontSize: "12px" }}
                body={(rowData) => (
                  <>
                    <Button
                      icon="pi pi-search"
                      type="button"
                      className="p-button-text"
                      onClick={() => {
                        setCurrentOrderDetails(rowData);
                        setOpenModal(true);
                      }}
                    />
                  </>
                )}
              />
            </DataTable>
          </div>
        </div>
      </div>
      {orderDetails && orderDetails.length === 0 && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: "6px" }}
        ></ProgressBar>
      )}
      <Dialog
        header="Transaction Details"
        visible={openModal}
        style={{ width: "50vw" }}
        onHide={() => setOpenModal(false)}
        modal
      >
        <h1 style={{ fontSize: "14px", color: "black" }}>User Details</h1>
        <Divider />
        {currentOrderDetails &&
          currentOrderDetails.userDetails &&
          currentOrderDetails.userDetails.name && (
            <ProductTable
              c1="Name"
              c2={currentOrderDetails && currentOrderDetails?.userDetails.name}
              c3={currentOrderDetails?.userDetails?.name}
            />
          )}
        {currentOrderDetails &&
          currentOrderDetails.userDetails &&
          currentOrderDetails.userDetails.email && (
            <ProductTable
              c1="Email"
              c2={currentOrderDetails && currentOrderDetails?.userDetails.email}
              c3={currentOrderDetails?.userDetails?.email}
            />
          )}
        <Divider />
        {currentOrderDetails && currentOrderDetails._id && (
          <ProductTable
            c1="Order ID"
            c2={currentOrderDetails && currentOrderDetails?._id}
            c3={currentOrderDetails?._id}
          />
        )}

        {currentOrderDetails &&
          currentOrderDetails.paymentsDetails &&
          currentOrderDetails.paymentsDetails.taxAmount && (
            <ProductTable
              c1="Tax Amount"
              c2={
                currentOrderDetails &&
                currentOrderDetails?.paymentsDetails?.taxAmount
              }
              c3={currentOrderDetails?.paymentsDetails?.taxAmount + " NGN"}
            />
          )}

        {currentOrderDetails &&
          currentOrderDetails.paymentsDetails &&
          currentOrderDetails.paymentsDetails.shippingCharges && (
            <ProductTable
              c1="Shipping Fee"
              c2={
                currentOrderDetails &&
                currentOrderDetails?.paymentsDetails?.shippingCharges
              }
              c3={
                currentOrderDetails?.paymentsDetails?.shippingCharges + " NGN"
              }
            />
          )}
        {currentOrderDetails &&
          currentOrderDetails.paymentsDetails &&
          currentOrderDetails.paymentsDetails.totalCharges && (
            <ProductTable
              c1="Total Amount"
              c2={
                currentOrderDetails &&
                currentOrderDetails?.paymentsDetails?.totalCharges
              }
              c3={currentOrderDetails?.paymentsDetails?.totalCharges + " NGN"}
            />
          )}
        {currentOrderDetails && currentOrderDetails.paymentId && (
          <ProductTable
            c1="Payment ID"
            c2={currentOrderDetails && currentOrderDetails?.paymentId}
            c3={currentOrderDetails?.paymentId}
          />
        )}
        {currentOrderDetails && currentOrderDetails.paymentStatus && (
          <ProductTable
            c1="Payment Status"
            c2={currentOrderDetails && currentOrderDetails?.paymentStatus}
            c3={currentOrderDetails?.paymentStatus}
          />
        )}

        <Divider />
        {currentOrderDetails &&
          currentOrderDetails.paymentType !== "COD" &&
          currentOrderDetails.paymentStatus !== "Pending" && (
            <>
              <h1 style={{ fontSize: "14px", color: "black" }}>Card Details</h1>
              <Divider />
              {currentOrderDetails &&
                currentOrderDetails.transactionDetails &&
                currentOrderDetails.transactionDetails.data &&
                currentOrderDetails.transactionDetails.data.card && (
                  <ProductTable
                    c1="Country"
                    c2={
                      currentOrderDetails &&
                      currentOrderDetails?.transactionDetails?.data?.card
                        ?.country
                    }
                    c3={
                      currentOrderDetails?.transactionDetails?.data?.card
                        ?.country
                    }
                  />
                )}
              {currentOrderDetails &&
                currentOrderDetails.transactionDetails &&
                currentOrderDetails.transactionDetails.data &&
                currentOrderDetails.transactionDetails.data.card && (
                  <ProductTable
                    c1="Expiry"
                    c2={
                      currentOrderDetails &&
                      currentOrderDetails?.transactionDetails?.data?.card
                        ?.expiry
                    }
                    c3={
                      currentOrderDetails?.transactionDetails?.data?.card
                        ?.expiry
                    }
                  />
                )}
              {currentOrderDetails &&
                currentOrderDetails.transactionDetails &&
                currentOrderDetails.transactionDetails.data &&
                currentOrderDetails.transactionDetails.data.card && (
                  <ProductTable
                    c1="First 6 Digits"
                    c2={
                      currentOrderDetails &&
                      currentOrderDetails?.transactionDetails?.data?.card
                        ?.first_6digits
                    }
                    c3={
                      currentOrderDetails?.transactionDetails?.data?.card
                        ?.first_6digits
                    }
                  />
                )}
              {currentOrderDetails &&
                currentOrderDetails.transactionDetails &&
                currentOrderDetails.transactionDetails.data &&
                currentOrderDetails.transactionDetails.data.card && (
                  <ProductTable
                    c1="Last 4 Digits"
                    c2={
                      currentOrderDetails &&
                      currentOrderDetails?.transactionDetails?.data?.card
                        ?.last_4digits
                    }
                    c3={
                      currentOrderDetails?.transactionDetails?.data?.card
                        ?.last_4digits
                    }
                  />
                )}
              {currentOrderDetails &&
                currentOrderDetails.transactionDetails &&
                currentOrderDetails.transactionDetails.data &&
                currentOrderDetails.transactionDetails.data.card && (
                  <ProductTable
                    c1="Issuer"
                    c2={
                      currentOrderDetails &&
                      currentOrderDetails?.transactionDetails?.data?.card
                        ?.issuer
                    }
                    c3={
                      currentOrderDetails?.transactionDetails?.data?.card
                        ?.issuer
                    }
                  />
                )}
              {currentOrderDetails &&
                currentOrderDetails.transactionDetails &&
                currentOrderDetails.transactionDetails.data &&
                currentOrderDetails.transactionDetails.data.card && (
                  <ProductTable
                    c1="Type"
                    c2={
                      currentOrderDetails &&
                      currentOrderDetails?.transactionDetails?.data?.card?.type
                    }
                    c3={
                      currentOrderDetails?.transactionDetails?.data?.card?.type
                    }
                  />
                )}
            </>
          )}
      </Dialog>
    </>
  );
};
