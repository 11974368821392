import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { UserService } from "../service/UsersService";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Sidebar } from "primereact/sidebar";
import { Divider } from "primereact/divider";
import { OrderService } from "../service/OrderService";
import { InputText } from "primereact/inputtext";
import { ProductTable } from "./ProductTable";
import moment from "moment";

export const UserTable = () => {
  const dt = useRef(null);
  let emptyUser = {
    name: "",
  };
  const [users, setUsers] = useState(null);
  const [visibleRight, setVisibleRight] = useState(false);
  const toast = useRef(null);
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [relistUserDialog, setRelistUserDialog] = useState(false);
  const [userDetails, setUserDetails] = useState(emptyUser);
  const [currentProductDetails, setCurrentProductDetails] = useState("");
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [uid, setUid] = useState("");
  const dealType = currentProductDetails?.itemDeal?.split(" ")[1];
  const dealPrice = currentProductDetails?.itemDeal?.split(" ")[0];
  let disCountPrice;
  if (dealType === "%") {
    disCountPrice =
      currentProductDetails?.itemPrice -
      (currentProductDetails?.itemPrice * parseInt(dealPrice, 10)) / 100;
  } else if (dealType === "off") {
    disCountPrice = currentProductDetails?.itemPrice - parseInt(dealPrice, 10);
  } else {
    disCountPrice = currentProductDetails?.itemPrice;
  }
  useEffect(() => {
    const userService = new UserService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/profile",
      //url: process.env.REACT_APP_APIBASE+'admin/profile',
      headers: {
        "Content-Type": "application/json",
      },
    };
    userService.getUsers(config).then((data) => setUsers(data.reverse()));
  }, []);
  useEffect(() => {
    // window.dt = dt.current;
    getUsers();
  }, [deleteUserDialog, relistUserDialog]);

  const getUsers = () => {
    const userService = new UserService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/profile",
      //url: process.env.REACT_APP_APIBASE+'admin/profile',
      headers: {
        "Content-Type": "application/json",
      },
    };
    userService.getUsers(config).then((data) => setUsers(data.reverse()));
  };

  const hideDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  };
  const hideRelistUserDialog = () => {
    setRelistUserDialog(false);
  };
  const [orderDetails, setOrderDetails] = useState(null);
  const [recentorderDetails, setRecentOrderDetails] = useState(null);
  const getOrders = (uid) => {
    const orderService = new OrderService();
    const config = {
      method: "get",
      url:
        process.env.REACT_APP_APIBASE +
        "home/orders?limit=20&offset=0&userid=" +
        uid,
      headers: {
        "Content-Type": "application/json",
      },
    };
    orderService.getOrders(config).then((data) => {
      setOrderDetails(data.data);
      setRecentOrderDetails(data.data[0]);
    });
  };

  const deleteUser = () => {
    const userService = new UserService();
    const config = {
      method: "put",
      url: process.env.REACT_APP_APIBASE + "home/user/profile/" + uid,
      headers: {
        "Content-Type": "application/json",
      },
      data: { status: "Active" },
    };
    userService.deleteUser(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Account deactivated successfully",
          life: 3000,
        });
        setDeleteUserDialog(false);
        setUserDetails(emptyUser);
        getUsers();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while deactivating account",
          life: 3000,
        });
      }
    });
  };

  const relistUser = () => {
    const userService = new UserService();
    const config = {
      method: "put",
      url: process.env.REACT_APP_APIBASE + "home/user/profile/" + uid,
      headers: {
        "Content-Type": "application/json",
      },
      data: { status: "Not Active" },
    };
    userService.relistUser(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Account activated successfully",
          life: 3000,
        });
        setRelistUserDialog(false);
        setUserDetails(emptyUser);
        getUsers();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while reactivating account",
          life: 3000,
        });
      }
    });
  };
  const deleteUserDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteUserDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteUser}
      />
    </>
  );
  const relistUserDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideRelistUserDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={relistUser}
      />
    </>
  );
  const activestatusBodyTemplate = (rowData) => {
    if (rowData.userDetails.status == "Active") {
      return (
        <>
          <span className={`product-badge status-instock`}>
            {rowData.userDetails.status}
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className={`product-badge status-outofstock`}>Not Active</span>
        </>
      );
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {rowData.userDetails.status == "Active" && (
          <Button
            icon="pi pi-lock"
            className="p-button-rounded p-button-warning"
            onClick={() => {
              setUserDetails(rowData.userDetails);
              setUid(rowData.userDetails["_id"]);
              setDeleteUserDialog(true);
            }}
          />
        )}
        {rowData.userDetails.status == "Not Active" && (
          <Button
            icon="pi pi-unlock"
            className="p-button-rounded p-button-info"
            onClick={() => {
              setUserDetails(rowData.userDetails);
              setUid(rowData.userDetails["_id"]);
              setRelistUserDialog(true);
            }}
          />
        )}
      </div>
    );
  };

  const [globalFilter, setGlobalFilter] = useState("");
  const reset = () => {
    setGlobalFilter("");
    dt.current.reset();
  };
  const header = (
    <div className="table-header">
      <Button
        type="button"
        label="Clear"
        className="p-button-outlined"
        icon="pi pi-filter-slash"
        onClick={reset}
      />
      <span className="p-input-icon-left" style={{ float: "right" }}>
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search"
          style={{ marginRight: "15px" }}
        />
      </span>
    </div>
  );

  return (
    <>
      <Sidebar
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
        style={{ width: "55%" }}
      >
        <h1
          style={{
            fontSize: "14px",
            color: "black",
            fontWeight: "bolder",
          }}
        >
          Personal details
        </h1>
        <Divider />
        {userDetails &&
          userDetails["userDetails"] &&
          userDetails["userDetails"].name && (
            <h1
              style={{
                fontSize: "12px",
                color: "#5e5e5e",
                fontWeight: "bold",
              }}
            >
              Name:{" "}
              <span
                style={{
                  fontSize: "12px",
                  color: "#666666",
                  fontWeight: "normal",
                }}
              >
                {userDetails["userDetails"].name}
              </span>
            </h1>
          )}
        {userDetails &&
          userDetails["userDetails"] &&
          userDetails["userDetails"].email && (
            <h1
              style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
            >
              Email:{" "}
              <span
                style={{
                  fontSize: "12px",
                  color: "#666666",
                  fontWeight: "normal",
                }}
              >
                {userDetails["userDetails"].email}
              </span>
            </h1>
          )}
        {userDetails &&
          userDetails["userDetails"] &&
          userDetails["userDetails"].gender && (
            <h1
              style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
            >
              Gender:{" "}
              <span
                style={{
                  fontSize: "12px",
                  color: "#666666",
                  fontWeight: "normal",
                }}
              >
                {userDetails["userDetails"].gender}
              </span>
            </h1>
          )}
        {userDetails &&
          userDetails["userDetails"] &&
          userDetails["userDetails"].dob && (
            <h1
              style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
            >
              Date of birth:{" "}
              <span
                style={{
                  fontSize: "12px",
                  color: "#666666",
                  fontWeight: "normal",
                }}
              >
                {userDetails["userDetails"].dob}
              </span>
            </h1>
          )}
        {userDetails &&
          userDetails["userDetails"] &&
          userDetails["userDetails"].phoneno && (
            <h1
              style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
            >
              Mobile:{" "}
              <span
                style={{
                  fontSize: "12px",
                  color: "#666666",
                  fontWeight: "normal",
                }}
              >
                {userDetails["userDetails"].phoneno}
              </span>
            </h1>
          )}
        <Divider />
        <h1 style={{ fontSize: "14px", color: "black", fontWeight: "bolder" }}>
          Address
        </h1>
        <Divider />
        {userDetails &&
          userDetails["address"] &&
          userDetails["address"].addressLine1 && (
            <h1
              style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
            >
              address line 1:{" "}
              <span
                style={{
                  fontSize: "12px",
                  color: "#666666",
                  fontWeight: "normal",
                }}
              >
                {userDetails["address"].addressLine1}
              </span>
            </h1>
          )}
        {userDetails &&
          userDetails["address"] &&
          userDetails["address"].addressLine2 && (
            <h1
              style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
            >
              address line 2:{" "}
              <span
                style={{
                  fontSize: "12px",
                  color: "#666666",
                  fontWeight: "normal",
                }}
              >
                {userDetails["address"].addressLine2}
              </span>
            </h1>
          )}
        {userDetails && userDetails["address"] && userDetails["address"].city && (
          <h1
            style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
          >
            city:{" "}
            <span
              style={{
                fontSize: "12px",
                color: "#666666",
                fontWeight: "normal",
              }}
            >
              {userDetails["address"].city}
            </span>
          </h1>
        )}
        {userDetails && userDetails["address"] && userDetails["address"].state && (
          <h1
            style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
          >
            state:{" "}
            <span
              style={{
                fontSize: "12px",
                color: "#666666",
                fontWeight: "normal",
              }}
            >
              {userDetails["address"].state}
            </span>
          </h1>
        )}
        {userDetails &&
          userDetails["address"] &&
          userDetails["address"].country && (
            <h1
              style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
            >
              country:{" "}
              <span
                style={{
                  fontSize: "12px",
                  color: "#666666",
                  fontWeight: "normal",
                }}
              >
                {userDetails["address"].country}
              </span>
            </h1>
          )}
        {userDetails &&
          userDetails["address"] &&
          userDetails["address"].postalCode && (
            <h1
              style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
            >
              pin code:{" "}
              <span
                style={{
                  fontSize: "12px",
                  color: "#666666",
                  fontWeight: "normal",
                }}
              >
                {userDetails["address"].postalCode}
              </span>
            </h1>
          )}
        <Divider />
        <h1 style={{ fontSize: "14px", color: "black", fontWeight: "bolder" }}>
          Recent Order
        </h1>
        <Divider />
        {recentorderDetails && (
          <h1
            style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
          >
            Order Id:{" "}
            <span
              style={{
                fontSize: "12px",
                color: "#666666",
                fontWeight: "normal",
              }}
            >
              {recentorderDetails._id}
            </span>
          </h1>
        )}
        {recentorderDetails && (
          <h1
            style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
          >
            Payment Status:{" "}
            <span
              style={{
                fontSize: "12px",
                color: "#666666",
                fontWeight: "normal",
              }}
            >
              {recentorderDetails.paymentStatus}
            </span>
          </h1>
        )}
        {recentorderDetails && (
          <h1
            style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
          >
            Payment Type:{" "}
            <span
              style={{
                fontSize: "12px",
                color: "#666666",
                fontWeight: "normal",
              }}
            >
              {recentorderDetails.paymentType}
            </span>
          </h1>
        )}
        {recentorderDetails && (
          <h1
            style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
          >
            Order Status:{" "}
            <span
              style={{
                fontSize: "12px",
                color: "#666666",
                fontWeight: "normal",
              }}
            >
              {recentorderDetails.orderStatus}
            </span>
          </h1>
        )}
        {recentorderDetails && (
          <h1
            style={{ fontSize: "12px", color: "#5e5e5e", fontWeight: "bold" }}
          >
            Items Ordered:
          </h1>
        )}

        {recentorderDetails &&
          recentorderDetails.ItemsOrdered &&
          recentorderDetails.ItemsOrdered.items && (
            <DataTable value={recentorderDetails.ItemsOrdered.items}>
              <Column
                field="itemName"
                header="Name"
                sortable
                body={(rowData) => (
                  <span
                    onClick={() => {
                      setCurrentProductDetails(rowData);
                      console.log(rowData);
                      setOpenProductModal(true);
                      setOpenModal(false);
                    }}
                    style={{
                      fontSize: "12px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {rowData.itemName.substring(0, 20)}
                  </span>
                )}
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="itemPrice"
                header="Price"
                style={{ fontSize: "12px" }}
                body={(rowData) => {
                  const dealType = rowData?.itemDeal?.split(" ")[1];
                  const dealPrice = rowData?.itemDeal?.split(" ")[0];
                  let disCountPrice;
                  if (dealType === "%") {
                    disCountPrice =
                      rowData?.itemPrice -
                      (rowData?.itemPrice * parseInt(dealPrice, 10)) / 100;
                  } else if (dealType === "off") {
                    disCountPrice =
                      rowData?.itemPrice - parseInt(dealPrice, 10);
                  } else {
                    disCountPrice = rowData?.itemPrice;
                  }
                  return (
                    <>
                      {rowData?.itemDeal
                        ? disCountPrice + " NGN"
                        : rowData?.itemPrice + " NGN"}
                    </>
                  );
                }}
              ></Column>
              <Column
                field="itemCategory"
                header="Category"
                body={(rowData) => <>{rowData.itemCategory.name}</>}
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="itemBrand"
                body={(rowData) => <>{rowData.itemBrand.name}</>}
                header="Brand"
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="quantity"
                header="Availability"
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="itemDeal"
                header="Deal"
                style={{ fontSize: "12px" }}
              ></Column>
            </DataTable>
          )}
        <Divider />
        <h1 style={{ fontSize: "14px", color: "black", fontWeight: "bolder" }}>
          Orders
        </h1>
        <Divider />
        <DataTable
          value={orderDetails}
          className="datatable-responsive"
          rows={5}
          paginator
        >
          <Column
            field="_id"
            header="Order ID"
            style={{ fontSize: "10px" }}
            body={(rowData) => (
              <>
                <span style={{ fontSize: "10px" }}>
                  {rowData && rowData._id && rowData._id}
                </span>
              </>
            )}
          />
          <br />
          <Column
            field="orderStatus"
            header="Order Status"
            style={{ fontSize: "10px" }}
          />
          <Column
            field="orderplaced.at"
            header="Order Placed on"
            style={{ fontSize: "10px" }}
            body={(rowData) => (
              <>
                <span style={{ fontSize: "10px" }}>
                  {rowData &&
                    rowData.orderplaced &&
                    rowData.orderplaced.at &&
                    rowData.orderplaced.at.split("T")[0]}
                </span>
              </>
            )}
          />
          <Column
            field="paymentsDetails.totalCharges"
            header="Order Amount"
            style={{ fontSize: "10px" }}
          />
          <Column
            field="orderdelivered.at"
            header="Date"
            style={{ fontSize: "10px" }}
            body={(rowData) => (
              <>
                <span style={{ fontSize: "10px" }}>
                  {rowData &&
                  rowData.orderdelivered &&
                  rowData.orderdelivered.at
                    ? rowData.orderdelivered.at.split("T")[0]
                    : "Not Delivered"}
                </span>
              </>
            )}
          />
          <Column
            field="orderShipped.at"
            header="Shipping Status"
            style={{ fontSize: "10px" }}
            body={(rowData) => (
              <>
                <span style={{ fontSize: "10px" }}>
                  {rowData && rowData.orderShipped && rowData.orderShipped.at
                    ? rowData.orderShipped.at.split("T")[0]
                    : "Not Shipped"}
                </span>
              </>
            )}
          />
        </DataTable>
      </Sidebar>
      <Toast ref={toast} />
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <h5>Users</h5>
            <DataTable
              ref={dt}
              value={users}
              className="datatable-responsive"
              rows={5}
              paginator
              globalFilter={globalFilter}
              header={header}
              dataKey="_id"
            >
              {/*<Column header="Profile photo" style={{fontSize:"12px",overflow: "hidden"}} body={(data) => <img src={data.userDetails.profilePhoto} alt={data.userDetails.profilePhoto} width="50" />} />*/}
              <Column
                field="userDetails.name"
                header="Name"
                style={{ fontSize: "12px" }}
              />
              <Column
                field="userDetails.email"
                header="Email"
                style={{
                  fontSize: "12px",
                  overflowWrap: "break-word",
                  width: "auto",
                }}
              />
              <Column
                field="userDetails.phonrno"
                header="Phone"
                style={{ fontSize: "12px", textAlign: "center" }}
              />
              <Column
                field="userDetails.dob"
                header="Dob"
                style={{ fontSize: "12px" }}
              />
              <Column
                field="added.at"
                header="Date"
                style={{
                  fontSize: "12px",
                  overflowWrap: "break-word",
                  width: "auto",
                }}
                sortable="custom"
                sortFunction={(event) => {
                  let data = users.sort((x, y) => (new Date(x.userDetails.created.at).getTime() - new Date(y.userDetails.created.at).getTime()) * event.order);

                  return data;
                }}
                body={(rowData) => (
                  <>
                    <span
                      style={{
                        fontSize: "12px",
                        overflowWrap: "break-word",
                        width: "auto",
                      }}
                    >
                      {rowData.userDetails &&
                        moment(
                          rowData.userDetails.created.at
                        ).format('DD/MM/YYYY, HH:mm:ss')}
                    </span>
                  </>
                )}
              />
              <Column
                field="userDetails.orders"
                header="Orders"
                style={{ fontSize: "12px" }}
              />
              <Column
                field="userDetails.status"
                header="Status"
                body={activestatusBodyTemplate}
                sortable={true}
                style={{ fontSize: "12px" }}
              />
              <Column
                header="View"
                style={{ fontSize: "12px" }}
                body={(rowData) => (
                  <>
                    <Button
                      icon="pi pi-search"
                      type="button"
                      className="p-button-text"
                      onClick={() => {
                        setVisibleRight(true);
                        setUserDetails(rowData);
                        getOrders(rowData.userDetails._id);
                      }}
                    />
                  </>
                )}
              />
              <Column
                body={actionBodyTemplate}
                style={{ fontSize: "12px" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
      <Dialog
        visible={deleteUserDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteUserDialogFooter}
        onHide={hideDeleteUserDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {userDetails && (
            <span>
              Are you sure you want to deactivate <b>{userDetails.name}'s</b>{" "}
              account ?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        header="Product Details"
        visible={openProductModal}
        style={{ width: "50vw" }}
        onHide={() => setOpenProductModal(false)}
        modal
      >
        <div style={{ textAlign: "center" }}>
          {currentProductDetails &&
            currentProductDetails?.itemImages.map((item, index) => {
              return (
                <img
                  style={{
                    width: 180,
                    margin: 10,
                    objectFit: "contain",
                  }}
                  src={item}
                />
              );
            })}
        </div>
        <ProductTable
          c1="Name"
          c2={currentProductDetails && currentProductDetails?.itemName}
          c3={currentProductDetails?.itemName}
        />
        <ProductTable
          c1="Brand"
          c2={currentProductDetails && currentProductDetails?.itemBrand}
          c3={currentProductDetails?.itemBrand?.name}
        />
        <ProductTable
          c1="Catergory"
          c2={currentProductDetails && currentProductDetails?.itemCategory}
          c3={currentProductDetails?.itemCategory?.name}
        />
        <ProductTable
          c1="Price"
          c2={currentProductDetails && currentProductDetails?.itemPrice}
          c3={
            currentProductDetails?.itemDeal
              ? disCountPrice + " NGN"
              : currentProductDetails?.itemPrice + " NGN"
          }
        />
        <ProductTable
          c1="Deals"
          c2={currentProductDetails && currentProductDetails?.itemDeal}
          c3={currentProductDetails?.itemDeal}
        />
        <ProductTable
          c1="Quantity"
          c2={currentProductDetails && currentProductDetails?.quantity}
          c3={currentProductDetails?.quantity}
        />{" "}
        {/*<ProductTable*/}
        {/*    c1="Rating"*/}
        {/*    c2={currentProductDetails && currentProductDetails?.rating}*/}
        {/*    c3={currentProductDetails?.rating}*/}
        {/*/>*/}
      </Dialog>

      <Dialog
        visible={relistUserDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={relistUserDialogFooter}
        onHide={hideRelistUserDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {userDetails && (
            <span>
              Are you sure you want to activate <b>{userDetails.name}'s</b>{" "}
              account ?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};
