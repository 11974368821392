import React,{useState,useEffect,useRef} from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BannerService } from '../service/BannerService';
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { PreSignedUrlService } from '../service/PreSignedUrlService';
import axios from "axios";
import {CustomLoader} from "../loader";
export const BannerTable = () => {
    let emptyBanner = {
        name: "",
        images: null,
        description:"",
        text:"",
        bannertype:""
    };
    const [banner, setBanner] = useState(null);
    const [deleteBannerDialog, setDeleteBannerDialog] = useState(false);
    const [activeBannerDialog, setActiveBannerDialog] = useState(false);
    const [relistBannerDialog, setRelistBannerDialog] = useState(false);
    const [bannerDetails, setBannerDetails] = useState(emptyBanner);
    const toast = useRef(null);
    const [edit,setEditUpdate] = useState(false)
    const [images, setImage] = useState("")
    const [loader, setLoader] = useState(false);
    const [bannerUpdateDialog, setBannerUpdateDialog] = useState(false);
    const [banid,setBanid] = useState("");
    const [selectedBannerType, setSelectedBannerType] = useState(null);
    const bannertype = [
        {key:"1",name:"Position-1"},
        {key:"2",name:"Position-2"},
    ]

    useEffect(() => {
        const bannerService = new BannerService();
        const config = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'admin/bnr',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        bannerService.getBanners(config).then(data => setBanner(data.reverse()));
    }, []);
    useEffect(() => {
        const bannerService = new BannerService();
        const config = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'admin/bnr',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        bannerService.getBanners(config).then(data => setBanner(data.reverse()));
    }, [deleteBannerDialog,relistBannerDialog,edit,bannerDetails,activeBannerDialog]);

    const onBannerTypeChange = (e) => {
        setSelectedBannerType(e.value);
        let bannerEdit = {...bannerDetails};
        bannerEdit["bannerType"] = e.value.name;

        setBannerDetails(bannerEdit);
    }

    const activestatusBodyTemplate = (rowData) => {
        if (rowData.status == "Active") {
            return (
                <>
                    <span className={`product-badge status-instock`}>{rowData.status}</span>
                </>
            );
        } else {
            return (
                <>
                    <span className={`product-badge status-outofstock`}>Not Active</span>
                </>
            );
        }
    };
    const hideDeleteBannerDialog = () => {
        setDeleteBannerDialog(false);
    };
    const hideActiveBannerDialog = () => {
        setActiveBannerDialog(false);
    };
    const hideRelistBannerDialog = () => {
        setRelistBannerDialog(false);
    };
    const deleteBanner = () => {
        const bannerService = new BannerService();
        const config = {
            method: "delete",
            url: process.env.REACT_APP_APIBASE+"admin/bnr/"+banid,
            //url: process.env.REACT_APP_APIBASE+'admin/bnr/'+banid,
            headers: {
                "Content-Type": "application/json",
            }
        };
        bannerService.deleteBanner(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Banner delete successfully", life: 3000 });
                setDeleteBannerDialog(false);
                setBannerDetails(emptyBanner);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while delete banner", life: 3000 });
            }
        });
    };

    const activeBanner = () => {
        const bannerService = new BannerService();
        const config = {
            method: "put",
            url: process.env.REACT_APP_APIBASE+"admin/bnr/"+banid,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Active" },
        };
        bannerService.deleteBanner(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Banner active successfully", life: 3000 });
                setActiveBannerDialog(false);
                setBannerDetails(emptyBanner);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while active banner", life: 3000 });
            }
        });
    };

    const relistBanner = () => {
        const bannerService = new BannerService();
        const config = {
            method: "put",
            url: process.env.REACT_APP_APIBASE+"admin/bnr/"+banid,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Not Active" },
        };
        bannerService.relistBanner(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Banner relisted successfully", life: 3000 });
                setRelistBannerDialog(false);
                setBannerDetails(emptyBanner);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while relisting banner", life: 3000 });
            }
        });
    };


    const updateBanner = () => {
        const bannerervice = new BannerService();
        let errors = {};
        if (!bannerDetails.name) {
            errors.name = 'Name is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.name, life: 3000});
            return;
        }

        const banner = {
            "name": bannerDetails.name,
            "images": images,
            "description":"",
            "text":"",
            "bannertype":bannerDetails.bannerType
        }
        if (edit === true){
            const config = {
                method: 'put',
                url: process.env.REACT_APP_APIBASE+"admin/bnr/update/"+banid,
                headers: {
                    'Content-Type' : 'application/json'
                },
                data: banner
            }
            bannerervice.updateBanner(config).then(data => {if (data.status === "success") {
                toast.current.show({severity:'success', summary: 'Success', detail:'Banner updated successfully', life: 3000});
                setBannerUpdateDialog(false);
                setBannerDetails(emptyBanner);
                setEditUpdate(false);
                setBanid("");
            }else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Error while updating banner', life: 3000});
            }});
            return
        }
    };


    const bannerUpdateDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={()=>{setBannerUpdateDialog(false)}} />
            <Button label="Save" icon="pi pi-check" className="p-button-text"  onClick={updateBanner}/>
        </>
    );

    const deleteBannerDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteBannerDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteBanner} />
        </>
    );
    const activeBannerDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideActiveBannerDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={activeBanner} />
        </>
    );
    const relistBannerDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRelistBannerDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={relistBanner} />
        </>
    );

    const editBanner = (banner) => {
        setBannerDetails({ ...banner });
        console.log(banner);
        setEditUpdate(true)
        setBanid(banner._id)
        setImage(banner.images)
        setBannerUpdateDialog(true);
        setSelectedBannerType(banner.bannertype);

        console.log(banner.images);
    };
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _banner = { ...bannerDetails };
        _banner[`${name}`] = val;
        console.log('banner name', _banner)
        setBannerDetails(_banner)
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{width: "150px"}} >
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1" onClick={() => editBanner(rowData)} />
                {rowData.status =="Active" && <Button icon="pi pi-lock" className="p-button-rounded p-button-warning  mr-1"  onClick={() => {setBannerDetails(rowData);setBanid(rowData["_id"]);setActiveBannerDialog(true);}} />}
                {rowData.status =="Not Active" && <Button icon="pi pi-unlock" className="p-button-rounded p-button-info  mr-1"  onClick={() => {setBannerDetails(rowData);setBanid(rowData["_id"]);setRelistBannerDialog(true);}} />}
                { <Button icon="pi pi-trash" className="p-button-rounded p-button-warning"  onClick={() => {setBannerDetails(rowData);setBanid(rowData["_id"]);setDeleteBannerDialog(true);}} />}

            </div>
        );
    };
    const [selectedStatF, setSelectedStatF] = useState(null);
    const dt = useRef(null);
    const statItemTemplate = (option) => {
        return <span style={{fontSize:"14px",fontWeight:600,textTransform:'capitalize'}}>{option}</span>;
    }
    const onStatChange = (e) => {
        dt.current.filter(e.value, 'status', 'equals');
        setSelectedStatF(e.value);
    }
    const statFilter = <Dropdown value={selectedStatF} options={["Active","Not Active"]} onChange={onStatChange} itemTemplate={statItemTemplate} placeholder="Select a Status" option showClear style={{width:"110px",fontSize:'10px'}}/>;

    const onTemplateUpload = (e) => {
        onUpload(e.files)
    }
    const onUpload = (e) => {
        setLoader(true);
        // const data = {
        //     "fileName":e[0].name,
        //     "fileType":e[0].type.split("/")[1],
        // }
        // const objectUrl = e[0]
        // const type = e[0].type
        // const config = {
        //     method: 'post',
        //     url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
        //     headers: {
        //         'Content-Type' : 'application/json'
        //     },
        //     data: data
        // }
        // const preSignedUrlService = new PreSignedUrlService();
        // preSignedUrlService.generatePreSignedUrl(config).then(data => {if (data.url !== "") {
        //     setImage(data.url);
        //     preSignedUrlService.uploadOnGCP(data.url,objectUrl,type ).then(resp =>{
        //         toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
        //     }).catch((error)=>{
        //         toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        //     })
        // }else{
        //     toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        // }});


        var data = new FormData();
        data.append('image', e[0]);

        var config = {
            method: 'post',
            url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data : data
        };

        axios(config)
        .then(function (response) {
            console.log(response.data);
            let {url} = response.data;

            setImage(url);
            setLoader(false);
            toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
        })
        .catch(function (error) {
            console.log(error);
            setLoader(false);
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        });
    }

    return (
        <>
            {loader && CustomLoader()}
        <Toast ref={toast} />
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Banners</h5>
                    <DataTable ref={dt} value={banner} className="datatable-responsive" rows={5} paginator>
                        <Column header="Image" body={(data) => <img src={data.images} alt={data.image} width="50" />} />
                        <Column field="name" header="Banner Name" sortable style={{fontSize:"12px"}}/>
                        {/*<Column field="description" header="Description" sortable style={{fontSize:"12px"}}/>
                        <Column field="text" header="Banner text" sortable style={{fontSize:"12px"}}/>*/ }
                        <Column field="bannertype" header="Banner type" style={{fontSize:"12px"}}/>
                        <Column field="status" header="Status" body={activestatusBodyTemplate} filter filterElement={statFilter}/>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>

        <Dialog visible={bannerUpdateDialog} style={{ width: "550px" }} header="Banner Details" modal className="p-fluid" footer={bannerUpdateDialogFooter} onHide={()=>{setBannerUpdateDialog(false)}}>
                            {bannerDetails.images && <img src={bannerDetails.images} alt={bannerDetails.images} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                            <div className="field col-12">
                            <label htmlFor="brandImage">Brand image</label>
                            <FileUpload name="demo[]" customUpload={true} uploadHandler={onTemplateUpload} onUpload={onTemplateUpload} accept="image/png,image/jpg,image/jpeg" maxFileSize={1000000}
                                    emptyTemplate={<p className="p-m-3" style={{fontSize:"15px"}}>Drag and drop files to here to upload.</p>} auto />
                            </div>
                            <div className="field">
                                <label htmlFor="name">Name</label>
                                <InputText id="name" value={bannerDetails.name} onChange={(e) => onInputChange(e, "name")} required autoFocus />
                            </div>
                            {/*<div className="field">
                                <label htmlFor="name">Description</label>
                                <InputText id="name" value={bannerDetails.description} onChange={(e) => onInputChange(e, "description")} required autoFocus />
                            </div>
                            <div className="field">
                                <label htmlFor="name">Banner Text</label>
                                <InputText id="name" value={bannerDetails.text} onChange={(e) => onInputChange(e, "text")} required autoFocus />
                            </div>*/}

                        <div className="field col-12">
                            <label htmlFor="bannerText">Banner type</label>
                            <Dropdown value={selectedBannerType} options={bannertype} onChange={onBannerTypeChange} optionLabel="name" placeholder={bannerDetails?bannerDetails.bannertype:"Select a Banner Type"} />
                        </div>

                        </Dialog>
            <Dialog visible={deleteBannerDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteBannerDialogFooter} onHide={hideDeleteBannerDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {bannerDetails && (
                        <span>
                            Are you sure you want to delete <b>{bannerDetails.name}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={activeBannerDialog} style={{ width: "450px" }} header="Confirm" modal footer={activeBannerDialogFooter} onHide={hideActiveBannerDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {bannerDetails && (
                        <span>
                            Are you sure you want to deactivate <b>{bannerDetails.name}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={relistBannerDialog} style={{ width: "450px" }} header="Confirm" modal footer={relistBannerDialogFooter} onHide={hideRelistBannerDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {bannerDetails && (
                        <span>
                            Are you sure you want to activate <b>{bannerDetails.name}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>
        </>
    );
};
