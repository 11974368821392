import React,{useState,useEffect,useRef} from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { UserService } from '../service/UsersService';
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { Divider } from 'primereact/divider';
import { OrderService } from '../service/OrderService';
export const AdminUserTable = () => {
    let emptyUser = {
        name: ""
    };
    const [users, setUsers] = useState(null);
    const [visibleRight, setVisibleRight] = useState(false);
    const toast = useRef(null);
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [relistUserDialog, setRelistUserDialog] = useState(false);
    const [activeUserDialog, setActiveUserDialog] = useState(false);
    const [userDetails, setUserDetails] = useState(emptyUser);
    const [uid,setUid] = useState("")
    useEffect(() => {
        const userService = new UserService();
        const config = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'admin/profile/site',
            //url: process.env.REACT_APP_APIBASE+'admin/profile/site',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        userService.getUsers(config).then(data => setUsers(data.reverse()));

    }, []);
    useEffect(() => {
        const userService = new UserService();
        const config = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'admin/profile/site',
            //url: process.env.REACT_APP_APIBASE+'admin/profile/site',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        userService.getUsers(config).then(data => setUsers(data.reverse()));

    }, [deleteUserDialog,relistUserDialog,activeUserDialog]);
    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
    };
    const hideActiveUserDialog = () => {
        setActiveUserDialog(false);
    };
    const hideRelistUserDialog = () => {
        setRelistUserDialog(false);
    };
    const [orderDetails, setOrderDetails] = useState(null);
    const [recentorderDetails, setRecentOrderDetails] = useState(null);

    const deleteUser = () => {
        const userService = new UserService();
        const config = {
            method: "delete",
            url: process.env.REACT_APP_APIBASE+"home/user/profile/"+uid,
            //url: process.env.REACT_APP_APIBASE+"home/user/profile/"+uid,
            headers: {
                "Content-Type": "application/json",
            }
        };
        userService.deleteUser(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Account deleted successfully", life: 3000 });
                setDeleteUserDialog(false);
                setUserDetails(emptyUser);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while deleted account", life: 3000 });
            }
        });
    };

    const activeUser = () => {
        const userService = new UserService();
        const config = {
            method: "put",
            url: process.env.REACT_APP_APIBASE+"home/user/profile/"+uid,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Active" },
        };
        userService.deleteUser(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Account deactivated successfully", life: 3000 });
                setActiveUserDialog(false);
                setUserDetails(emptyUser);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while deactivating account", life: 3000 });
            }
        });
    };


    const relistUser = () => {
        const userService = new UserService();
        const config = {
            method: "put",
            url: process.env.REACT_APP_APIBASE+"home/user/profile/"+uid,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Not Active" },
        };
        userService.relistUser(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Account activated successfully", life: 3000 });
                setRelistUserDialog(false);
                setUserDetails(emptyUser);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while reactivating account", life: 3000 });
            }
        });
    };
    const deleteUserDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteUser} />
        </>
    );
    const activeUserDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideActiveUserDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={activeUser} />
        </>
    );
    const relistUserDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRelistUserDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={relistUser} />
        </>
    );
    const activestatusBodyTemplate = (rowData) => {
        if (rowData.userDetails.status == "Active") {
            return (
                <>
                    <span className={`product-badge status-instock`}>{rowData.userDetails.status}</span>
                </>
            );
        } else {
            return (
                <>
                    <span className={`product-badge status-outofstock`}>Not Active</span>
                </>
            );
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                { rowData.userDetails.status =="Active" && <Button icon="pi pi-lock" className="p-button-rounded p-button-warning mr-2" onClick={() => {setUserDetails(rowData.userDetails);setUid(rowData.userDetails["_id"]);setActiveUserDialog(true);}} /> }
                { rowData.userDetails.status =="Not Active" && <Button icon="pi pi-unlock" className="p-button-rounded p-button-info mr-2" onClick={() => {setUserDetails(rowData.userDetails);setUid(rowData.userDetails["_id"]);setRelistUserDialog(true);}} /> }
                { <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2"  onClick={() => {setUserDetails(rowData);setUid(rowData.userDetails["_id"]);setDeleteUserDialog(true);}} /> }


            </div>
        );
    };
    return (
        <>
        <Toast ref={toast} />
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Admin Users</h5>
                    <DataTable value={users} className="datatable-responsive" rows={5} paginator>
                        <Column field="userDetails.name" header="Name"  style={{fontSize:"12px"}}/>
                        <Column field="userDetails.email" header="Email"  style={{fontSize:"12px"}}/>
                        <Column field="userDetails.phoneno" header="Phone"  style={{fontSize:"12px"}}/>
                        <Column field="userDetails.status" header="Status"  body={activestatusBodyTemplate} style={{fontSize:"12px"}}/>
                        <Column body={actionBodyTemplate} style={{fontSize:"12px"}}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
        <Dialog visible={deleteUserDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {userDetails && (
                        <span>
                            Are you sure you want to delete <b>{userDetails.name}</b> account ?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={activeUserDialog} style={{ width: "450px" }} header="Confirm" modal footer={activeUserDialogFooter} onHide={hideActiveUserDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {userDetails && (
                        <span>
                            Are you sure you want to deactivate <b>{userDetails.name}'s</b> account ?
                        </span>
                    )}
                </div>
            </Dialog>


            <Dialog visible={relistUserDialog} style={{ width: "450px" }} header="Confirm" modal footer={relistUserDialogFooter} onHide={hideRelistUserDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {userDetails && (
                        <span>
                            Are you sure you want to activate <b>{userDetails.name}'s</b> account ?
                        </span>
                    )}
                </div>
            </Dialog>
        </>
    );
};
