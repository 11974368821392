import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect, useRef } from 'react';
import {useHistory} from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import "./customButton.css"
import { Toast } from 'primereact/toast';
import { CategoryService } from '../service/CategoryService';
import { CityService } from '../service/CityService';
import { UserService } from '../service/UsersService';
export const DeliveryCityFormLayout = () => {
    const history = useHistory();
    const [radio, setRadio] = useState('Brands');
    const [selectedTypeY,setSelectedTypeY]=useState("Nigeria");
    const [selectedTypeD,setSelectedTypeD]=useState(null);
    const [city, setCity] = useState(null);
    const toast = useRef(null);
    const onTypeDChange = (e) => {
        setSelectedTypeD(e.value);
    }
    const onTypeYChange = (e) => {
        setSelectedTypeY(e.value);
    }
    
    const citySelectItems = [
        {label: 'Nigeria', value: 'Nigeria'},
       ];
    useEffect(() => {
       const data = {
            "country": "nigeria",
            "state": "Lagos"
        }
        const userService = new UserService();
        const config3 = {
            method: 'post',
            url: 'https://countriesnow.space/api/v0.1/countries/state/cities',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: data
        }
        userService.getCity(config3).then(data =>setCity(data["data"]));
    }, []);
    
    const onSubmit = () =>{
        const cityService = new CityService();
        const t = selectedTypeD;
        const country = selectedTypeY;
        const d = radio;
        let errors = {};
        if (!selectedTypeD) {
            errors.name = 'City name is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.name, life: 3000});
            return;
        }

        const body = {
            "city_name": t,
            "country":country,
            "delivery_type":"Available",
        }
        const config = {
            method: 'post',
            url: process.env.REACT_APP_APIBASE+'home/delivery',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: body
        }
        cityService.addCity(config).then(data => {if (data.success === true) {
            toast.current.show({severity:'success', summary: 'Success', detail:'Delivery City added successfully', life: 3000});
            history.push('/delivery_city');
        }else{
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while adding a new Delivery City', life: 3000});
        }});
  }
    return (
        <>
        <Toast ref={toast} />
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Add City</h5>
                    <div className="p-fluid formgrid grid">
                    <div className="field col-12">
                            <label htmlFor="dealCategory">Country</label>
                            <Dropdown value={selectedTypeY} options={citySelectItems} onChange={onTypeYChange} placeholder="Select Country" />
                         </div>
                       <div className="field col-12">
                            <label htmlFor="dealCategory">City</label>
                            <Dropdown value={selectedTypeD} options={city} onChange={onTypeDChange} placeholder="Select City" />
                         </div>
                          {/*<div className="field col-12">
                            <label htmlFor="dealCategory">Shipping Type</label>
                         </div>
                        <div className="p-field-radiobutton">
                            <RadioButton inputId="radio1" name="radio" value="Available" onChange={(e) => setRadio(e.value)} />
                            <label htmlFor="radio1">  Available</label>
                        </div>
                        <div className="p-field-radiobutton" style={{marginLeft:"20px"}}>
                            <RadioButton inputId="radio2" name="radio" value="Not Available" onChange={(e) => setRadio(e.value)} />
                            <label htmlFor="radio2">  Not Available</label>
                        </div>*/}
                      
                        <div className="field col-12">
                        <Button className="p-button-rounded formButton" onClick={onSubmit}>Add City</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
