import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CityService } from "../service/CityService";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { UserService } from "../service/UsersService";
export const DeliveryCityTable = () => {
  let emptyDeals = {
    name: "",
    images: null,
    forSpecificCategory: false,
    categoryName: "-",
    forSpecificBrand: false,
    brandName: "-",
    delivery_type: "",
  };
  const [city, setCity] = useState(null);
  const [deleteCityDialog, setDeleteCityDialog] = useState(false);
  const [activeCityDialog, setActiveCityDialog] = useState(false);
  const [relistCityDialog, setRelistCityDialog] = useState(false);
  const [cityDetails, setCityDetails] = useState(emptyDeals);
  const toast = useRef(null);
  const [edit, setEditUpdate] = useState(false);
  const [cityUpdateDialog, setCityUpdateDialog] = useState(false);
  const [did, setDid] = useState("");

  const [brands, setBrands] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [setOptionCity, setCityOptions] = useState(null);
  const [countryDetails, setCountryDetails] = useState("Nigeria");
  const citySelectItems = [{ label: "Nigeria", value: "Nigeria" }];

  /* useEffect(() => {
        const dealsService = new DealService();
        const config = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'admin/deals',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        dealsService.getDeals(config).then(data => setDeals(data));


        const categoryService = new CategoryService();
        const config1 = {
            method: "get",
            url: process.env.REACT_APP_APIBASE+"admin/getCategories",
            headers: {
                "Content-Type": "application/json",
            },
        };
        categoryService.getCategories(config1).then((data) => setCategory(data));
        const brandsService = new BrandService();
        const config2 = {
            method: "get",
            url: process.env.REACT_APP_APIBASE+"admin/admin/brands",
            headers: {
                "Content-Type": "application/json",
            },
        };
        brandsService.getBrands(config2).then((data) => setBrands(data));
    }, []);*/

  useEffect(() => {
    const data = {
      country: "nigeria",
      state: "Lagos",
    };
    const userService = new UserService();
    const config3 = {
      method: "post",
      url: "https://countriesnow.space/api/v0.1/countries/state/cities",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    userService.getCity(config3).then((data) => setCityOptions(data["data"]));
    const cityService = new CityService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "home/deliverycities",
      headers: {
        "Content-Type": "application/json",
      },
    };
    cityService.getCity(config).then((data) => setCity(data.data.reverse()));
  }, [deleteCityDialog, relistCityDialog, edit, activeCityDialog]);
  //deleteCityDialog,relistCityDialog,edit,cityDetails,setlectedCity

  const activestatusBodyTemplate = (rowData) => {
    if (rowData.status == "Active") {
      return (
        <>
          <span className={`product-badge status-instock`}>
            {rowData.status}
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className={`product-badge status-outofstock`}>Not Active</span>
        </>
      );
    }
  };
  const hidedeleteCityDialog = () => {
    setDeleteCityDialog(false);
  };

  const hideactiveCityDialog = () => {
    setActiveCityDialog(false);
  };
  const hiderelistCityDialog = () => {
    setRelistCityDialog(false);
  };
  const deleteCity = () => {
    const cityService = new CityService();
    const config = {
      method: "delete",
      url: process.env.REACT_APP_APIBASE + "home/delivery/" + did,
      //url: process.env.REACT_APP_APIBASE+'home/delivery/'+did,
      headers: {
        "Content-Type": "application/json",
      },
      data: { _id: did },
    };
    cityService.deleteCity(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "City Status successfully",
          life: 3000,
        });
        setDeleteCityDialog(false);
        setCityDetails(emptyDeals);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while city action",
          life: 3000,
        });
      }
    });
  };

  const activeCity = () => {
    const cityService = new CityService();
    const config = {
      method: "put",
      url: process.env.REACT_APP_APIBASE + "home/delivery/" + did,
      headers: {
        "Content-Type": "application/json",
      },
      data: { status: "Not Active", _id: did },
    };
    cityService.deleteCity(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "City Status successfully",
          life: 3000,
        });
        setActiveCityDialog(false);
        setCityDetails(emptyDeals);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while city action",
          life: 3000,
        });
      }
    });
  };

  const relistDeal = () => {
    const cityService = new CityService();
    const config = {
      method: "put",
      url: process.env.REACT_APP_APIBASE + "home/delivery/" + did,
      headers: {
        "Content-Type": "application/json",
      },
      data: { status: "Active", _id: did },
    };
    cityService.relistCity(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "City relisted successfully",
          life: 3000,
        });
        setRelistCityDialog(false);
        setCityDetails(emptyDeals);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while relisting city",
          life: 3000,
        });
      }
    });
  };

  const updateDeal = () => {
    const cityService = new CityService();
    if (edit === true) {
      const config = {
        method: "put",
        url: process.env.REACT_APP_APIBASE + "home/delivery/" + did,
        headers: {
          "Content-Type": "application/json",
        },
        data: cityDetails,
      };
      cityService.updateCity(config).then((data) => {
        if (data.success === true) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "City updated successfully",
            life: 3000,
          });
          setCityUpdateDialog(false);
          setCityDetails(emptyDeals);
          setEditUpdate(false);
          setDid("");
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error while updating city",
            life: 3000,
          });
        }
      });
      return;
    }
  };

  const cityUpdateDialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          setCityUpdateDialog(false);
        }}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={updateDeal}
      />
    </>
  );

  const deleteCityDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hidedeleteCityDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteCity}
      />
    </>
  );
  const activeCityDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideactiveCityDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={activeCity}
      />
    </>
  );
  const relistCityDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hiderelistCityDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={relistDeal}
      />
    </>
  );
  const editDeal = (deal) => {
    setCityDetails({ ...deal });
    setEditUpdate(true);
    setDid(deal._id);
    setSelectedBrand({ name: deal.city_name });
    //setImage(deal.images)
    //setCityOptions({"name":deal.city_name})
    setCityUpdateDialog(true);
  };
  const onCityChange = (e) => {
    let _deal = { ...cityDetails };
    _deal["city_name"] = e.value;
    setCityDetails(_deal);
    setSelectedBrand(e.value);
  };
  const onCountryChange = (e) => {
    let _deal = { ...cityDetails };
    _deal["country"] = e.value;
    setCountryDetails(_deal);
  };
  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _deal = { ...cityDetails };
    if (name === "Paid Shipping") {
      _deal[`delivery_type`] = val;
    } else if (name === "Free Shipping") {
      _deal[`delivery_type`] = val;
    } else {
      _deal[`delivery_type`] = val;
    }

    setCityDetails(_deal);
  };
  const onCategoryChange = (e) => {
    let _deal = { ...cityDetails };
    _deal["categoryName"] = e.value;
    setCityDetails(_deal);
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editDeal(rowData)}
        />
        {/* {rowData.status == "Active" && (
          <Button
            icon="pi pi-lock"
            className="p-button-rounded p-button-warning mr-2"
            onClick={() => {
              setCityDetails(rowData);
              setDid(rowData["_id"]);
              setActiveCityDialog(true);
            }}
          />
        )} */}
        {/* {rowData.status == "Not Active" && (
          <Button
            icon="pi pi-unlock"
            className="p-button-rounded p-button-info mr-2"
            onClick={() => {
              setCityDetails(rowData);
              setDid(rowData["_id"]);
              setRelistCityDialog(true);
            }}
          />
        )} */}
        {
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-warning mr-2"
            onClick={() => {
              setCityDetails(rowData);
              setDid(rowData["_id"]);
              setDeleteCityDialog(true);
            }}
          />
        }
      </div>
    );
  };
  const [selectedStatF, setSelectedStatF] = useState(null);
  const dt = useRef(null);
  const statItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option}
      </span>
    );
  };
  const onStatChange = (e) => {
    dt.current.filter(e.value, "status", "equals");
    setSelectedStatF(e.value);
  };
  const statFilter = (
    <Dropdown
      value={selectedStatF}
      options={["Active", "Not Active"]}
      onChange={onStatChange}
      itemTemplate={statItemTemplate}
      placeholder="Select a Status"
      option
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );

  return (
    <>
      <Toast ref={toast} />
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <h5>Cities</h5>
            <DataTable
              ref={dt}
              value={city}
              className="p-datatable-customers"
              rows={5}
              paginator
            >
              <Column
                header="Country"
                body={(rowData) => (
                  <>
                    <span style={{ fontSize: "12px" }}>Nigeria</span>
                  </>
                )}
                sortable
                style={{ fontSize: "12px" }}
              />
              <Column
                field="city_name"
                header="City"
                sortable
                style={{ fontSize: "12px" }}
              />
              <Column
                field="status"
                header="Status"
                body={activestatusBodyTemplate}
                filter
                filterElement={statFilter}
              />
              <Column body={actionBodyTemplate}></Column>
            </DataTable>
          </div>
        </div>
      </div>

      <Dialog
        visible={cityUpdateDialog}
        style={{ width: "450px" }}
        header="City Details"
        modal
        className="p-fluid"
        footer={cityUpdateDialogFooter}
        onHide={() => {
          setCityUpdateDialog(false);
        }}
      >
        {cityDetails.city_name && (
          <div className="field">
            <label htmlFor="dealCategory">Country</label>
            <Dropdown
              value={countryDetails}
              options={citySelectItems}
              onChange={onCountryChange}
              placeholder="Select Country"
            />
          </div>
        )}
        {cityDetails.city_name && (
          <div className="field">
            <label htmlFor="description">City</label>
            <Dropdown
              value={selectedBrand}
              options={setOptionCity}
              onChange={onCityChange}
              placeholder={
                selectedBrand ? selectedBrand["name"] : "Select City"
              }
            />
          </div>
        )}
      </Dialog>
      <Dialog
        visible={deleteCityDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteCityDialogFooter}
        onHide={hidedeleteCityDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {cityDetails && (
            <span>
              Are you sure you want to delete <b>{cityDetails.city_name}</b> ?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={activeCityDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={activeCityDialogFooter}
        onHide={hideactiveCityDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {cityDetails && (
            <span>
              Are you sure you want to INactive <b>{cityDetails.city_name}</b> ?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={relistCityDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={relistCityDialogFooter}
        onHide={hiderelistCityDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {cityDetails && (
            <span>
              Are you sure you want to active city <b>{cityDetails.name}</b> ?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};
