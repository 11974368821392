import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect, useRef } from 'react';
import {useHistory} from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton';
import "./customButton.css"
import { PreSignedUrlService } from '../service/PreSignedUrlService';
import { Toast } from 'primereact/toast';
import { CategoryService } from '../service/CategoryService';
import { BrandService } from '../service/BrandsService';
import { DealService } from '../service/DealService';
import { ProductService } from '../service/ProductService';
import axios from 'axios';
import { MultiSelect } from 'primereact/multiselect';
import {CustomLoader} from "../loader";
export const DealsFormLayout = () => {
    const [radio, setRadio] = useState('Brand');
    const [category, setCategory] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedTypeD,setSelectedTypeD]=useState(null);
    const [brands, setBrands] = useState(null);
    const [deal, setDeal] = useState(null);
    const toast = useRef(null);
    const [loader, setLoader] = useState(false);
    const [image, setImage] = useState("")
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [product, setProducts] = useState(null);
    const [selectedSingle, setSelectedSingle] = useState(null);
    const history = useHistory();
    const onCategoryChange = (e) => {
        setSelectedCategory(e.value);
    }
    const onTypeDChange = (e) => {
        setSelectedTypeD(e.value);
    }
    const onBrandChange = (e) => {
        setSelectedBrand(e.value);
    }
    const onSingleChange = (e) => {
        setSelectedSingle(e.value);
    }
    useEffect(() => {
        const categoryService = new CategoryService();
        const config = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'admin/getCategories',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        categoryService.getCategories(config).then(data => setCategory(data));
        const brandsService = new BrandService();
        const config2 = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'admin/admin/brands',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        brandsService.getBrands(config2).then(data => setBrands(data));
        const productService = new ProductService();

            const config22 = {
                method: "get",
                url: process.env.REACT_APP_APIBASE+"admin/item",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            productService.getProduct(config22).then((data) => setProducts(data));
    }, []);

    const onTemplateUpload = (e) => {
        onUpload(e.files)
    }
    const onUpload = (e) => {
        setLoader(true);
        var data = new FormData();
        data.append('image', e[0]);

        const config = {
            method: 'post',
            url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: data
        }
        axios(config)
        .then(function (response) {
            console.log(response.data);
            let {url} = response.data;

            setImage(url[0]);
            setLoader(false);
            toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
        })
        .catch(function (error) {
            console.log(error);
            setLoader(false);
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        });

        // const data = {
        //     "fileName":e[0].name,
        //     "fileType":e[0].type.split("/")[1],
        // }
        // const objectUrl = e[0]
        // const type = e[0].type
        // const config = {
        //     method: 'post',
        //     url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
        //     headers: {
        //         'Content-Type' : 'application/json'
        //     },
        //     data: data
        // }
        // const preSignedUrlService = new PreSignedUrlService();
        // preSignedUrlService.generatePreSignedUrl(config).then(data => {if (data.url !== "") {
        //     setImage(data.url);
        //     preSignedUrlService.uploadOnGCP(data.url,objectUrl,type ).then(resp =>{
        //         toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
        //     }).catch((error)=>{
        //         toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        //     })
        // }else{
        //     toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        // }});
    }
    const onSubmit = () =>{
        const dealService = new DealService();
        const t = selectedTypeD?selectedTypeD:"off"
        const d = deal +" " +t;
        let errors = {};
        if (!deal) {
            errors.name = 'Deal name is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.name, life: 3000});
            return;
        }
        if (!selectedTypeD) {
            errors.name = 'Deal type is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.name, life: 3000});
            return;
        }

        if (!image) {
            errors.image = 'image upload is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.image, life: 3000});
            return;
        }

        const body = {
            "name": d,
            "images": image,
            //"forSpecificCategory":radio==="Category"?true:false,
            //"categoryName":radio==="Category"?selectedCategory['name']:"-",
            //"forSpecificBrand":radio==="Brand"?true:false,
            //"brandName":radio==="Brand"?selectedBrand['name']:"-",
            //"forSpecificSingle":radio==="Single"?true:false,
            //"singleName":radio==="Single"?selectedSingle['name']:"-",
        }
        const config = {
            method: 'post',
            url: process.env.REACT_APP_APIBASE+'home/deals',
            //url: process.env.REACT_APP_APIBASE+'home/deals',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: body
        }
        dealService.addDeals(config).then(data => {if (data.status === "success") {
            toast.current.show({severity:'success', summary: 'Success', detail:'Deal added successfully', life: 3000});
            history.push('/managedeals');
        }else{
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while adding a new deal', life: 3000});
        }});
  }

  /*const productMulti= [];
        product && product.map((data) => {
        let field={ label: data.name, value: data.name };
        productMulti.push(field);
    })*/
    return (
        <>
            {loader && CustomLoader()}
        <Toast ref={toast} />
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Add Deal</h5>
                    <div className="p-fluid formgrid grid">
                        {/*<div className="p-field-radiobutton">
                            <RadioButton inputId="radio1" name="radio" value="Brand" onChange={(e) => setRadio(e.value)} checked={radio === 'Brand'} />
                            <label htmlFor="radio1">  Brand</label>
                        </div>
                        <div className="p-field-radiobutton" style={{marginLeft:"20px"}}>
                            <RadioButton inputId="radio2" name="radio" value="Category" onChange={(e) => setRadio(e.value)} checked={radio === 'Category'} />
                            <label htmlFor="radio2">  Category</label>
                        </div>
                        <div className="p-field-radiobutton" style={{marginLeft:"20px"}}>
                            <RadioButton inputId="radio3" name="radio" value="Single" onChange={(e) => setRadio(e.value)} checked={radio === 'Single'} />
                            <label htmlFor="radio3">  Single</label>
                        </div>
                        {radio==="Brand" && <div className="field col-12"  style={{marginTop:"20px"}} >
                            <label htmlFor="dealName">Brand</label>
                            <Dropdown value={selectedBrand} options={brands} onChange={onBrandChange} optionLabel="name" placeholder="Select a Brand" />
                        </div>}
                        {radio==="Category" && <div className="field col-12" style={{marginTop:"20px"}}>
                            <label htmlFor="dealName">Category</label>
                            <Dropdown value={selectedCategory} options={category} onChange={onCategoryChange} optionLabel="name" placeholder="Select a Category" />
                        </div>}
                        {radio==="Single" && <div className="field col-12" style={{marginTop:"20px"}}>
                         <label htmlFor="dealName">Single</label>
                            <Dropdown
                                value={selectedSingle}
                                options={product}
                                onChange={onSingleChange}
                                style={{ minWidth: '12em' }}
                                optionLabel="name"
                                placeholder="Select Single Product "
				            />
                        </div>}
    */}
                        <div className="field col-12">
                            <label htmlFor="dealCategory">Discount value</label>
                            <InputText id="dealCategory" type="number" onChange={(e)=>setDeal(e.target.value)}/>
                        </div>
                        <div className="field col-12">
                            <label htmlFor="dealCategory">Discount Type</label>
                             <Dropdown value={selectedTypeD} options={["%","off"]} onChange={onTypeDChange} placeholder="Select a Type" />


                        </div>
                        <div className="field col-12">
                        <label htmlFor="dealImage">Deal image</label>
                            <FileUpload name="demo[]" customUpload={true} uploadHandler={onTemplateUpload} onUpload={onTemplateUpload} accept="image/png,image/jpg,image/jpeg" maxFileSize={1000000}
                                emptyTemplate={<p className="p-m-3" style={{fontSize:"15px"}}>Drag and drop files to here to upload.</p>} auto />
                        </div>
                        <div className="field col-12">
                            <Button className="p-button-rounded formButton" onClick={onSubmit}>Add deal</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
