import React from "react";

export const AppFooter = (props) => {
  return (
    <div className="layout-footer">
      <span className="font-medium ml-2">
        <p>
          © {new Date().getFullYear()} E-Commerce | Developed by{" "}
          <a href="https://www.kadritech.se">Kadritech AB</a> - Powered by
          <a href="https://www.dataholic.info"> Dataholic</a>
        </p>
      </span>
    </div>
  );
};
