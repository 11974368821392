import React, {useEffect} from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useState, useRef } from "react";
import axios from "axios";
import { useHistory,useParams,useLocation } from "react-router-dom";
import { Toast } from "primereact/toast";
import LoadingOverlay from "react-loading-overlay";
import { DealService } from '../service/DealService';
import "./customButton.css";
import { AppFooter } from './../AppFooter';
import { AppTopbar } from './../AppTopbar';
import classNames from 'classnames';
export const ProductPage = () => {
    const history = useHistory();
    const params = useParams()
    const useQuery= () => {
        return new URLSearchParams(useLocation().search);
 }
 let query = useQuery();

    const [isActive, setActive] = useState(false);
    const [item, setItem] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        const dealsService = new DealService();
        const itemid= query.get('item_id');

        const config = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'home/item/'+itemid,
            //url: process.env.REACT_APP_APIBASE+'home/item/'+itemid,
            headers: {
                'Content-Type' : 'application/json'
            },
        }
        dealsService.getDeals(config).then(data => setItem(data["result"]));


    }, []);

    return (
        <>
        <LoadingOverlay active={isActive} spinner>
        <AppTopbar />

        <div className="product">
            <Card className="p-shadow-7 formcard">
            {item.images &&
            item.images.map((img,index) => {
                if(index === 1){
                return  <div className="product-image" key={index}>
                    <img src={img} alt={img} />
                </div>
                }
            })}
            <p className="product-name"><b>Product Name:</b> {item.name}</p>
            <p className="product-price">Price: {item.price}</p>
            <p className="product-price">Description: {item.description}</p>
            <p className="product-price">Specifications: {item.specifications}</p>
            <p className="product-price">Brand: {item.brand}</p>
            <p className="product-price">Category: {item.category}</p>
            <p className="product-price">Reviews: {item.rating}</p>

            </Card>{" "}
        </div>
        <AppFooter />
        </LoadingOverlay>{" "}
        </>
    );
};
