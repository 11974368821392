import React,{useState,useEffect,useRef} from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DealService } from '../service/DealService';
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { CategoryService } from "../service/CategoryService";
import { BrandService } from "../service/BrandsService";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from 'primereact/multiselect';
import { ProductService } from '../service/ProductService';

export const ShippingTable = () => {
    let emptyDeals = {
        name: "",
        images: null,
        forSpecificCategory:false,
        categoryName:"-",
        forSpecificBrand:false,
        brandName:"-",
        price_type:""
    };
    const [deals, setDeals] = useState(null);
    const [deleteDealDialog, setDeleteDealDialog] = useState(false);
    const [relistDealDialog, setRelistDealDialog] = useState(false);
    const [dealDetails, setDealDetails] = useState(emptyDeals);
    const toast = useRef(null);
    const [edit,setEditUpdate] = useState(false)
    const [images, setImage] = useState("")
    const [dealUpdateDialog, setDealUpdateDialog] = useState(false);
    const [did,setDid] = useState("")

    const [brands, setBrands] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [category, setCategory] = useState("");
    const [selectedTypeD,setSelectedTypeD]=useState("");
    const [product, setProducts] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSingle, setSelectedSingle] = useState(null);
    const [statusTypeD,setStatusTypeD]=useState(null);

    useEffect(() => {
        const dealsService = new DealService();
        const config = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'home/getshippingtaxs',
            //url: process.env.REACT_APP_APIBASE+'home/getshippingtaxs',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        dealsService.getDeals(config).then(data => setDeals(data["data"]));


        const categoryService = new CategoryService();
        const config1 = {
            method: "get",
            url: process.env.REACT_APP_APIBASE+"admin/getCategories",
            headers: {
                "Content-Type": "application/json",
            },
        };
        categoryService.getCategories(config1).then((data) => setCategory(data));
        const brandsService = new BrandService();
        const config2 = {
            method: "get",
            url: process.env.REACT_APP_APIBASE+"admin/admin/brands",
            headers: {
                "Content-Type": "application/json",
            },
        };
        brandsService.getBrands(config2).then((data) => setBrands(data));
        const productService = new ProductService();

        const config22 = {
            method: "get",
            url: process.env.REACT_APP_APIBASE+"admin/item",
            headers: {
                "Content-Type": "application/json",
            },
        };
        productService.getProduct(config22).then((data) => setProducts(data));
    }, []);
    useEffect(() => {
        const dealsService = new DealService();
        const config = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'home/getshippingtaxs',
            //url: process.env.REACT_APP_APIBASE+'home/getshippingtaxs',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        dealsService.getDeals(config).then(data => setDeals(data["data"]));

    }, [deleteDealDialog,relistDealDialog,edit,dealDetails]);

    const activestatusBodyTemplate = (rowData) => {
        if (rowData.status == "Active") {
            return (
                <>
                    <span className={`product-badge status-instock`}>{rowData.status}</span>
                </>
            );
        } else {
            return (
                <>
                    <span className={`product-badge status-outofstock`}>Not Active</span>
                </>
            );
        }
    };
    const onTypeDChange = (e) => {
        setSelectedTypeD(e.value);
        let _deal = { ...dealDetails };
        _deal["shipping_amount_type"] = e.value;
        setDealDetails(_deal)
    }
    const hideDeleteDealDialog = () => {
        setDeleteDealDialog(false);
    };
    const hideRelistDealDialog = () => {
        setRelistDealDialog(false);
    };
    const deleteDeal = () => {
        const dealService = new DealService();
        const config = {
            method: "delete",
            url: process.env.REACT_APP_APIBASE+"home/shippingtax/"+did,
            //url: process.env.REACT_APP_APIBASE+"home/shippingtax/"+did,
            headers: {
                "Content-Type": "application/json",
            },
            //data: { status: "Not Active",_id:did },
            data: { _id:did },
        };
        dealService.deleteDeal(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Shipping unlisted successfully", life: 3000 });
                setDeleteDealDialog(false);
                setDealDetails(emptyDeals);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while unlisting shipping", life: 3000 });
            }
        });
    };

    const relistDeal = () => {
        const dealService = new DealService();
        const config = {
            method: "put",
            url: process.env.REACT_APP_APIBASE+"home/shippingtax/"+did,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Active",_id:did },
        };
        dealService.relistDeal(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Shipping tax successfully", life: 3000 });
                setRelistDealDialog(false);
                setDealDetails(emptyDeals);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while relisting shpping tax", life: 3000 });
            }
        });
    };


    const updateDeal = () => {
        const dealService = new DealService();
        if (edit === true){
            let errors = {};
            if (!dealDetails.shipping_type) {
                errors.radio = 'Type is required.';
                toast.current.show({severity:'error', summary: 'Error', detail: errors.radio, life: 3000});
                return;
            }

            if (!dealDetails.shipping_value) {
                errors.price = 'price is required.';
                toast.current.show({severity:'error', summary: 'Error', detail: errors.price, life: 3000});
                return;
            }
            if (!dealDetails.shipping_amount_type) {
                errors.price = 'price type is required.';
                toast.current.show({severity:'error', summary: 'Error', detail: errors.price, life: 3000});
                return;
            }

            const config = {
                method: 'put',
                url: process.env.REACT_APP_APIBASE+"home/shippingtax/"+did,
                //url: process.env.REACT_APP_APIBASE+'home/shippingtax/'+did,
                headers: {
                    'Content-Type' : 'application/json'
                },
                data: dealDetails
            }
            dealService.updateDeals(config).then(data => {if (data.success === true) {
                toast.current.show({severity:'success', summary: 'Success', detail:'Shopping tax updated successfully', life: 3000});
                setDealUpdateDialog(false);
                setDealDetails(emptyDeals);
                setEditUpdate(false);
                setDid("");
            }else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Error while updating shipping tax', life: 3000});
            }});
            return
        }
    };

    const dealUpdateDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={()=>{
                setDealUpdateDialog(false);
                setSelectedBrand("");
                setSelectedCategory("");
                setSelectedSingle("");
            }} />
            <Button label="Save" icon="pi pi-check" className="p-button-text"  onClick={updateDeal}/>
        </>
    );

    const deleteDealDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDealDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteDeal} />
        </>
    );
    const relistDealDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRelistDealDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={relistDeal} />
        </>
    );
    const editDeal = (deal) => {
        console.log(deal)
        setDealDetails({ ...deal });
        setEditUpdate(true)
        setDid(deal._id)

        if(deal.shipping_type == "Brand"){
            setSelectedBrand(deal.shipping_product[0].split(','))
        }
        if(deal.shipping_type == "Single"){
            setSelectedSingle(deal.shipping_product[0].split(','))
        }
        if(deal.shipping_type == "Category"){
            setSelectedCategory(deal.shipping_product[0].split(','))
        }
        setImage(deal.images)
        setDealUpdateDialog(true);
        setSelectedTypeD({"name":deal.shipping_amount_type});
        setStatusTypeD({"name":deal.status});
    };
    const onBrandChange = (e) => {
        let _deal = { ...dealDetails };
        _deal["shipping_product"] = e.value.toString();
        setDealDetails(_deal);
        setSelectedBrand(e.value);
    };
    const onSingleChange = (e) => {
        let _deal = { ...dealDetails };
        _deal["shipping_product"] = e.value.toString();
        setDealDetails(_deal);
        setSelectedSingle(e.value);
    }
    const onInputChange = (e, name) => {
        console.log(e.target.value, name);
        const val = (e.target && e.target.value) || "";
        let _deal = { ...dealDetails };

        console.log(_deal);

        if (name==="forSpecificBrand"){
            _deal[`forSpecificBrand`] = true
            _deal[`forSpecificCategory`] = false;
            _deal[`shipping_type`]="Brand";
        }else if (name==="forSpecificCategory"){
            _deal[`forSpecificBrand`] = false;
            _deal[`forSpecificCategory`] = true;
            _deal[`shipping_type`]="Category";
        }else if (name==="forSpecificSingle"){
            _deal[`shipping_type`]="Single";
        }else{
            _deal[`${name}`] = val;
        }

            setDealDetails(_deal)
    };
    const onCategoryChange = (e) => {
        let _deal = { ...dealDetails };
        _deal["shipping_product"] = e.value.toString();
        setDealDetails(_deal);
        setSelectedCategory(e.value);
    };
    const onStatusChange = (e) => {
        setStatusTypeD(e.value);
        let _deal = { ...dealDetails };
        _deal["status"] = e.value;
        setDealDetails(_deal)
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editDeal(rowData)} />
                {rowData.shipping_type !="All" && rowData.status =="Active" && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => {setDealDetails(rowData);setDid(rowData["_id"]);setDeleteDealDialog(true);}} />}
                {rowData.shipping_type !="All" && rowData.status =="Not Active" && <Button icon="pi pi-list" className="p-button-rounded p-button-info" onClick={() => {setDealDetails(rowData);setDid(rowData["_id"]);setRelistDealDialog(true);}} />}

            </div>
        );
    };
    const [selectedStatF, setSelectedStatF] = useState(null);
    const dt = useRef(null);
    const statItemTemplate = (option) => {
        return <span style={{fontSize:"14px",fontWeight:600,textTransform:'capitalize'}}>{option}</span>;
    }
    const onStatChange = (e) => {
        dt.current.filter(e.value, 'status', 'equals');
        setSelectedStatF(e.value);
    }
    const statFilter = <Dropdown value={selectedStatF} options={["Active","Not Active"]} onChange={onStatChange} itemTemplate={statItemTemplate} placeholder="Select a Status" option showClear style={{width:"110px",fontSize:'10px'}}/>;
    const brandMulti= [];
    brands && brands.map((data) => {
        let field={ label: data.name, value: data.name };
        brandMulti.push(field);
    })
    const categoryMulti= [];
    category && category.map((data) => {
        let field={ label: data.name, value: data.name };
        categoryMulti.push(field);
    })

    const productMulti= [];
    product && product.map((data) => {
        let field={ label: data.name, value: data.name };
        productMulti.push(field);
    })

    return (
        <>
        <Toast ref={toast} />
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Shipping Tax</h5>
                    <DataTable ref={dt} value={deals} className="p-datatable-customers" rows={5} paginator>
                        <Column field="shipping_amount_type" header="Type" sortable style={{fontSize:"12px"}}/>
                        <Column field="shipping_type" header="Shipping type" sortable style={{fontSize:"12px"}}/>
                        <Column field="shipping_product" header="Product Name" sortable style={{fontSize:"12px",wordWrap:"break-word"}}/>
                        <Column field="shipping_value" header="Shipping Fee" sortable style={{fontSize:"12px"}}/>
                        <Column field="status" header="Status" body={activestatusBodyTemplate} filter filterElement={statFilter}/>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>

        <Dialog visible={dealUpdateDialog} style={{ width: "450px" }} header="Shipping Update Details" modal className="p-fluid" footer={dealUpdateDialogFooter} onHide={()=>{
            setDealUpdateDialog(false);
            setSelectedBrand("");
            setSelectedCategory("");
            setSelectedSingle("");
        }}>
                            {dealDetails.images && <img src={dealDetails.images} alt={dealDetails.images} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

                            <div className="field">
                                <label htmlFor="name">Type</label>
                                <div className="field-radiobutton col-6">
                                    <RadioButton inputId="radio2" name="radio" value={dealDetails.brandName} onChange={(e) => {onInputChange(e, "forSpecificBrand")}} checked={dealDetails.shipping_type == "Brand"? true: false} style={{marginLeft:"0px"}}/>
                                    <label htmlFor="radio2">Brand</label>
                                    <RadioButton inputId="radio1" name="radio" value={dealDetails.categoryName} onChange={(e) => {onInputChange(e, "forSpecificCategory")}} checked={dealDetails.shipping_type == "Category"? true: false} style={{marginLeft:"15px"}} />
                                    <label htmlFor="radio1">Category</label>
                                     <RadioButton inputId="radio3" name="radio" value={dealDetails.single} onChange={(e) => {onInputChange(e, "forSpecificSingle")}} checked={dealDetails.shipping_type == "Single"? true: false} style={{marginLeft:"15px"}}/>
                                    <label htmlFor="radio3">Single</label>

                                </div>
                            </div>
                            {dealDetails.shipping_type == "Category" && <div className="field">
                                <MultiSelect
                                    value={selectedCategory}
                                    options={categoryMulti}
                                    onChange={onCategoryChange}
                                    style={{ minWidth: '12em' }}
                                    filter={true}
                                    placeholder="Select a Category"
                                    id="category"
                                />
                            </div>}
                            {dealDetails.shipping_type == "Brand" && <div className="field">
                                <label htmlFor="description">Brand</label>
                                <MultiSelect
                                value={selectedBrand}
                                options={brandMulti}
                                onChange={onBrandChange}
                                style={{ minWidth: '12em' }}
                                filter={true}
                                placeholder="Select a Brand"
                                id="brand"
				            />
                            </div>}
                            {dealDetails.shipping_type == "Single" && <div className="field">
                                <MultiSelect
                                    value={selectedSingle}
                                    options={productMulti}
                                    onChange={onSingleChange}
                                    style={{ minWidth: '12em' }}
                                    filter={true}
                                    placeholder="Select Single Product "
                                />
                            </div>}
                            <div className="field">
                                <label htmlFor="name">Shipping Tax Value</label>
                                <InputText type="number" id="name" value={dealDetails.shipping_value} onChange={(e) => onInputChange(e, "shipping_value")} required autoFocus />
                            </div>
                            <div className="field col-12">
                                <label htmlFor="dealCategory">Price Type</label>
                                <Dropdown value={selectedTypeD} options={["fixed"]} onChange={onTypeDChange}  placeholder={selectedTypeD?selectedTypeD["name"]:   "Select a Type"} />
                            </div>
                            <div className="field col-12">
                                <label htmlFor="dealCategory">Status</label>
                                <Dropdown value={statusTypeD} options={["Active","Not Active"]} onChange={onStatusChange}  placeholder={statusTypeD?statusTypeD["name"]:   "Select Status"} />
                            </div>


                        </Dialog>
            <Dialog visible={deleteDealDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteDealDialogFooter} onHide={hideDeleteDealDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {dealDetails && (
                        <span>
                            Are you sure you want to delete <b>{dealDetails.shipping_product}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={relistDealDialog} style={{ width: "450px" }} header="Confirm" modal footer={relistDealDialogFooter} onHide={hideRelistDealDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {dealDetails && (
                        <span>
                            Are you sure you want to active shipping <b>{dealDetails.shipping_product}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>
        </>
    );
};
