import React, { useState, useEffect, useRef } from 'react';
import {useHistory} from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import "./customButton.css";
import { PreSignedUrlService } from '../service/PreSignedUrlService';
import { BannerService } from '../service/BannerService';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import {CustomLoader} from "../loader";
export const BannerFormLayout = () => {
    const [name,setName] = useState("")
    const toast = useRef(null);
    const [image, setImage] = useState("")
    const [description, setDescription] = useState("");
    const [text, setText] = useState("");
    const [loader, setLoader] = useState(false);
    const [selectedBannerType, setSelectedBannerType] = useState(null);
    const history = useHistory();
    const bannertype = [
        {key:"1",name:"Position-1"},
        {key:"2",name:"Position-2"},
    ]
    const onTemplateUpload = (e) => {
        onUpload(e.files)
    }
    const onBannerTypeChange = (e) => {
        setSelectedBannerType(e.value);
    }
    const onUpload = (e) => {
        setLoader(true);
        var data = new FormData();
        data.append('image', e[0]);

        var config = {
            method: 'post',
            url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
            headers: { 
                'content-type': 'multipart/form-data'
            },
            data : data
        };

        axios(config)
        .then(function (response) {
            console.log(response.data);
            let {url} = response.data;

            setImage(url);
            setLoader(false);
            toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
        })
        .catch(function (error) {
            console.log(error);
            setLoader(false);
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        });


        // const data = {
        //     "fileName":e[0].name,
        //     "fileType":e[0].type.split("/")[1],
        // }
        // const objectUrl = e[0]
        // const type = e[0].type
        // const config = {
        //     method: 'post',
        //     url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
        //     headers: {
        //         'Content-Type' : 'application/json'
        //     },
        //     data: data
        // }
        // const preSignedUrlService = new PreSignedUrlService();
        // preSignedUrlService.generatePreSignedUrl(config).then(data => {if (data.url !== "") {
        //     setImage(data.url);
        //     preSignedUrlService.uploadOnGCP(data.url,objectUrl,type ).then(resp =>{
        //         toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
        //     }).catch((error)=>{
        //         toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        //     })
        // }else{
        //     toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        // }});
    }
    const onSubmit = () =>{
        const bannerService = new BannerService();
        let errors = {};
        if (!name) {
            errors.name = 'Name is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.name, life: 3000});
            return;
        }
        if (!image) {
            errors.image = 'image upload is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.image, life: 3000});
            return;
        }

        const banner = {
            "name": name,
            "images": image,
            "description":"",
            "text":"",
            "bannertype":selectedBannerType?selectedBannerType.name:"",
            "status":"Active"
        }
        const config = {
            method: 'post',
            url: process.env.REACT_APP_APIBASE+'admin/bnr',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: banner
        }
        bannerService.addBanner(config).then(data => {if (data.status === "Success") {
            toast.current.show({severity:'success', summary: 'Success', detail:'Banner added successfully', life: 3000});
            history.push('/managebanner');
        }else{
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while adding a new banner', life: 3000});
        }});
  }
    return (
        <>
        {loader && CustomLoader()}
                <Toast ref={toast} />
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Add Banner</h5>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12">
                            <label htmlFor="bannerName">Banner name</label>
                            <InputText id="bannerName" type="text" onChange={(e)=>setName(e.target.value)}/>
                        </div>
                        {/*<div className="field col-12">
                            <label htmlFor="bannerDesc">Banner description</label>
                            <InputText id="bannerDesc" type="text" onChange={(e)=>setDescription(e.target.value)}/>
                        </div>
                        <div className="field col-12">
                            <label htmlFor="bannerText">Banner text</label>
                            <InputText id="bannerText" type="text" onChange={(e)=>setText(e.target.value)}/>
                        </div>*/}
                        <div className="field col-12">
                            <label htmlFor="bannerText">Banner type</label>
                            <Dropdown value={selectedBannerType} options={bannertype} onChange={onBannerTypeChange} optionLabel="name" placeholder="Select a Banner Type" />
                        </div>
                        <div className="field col-12">
                        <label htmlFor="bannerImage">Banner image</label>
                        <FileUpload name="demo[]" customUpload={true} uploadHandler={onTemplateUpload} onUpload={onTemplateUpload} accept="image/png,image/jpg,image/jpeg" maxFileSize={1000000}
                                emptyTemplate={<p className="p-m-3" style={{fontSize:"15px"}}>Drag and drop files to here to upload asdd.</p>} auto />

                        </div>
                        <div className="field col-12">
                        <Button className="p-button-rounded formButton" onClick={onSubmit}>Add banner</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
