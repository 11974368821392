import React,{useState,useEffect,useRef} from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DealService } from '../service/DealService';
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { CategoryService } from "../service/CategoryService";
import { BrandService } from "../service/BrandsService";
import { Dropdown } from "primereact/dropdown";
export const TaxTable = () => {
    let emptyDeals = {
        name: "",
        images: null,
        forSpecificCategory:false,
        categoryName:"-",
        forSpecificBrand:false,
        brandName:"-",
        price_type:""
    };
    const [deals, setDeals] = useState(null);
    const [deleteDealDialog, setDeleteDealDialog] = useState(false);
    const [relistDealDialog, setRelistDealDialog] = useState(false);
    const [dealDetails, setDealDetails] = useState(emptyDeals);
    const toast = useRef(null);
    const [edit,setEditUpdate] = useState(false)
    const [dealUpdateDialog, setDealUpdateDialog] = useState(false);
    const [did,setDid] = useState("")
    const [selectedTypeD,setSelectedTypeD]=useState(null);
    const [statusTypeD,setStatusTypeD]=useState(null);

    useEffect(() => {
        const dealsService = new DealService();
        const config = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'home/tax',
            //url: process.env.REACT_APP_APIBASE+'home/tax',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        dealsService.getDeals(config).then(data => setDeals(data["data"]));

    }, []);
    useEffect(() => {
        const dealsService = new DealService();
        const config = {
            method: 'get',
            url: process.env.REACT_APP_APIBASE+'home/tax',
            //url: process.env.REACT_APP_APIBASE+'home/tax',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        dealsService.getDeals(config).then(data => setDeals(data["data"]));

    }, [deleteDealDialog,relistDealDialog,edit,dealDetails]);

    const activestatusBodyTemplate = (rowData) => {
        if (rowData.status == "Active") {
            return (
                <>
                    <span className={`product-badge status-instock`}>{rowData.status}</span>
                </>
            );
        } else {
            return (
                <>
                    <span className={`product-badge status-outofstock`}>Not Active</span>
                </>
            );
        }
    };
    const onTypeDChange = (e) => {
        setSelectedTypeD(e.value);
        let _deal = { ...dealDetails };
        _deal["tax_type"] = e.value;
        setDealDetails(_deal)
    }
    const onStatusChange = (e) => {
        setStatusTypeD(e.value);
        let _deal = { ...dealDetails };
        _deal["status"] = e.value;
        setDealDetails(_deal)
    }
    const hideDeleteDealDialog = () => {
        setDeleteDealDialog(false);
    };
    const hideRelistDealDialog = () => {
        setRelistDealDialog(false);
    };
    const deleteDeal = () => {
        const dealService = new DealService();
        const config = {
            method: "delete",
            url: process.env.REACT_APP_APIBASE+"home/tax/"+did,
            //url: process.env.REACT_APP_APIBASE+"home/tax/"+did,
            headers: {
                "Content-Type": "application/json",
            },
            data: { _id:did },
        };
        dealService.deleteDeal(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Tax deleted successfully", life: 3000 });
                setDeleteDealDialog(false);
                setDealDetails(emptyDeals);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while delete tax", life: 3000 });
            }
        });
    };

    const relistDeal = () => {
        const dealService = new DealService();
        const config = {
            method: "put",
            url: process.env.REACT_APP_APIBASE+"home/shippingtax/"+did,
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Active",_id:did },
        };
        dealService.relistDeal(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "Shipping tax successfully", life: 3000 });
                setRelistDealDialog(false);
                setDealDetails(emptyDeals);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while relisting shpping tax", life: 3000 });
            }
        });
    };


    const updateDeal = () => {
        const dealService = new DealService();
        if (edit === true){
            let errors = {};
            if (!dealDetails.tax_name) {
                errors.name = 'Tax name is required.';
                toast.current.show({severity:'error', summary: 'Error', detail: errors.name, life: 3000});
                return;
            }

            if (!dealDetails.tax_value) {
                errors.price = 'Tax value is required.';
                toast.current.show({severity:'error', summary: 'Error', detail: errors.price, life: 3000});
                return;
            }
            if (!dealDetails.tax_type) {
                errors.type = 'Tax type is required.';
                toast.current.show({severity:'error', summary: 'Error', detail: errors.type, life: 3000});
                return;
            }
            let checkedDeal;
            checkedDeal =deals.filter(o => o.status === "Active");
            console.log(checkedDeal.length);
            if(checkedDeal.length < 1 || dealDetails.status == "Not Active"){
                const config = {
                    method: 'put',
                    url: process.env.REACT_APP_APIBASE+"home/tax/"+did,
                    //url: process.env.REACT_APP_APIBASE+"home/tax/"+did,
                    headers: {
                        'Content-Type' : 'application/json'
                    },
                    data: dealDetails
                }
                dealService.updateDeals(config).then(data => {if (data.success === true) {
                    toast.current.show({severity:'success', summary: 'Success', detail:'Tax updated successfully', life: 3000});
                    setDealUpdateDialog(false);
                    setDealDetails(emptyDeals);
                    setEditUpdate(false);
                    setDid("");
                }else{
                    toast.current.show({severity:'error', summary: 'Error', detail:'Error while updating taxes', life: 3000});
                }});
        }else{
            toast.current.show({severity:'error', summary: 'Error', detail:'Only one tax can be active at a time', life: 3000});
            setDealUpdateDialog(false);
        }
            return
        }
    };

    const dealUpdateDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={()=>{setDealUpdateDialog(false)}} />
            <Button label="Save" icon="pi pi-check" className="p-button-text"  onClick={updateDeal}/>
        </>
    );

    const deleteDealDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDealDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteDeal} />
        </>
    );
    const relistDealDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRelistDealDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={relistDeal} />
        </>
    );
    const editDeal = (deal) => {
        setDealDetails({ ...deal });
        setEditUpdate(true)
        setDid(deal._id)
        setDealUpdateDialog(true);
        setSelectedTypeD({"name":deal.tax_type});
        setStatusTypeD({"name":deal.status});
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _deal = { ...dealDetails };
         _deal[`${name}`] = val;
        setDealDetails(_deal)
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editDeal(rowData)} />
                {rowData.status =="Not Active" && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => {setDealDetails(rowData);setDid(rowData["_id"]);setDeleteDealDialog(true);}} />}
                {/* {rowData.status =="Not Active" && <Button icon="pi pi-list" className="p-button-rounded p-button-info" onClick={() => {setDealDetails(rowData);setDid(rowData["_id"]);setRelistDealDialog(true);}} />} */}

            </div>
        );
    };
    const [selectedStatF, setSelectedStatF] = useState(null);
    const dt = useRef(null);
    const statItemTemplate = (option) => {
        return <span style={{fontSize:"14px",fontWeight:600,textTransform:'capitalize'}}>{option}</span>;
    }
    const onStatChange = (e) => {
        dt.current.filter(e.value, 'status', 'equals');
        setSelectedStatF(e.value);
    }
    const statFilter = <Dropdown value={selectedStatF} options={["Active","Not Active"]} onChange={onStatChange} itemTemplate={statItemTemplate} placeholder="Select a Status" option showClear style={{width:"110px",fontSize:'10px'}}/>;

    return (
        <>
        <Toast ref={toast} />
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Taxes List</h5>
                    <DataTable ref={dt} value={deals} className="p-datatable-customers" rows={5} paginator>
                        <Column field="tax_name" header="Tax name" sortable style={{fontSize:"12px"}}/>
                        <Column field="tax_type" header="Tax type" sortable style={{fontSize:"12px"}}/>
                        <Column field="tax_value" header="Tax" sortable style={{fontSize:"12px"}}/>
                        <Column field="status" header="Status" body={activestatusBodyTemplate} filter filterElement={statFilter}/>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>

        <Dialog visible={dealUpdateDialog} style={{ width: "450px" }} header="Tax Update Details" modal className="p-fluid" footer={dealUpdateDialogFooter} onHide={()=>{setDealUpdateDialog(false)}}>
                            {dealDetails.images && <img src={dealDetails.images} alt={dealDetails.images} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                            <div className="field">
                                <label htmlFor="name">Tax Name</label>
                                <InputText id="name" value={dealDetails.tax_name} onChange={(e) => onInputChange(e, "tax_name")} required autoFocus />
                            </div>
                            <div className="field">
                                <label htmlFor="name">Tax Value</label>
                                <InputText type="number" id="tax_value" value={dealDetails.tax_value} onChange={(e) => onInputChange(e, "tax_value")} required autoFocus />
                            </div>
                            <div className="field col-12">
                            <label htmlFor="dealCategory">Tax Type</label>
                                <Dropdown value={selectedTypeD} options={["%","fixed"]} onChange={onTypeDChange}  placeholder={selectedTypeD?selectedTypeD["name"]:   "Select a Type"} />
                            </div>
                            <div className="field col-12">
                            <label htmlFor="dealCategory">Status</label>
                                <Dropdown value={statusTypeD} options={["Active","Not Active"]} onChange={onStatusChange}  placeholder={statusTypeD?statusTypeD["name"]:   "Select Status"} />
                            </div>


                        </Dialog>
            <Dialog visible={deleteDealDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteDealDialogFooter} onHide={hideDeleteDealDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {dealDetails && (
                        <span>
                            Are you sure you want to delete <b>{dealDetails.tax_name}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={relistDealDialog} style={{ width: "450px" }} header="Confirm" modal footer={relistDealDialogFooter} onHide={hideRelistDealDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {dealDetails && (
                        <span>
                            Are you sure you want to relist tax <b>{dealDetails.tax_name}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>
        </>
    );
};
