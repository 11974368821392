import React, { useState, useEffect, useRef, useMemo } from "react";
import classNames from "classnames";
import { Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { CSSTransition } from "react-transition-group";
import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";

import { Dashboard } from "./components/Dashboard";
import { AddBrandsFormLayout } from "./components/BrandFormLayout";
import { CategoryFormLayout } from "./components/CategoryFormLayout";
import { BannerFormLayout } from "./components/BannerFormLayout";
import { DealsFormLayout } from "./components/DealsFormLayout";
import { ItemFormLayout } from "./components/ItemFormLayout";
import { LoginPageComponent } from "./components/LoginPageComponent";
import { BrandsTable } from "./components/BrandsTable";
import { CategoryTable } from "./components/CategoryTable";
import { BannerTable } from "./components/BannerTable";
import { UserTable } from "./components/UsersTable";
import { DealsTable } from "./components/DealsTable";
import { TransactionsTable } from "./components/TransactionTable";
import { OrdersTable } from "./components/OrdersTable";
import { Crud } from "./pages/Crud";
import { ChatLayout } from "./components/ChatLayout";
import PrimeReact from "primereact/api";
import { DeliveryCityFormLayout } from "./components/DeliveryCityFormLayout";
import { DeliveryCityTable } from "./components/DeliveryCityTable";
import { ShippingFormLayout } from "./components/ShippingFormLayout";
import { ShippingTable } from "./components/ShippingTable";
import { GeneralTable } from "./components/GeneralTable";
import { GeneralFormLayout } from "./components/GeneralFormLayout";
import { EmailVerify } from "./components/EmailVerify";
import { TaxFormLayout } from "./components/TaxFormLayout";
import { TaxTable } from "./components/TaxTable";
import { ProductPage } from "./components/ProductPage";
import { AdminUserLayout } from "./components/AdminUserFormLayout";
import { AdminUserTable } from "./components/AdminUserTable";
import { TMCTable } from "./components/TMCTable";
import { UserService } from "./service/UsersService";
import { CancelTable } from "./components/CancelTable";
// import WebSocket from 'ws';
import socket from "./socket";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";
import { setActiveOrNewChat, setOrders } from "./store/slices";
import { OrderService } from "./service/OrderService";

const App = () => {
  let location = useLocation();
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const [users, setUsers] = useState(null);

  const chatList = useSelector((state) => state.chats);
  const orderList = useSelector((state) => state.orders);
  const dispatch = useDispatch();

  const getChats = () => {
    const userService = new UserService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "home/chats?status=New",
      headers: {
        "Content-Type": "application/json",
      },
    };

    userService.getUsers(config).then((data) => {
      console.log("getNewchat", data);
      dispatch(setActiveOrNewChat(data.data));
      // setChatList(data.data);
    });
    const config1 = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "home/chats?status=Started",
      headers: {
        "Content-Type": "application/json",
      },
    };
    userService.getUsers(config1).then((data) => {
      // dispatch(setActiveOrNewChat(data.data));
      // setAChatList(data.data);
    });
    const config2 = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "home/chats?status=Completed",
      headers: {
        "Content-Type": "application/json",
      },
    };
    // userService.getUsers(config2).then((data) => setArChatList(data.data));
  };

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
    const userService = new UserService();
    let token = "Bearer " + sessionStorage.getItem("token");
    if (token) {
      const config = {
        method: "get",
        url: process.env.REACT_APP_APIBASE + "home/user/profilebytoken",
        // url: process.env.REACT_APP_APIBASE+'home/user/profilebytoken',?
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      userService
        .getUsers(config)
        .then((data) => {setUsers(data["userDetails"]); console.log('data', data);});

      console.log("users", users);
    }

    getChats();
    getAllNewOrders();
    //get all chats
    socket.on("connect", (socket) => {
      console.log("socketio connected");
      getChats();
      getAllNewOrders();
    });
    socket.on("new_chat_arrived", (msg) => {
      getChats();
    });

    socket.on("new_order_done", (msg) => {
      console.log("inside new_order_done");
      getAllNewOrders();
    });

    ///orders listing for new order

    // const ws =  new WebSocket('wss://api.drop-deliveryapp.com/notification/v1/ws?roomid=neworder');
    // ws.onopen = ()=>{
    //     getAllNewOrders();
    //     console.log('#########socket connected new order#######');
    // }
    // ws.onmessage = (message) => {
    //     // setMessages(prev => [...prev, message.data]);
    //     getAllNewOrders();
    //     console.log("dsadsadsdssd", "socoekct message");
    // };

    //clean up function
    // return () => ws.close();
  }, [mobileMenuActive]);

  const getAllNewOrders = () => {
    const orderService = new OrderService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/orders",
      headers: {
        "Content-Type": "application/json",
      },
    };
    orderService.getOrders(config).then((res) => {
      // setOrderDetails(data.data);

      let orders =
        res.data && res.data.length > 0
          ? res.data.filter((item) =>
              item.orderStatus == "Ordered" ? item : null
            )
          : [];

      dispatch(setOrders(orders));

      console.log("orders", orders);
    });
  };

  console.log("chatList  ##### length", chatList.length);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [
    {
      label: "Home",
      items: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          to: "/dashboard",
        },
      ],
    },
    {
      label: "Brands",
      items: [
        {
          label: "Add Brand",
          icon: "pi pi-fw pi-plus-circle",
          to: "/addbrands",
        },
        {
          label: "Manage Brands",
          icon: "pi pi-fw pi-globe",
          to: "/managebrands",
        },
      ],
    },
    {
      label: "Categories",
      items: [
        {
          label: "Add Category",
          icon: "pi pi-fw pi-plus-circle",
          to: "/addcategory",
        },
        {
          label: "Manage Categories",
          icon: "pi pi-fw pi-microsoft",
          to: "/managecategory",
        },
      ],
    },
    {
      label: "Banners",
      items: [
        {
          label: "Add Banner",
          icon: "pi pi-fw pi-plus-circle",
          to: "/addbanner",
        },
        {
          label: "Manage Banners",
          icon: "pi pi-fw pi-ticket",
          to: "/managebanner",
        },
      ],
    },
    {
      label: "Deals",
      items: [
        {
          label: "Add Deal",
          icon: "pi pi-fw pi-plus-circle",
          to: "/adddeal",
        },
        {
          label: "Manage Deals",
          icon: "pi pi-fw pi-id-card",
          to: "/managedeals",
        },
      ],
    },
    {
      label: "Products",
      items: [
        {
          label: "Manage Product",
          icon: "pi pi-fw pi-filter",
          to: "/products",
        },
      ],
    },
    {
      label: "Users",
      items: [
        {
          label: "Manage App Users",
          icon: "pi pi-fw pi-users",
          to: "/manageusers",
        },
        {
          label: "Manage Admin Users",
          icon: "pi pi-fw pi-users",
          to: "/manage_adminusers",
        },
        {
          label: "Add Admin Users",
          icon: "pi pi-fw pi-users",
          to: "/adminusers",
        },
      ],
    },
    {
      label: "Chat",
      items: [
        {
          label: "Manage Chats",
          icon: "pi pi-fw pi-comments",
          to: "/chat",
          badge: chatList.length > 0 ? chatList.length : undefined,
        },
      ],
    },
    {
      label: "Transactions",
      items: [
        {
          label: "Manage Transactions",
          icon: "pi pi-fw pi-credit-card",
          to: "/transactions",
        },
      ],
    },
    {
      label: "Orders",
      items: [
        {
          label: "Manage Orders",
          icon: "pi pi-fw pi-shopping-cart",
          to: "/orders",
          badge: orderList.length > 0 ? orderList.length : undefined,
        },
        {
          label: "Cancel Orders",
          icon: "pi pi-fw pi-shopping-cart",
          to: "/cancelorders",
        },
      ],
    },
    {
      label: "Delivery City",
      items: [
        {
          label: "Add Delivery City",
          icon: "pi pi-fw pi-plus-circle",
          to: "/add_delivery_city",
        },
        {
          label: "Manage Delivery City",
          icon: "pi pi-fw pi-id-card",
          to: "/delivery_city",
        },
      ],
    },
    {
      label: "Shipping",
      items: [
        {
          label: "Add Shipping",
          icon: "pi pi-fw pi-plus-circle",
          to: "/addshipping",
        },
        {
          label: "Manage Shipping",
          icon: "pi pi-fw pi-id-card",
          to: "/shipping",
        },
      ],
    },
    {
      label: "Tax",
      items: [
        {
          label: "Add tax",
          icon: "pi pi-fw pi-plus-circle",
          to: "/addtax",
        },
        {
          label: "Manage tax",
          icon: "pi pi-fw pi-id-card",
          to: "/tax",
        },
      ],
    },
    {
      label: "General Settings",
      items: [
        {
          label: "Add General Setting",
          icon: "pi pi-fw pi-plus-circle",
          to: "/addgeneralsettings",
        },
        {
          label: "Manage T&C",
          icon: "pi pi-fw pi-plus-circle",
          to: "/term_condition",
        },
      ],
    },
  ];

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  return (
    <>
      {sessionStorage.getItem("token") ? (
        <div className={wrapperClass} onClick={onWrapperClick}>
          <AppTopbar
            profile={users}
            onToggleMenuClick={onToggleMenuClick}
            layoutColorMode={layoutColorMode}
            mobileTopbarMenuActive={mobileTopbarMenuActive}
            onMobileTopbarMenuClick={onMobileTopbarMenuClick}
            onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
          />

          <div className="layout-sidebar" onClick={onSidebarClick}>
            <AppMenu
              model={menu}
              onMenuItemClick={onMenuItemClick}
              layoutColorMode={layoutColorMode}
            />
          </div>

          <div className="layout-main-container">
            <div className="layout-main">
              <Switch>
                <Route path="/dashboard" exact component={Dashboard} />
                <Route path="/addbrands" component={AddBrandsFormLayout} />
                <Route path="/managebrands" component={BrandsTable} />
                <Route path="/addcategory" component={CategoryFormLayout} />
                <Route path="/managecategory" component={CategoryTable} />
                <Route path="/addbanner" component={BannerFormLayout} />
                <Route path="/managebanner" component={BannerTable} />
                <Route path="/adddeal" component={DealsFormLayout} />
                <Route path="/managedeals" component={DealsTable} />
                <Route path="/additem" component={ItemFormLayout} />
                <Route path="/products" component={Crud} />
                <Route path="/manageusers" component={UserTable} />
                <Route path="/manage_adminusers" component={AdminUserTable} />
                <Route path="/adminusers" component={AdminUserLayout} />
                <Route path="/chat" component={ChatLayout} />
                <Route path="/transactions" component={TransactionsTable} />
                <Route path="/orders" component={OrdersTable} />
                <Route path="/cancelorders" component={CancelTable} />
                <Route
                  path="/add_delivery_city"
                  component={DeliveryCityFormLayout}
                />
                <Route path="/delivery_city" component={DeliveryCityTable} />
                <Route path="/addshipping" component={ShippingFormLayout} />
                <Route path="/shipping" component={ShippingTable} />
                <Route path="/addtax" component={TaxFormLayout} />
                <Route path="/tax" component={TaxTable} />
                <Route
                  path="/addgeneralsettings"
                  component={GeneralFormLayout}
                />
                <Route path="/term_condition" component={TMCTable} />
              </Switch>
            </div>
            <AppFooter layoutColorMode={layoutColorMode} />
          </div>
          <CSSTransition
            classNames="layout-mask"
            timeout={{ enter: 200, exit: 200 }}
            in={mobileMenuActive}
            unmountOnExit
          >
            <div className="layout-mask p-component-overlay"></div>
          </CSSTransition>
        </div>
      ) : (
        <div>
          {(location.pathname && location.pathname == "/account-activation" && (
            <Switch>
              <Route path="/account-activation" exact component={EmailVerify} />
            </Switch>
          )) ||
            (location.pathname && location.pathname == "/product-page" && (
              <Switch>
                <Route path="/product-page" exact component={ProductPage} />
              </Switch>
            )) || <LoginPageComponent />}
        </div>
      )}
    </>
  );
};

export default App;
