import axios from 'axios';

export class UserService {

    getUsers(config) {
        return axios(config).then(res => res.data);
    }

    addUsers(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }

    deleteUser(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
    relistUser(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }

    UpdateChat(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
    getCity(config) {
        return axios(config).then(res => res.data);
    }

}