import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    chats: [],
    orders: []
    // closedIssues: {
    //     total_count: 0,
    //     incomplete_results: false,
    //     items: []
    // },
}

export const slices = createSlice({
    name: "issues",
    initialState,
    reducers: {
        setActiveOrNewChat: (state, action) => {
            state.chats = action.payload;
        },
        setOrders: (state, action) => {
            state.orders = action.payload;
        },
        resetActiveOrNewChat: (state) => {
            state.chats = [];
        }
        // resetState: (state) => {
        //     state.openIssues = {
        //         total_count: 0,
        //         incomplete_results: false,
        //         items: [],
        //     };
        //     state.closedIssues = {
        //         total_count: 0,
        //         incomplete_results: false,
        //         items: [],
        //     };
        // }
    }
})

export const { setActiveOrNewChat, setOrders, resetActiveOrNewChat } = slices.actions;

export default slices.reducer;