import React from "react";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const DashBoardTopBrandsCards = (props) => {

    console.log(props.brands);

    return (
        <div className="card">
            <h5>Top Brands</h5>
            <DataTable value={props.brands} className="p-datatable-customers" rows={5} paginator>
                <Column field="image" header="Image" body={(data) => <img src={data.itemBrand.images} alt={data.itemBrand.images} width="50" />} />
                <Column field="itemBrand" body={(rawData)=>(
                    <>{rawData.itemBrand.name}</>
                )} header="Name" sortable />
                <Column field="category" header="Category" body={(rawData)=>(
                    <>{rawData.category.name}</>
                )} sortable/>
                {/* <Column
                    header="View"
                    body={() => (
                        <>
                            <Button icon="pi pi-search" type="button" className="p-button-text" onClick=/>
                        </>
                    )}
                /> */}
            </DataTable>
        </div>
    );
};
