import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect, useRef } from 'react';
import {useHistory} from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import "./customButton.css"
import { Toast } from 'primereact/toast';
import { DealService } from '../service/DealService';

export const TaxFormLayout = () => {
    const history = useHistory();
    const [selectedTypeD,setSelectedTypeD]=useState(null);
    const [priceVal, setPrice] = useState(null);
    const toast = useRef(null);
    const [product, setProducts] = useState(null);
    const [taxVal, setName] = useState(null);
    
  
    useEffect(() => {
        
    }, []);

    const onTypeDChange = (e) => {
        setSelectedTypeD(e.value);
    }
    
    const onSubmit = () =>{
        const dealService = new DealService();
        const t = selectedTypeD;
        const price = priceVal;
        const name = taxVal;
        if(name == null && price == null && t == null){
            toast.current.show({severity:'error', summary: 'Error', detail:'All required field', life: 3000});
            return;
        }
        let errors = {};
        if (!name) {
            errors.name = 'name is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.name, life: 3000});
            return;
        }
       
        if (!price) {
            errors.price = 'price is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.price, life: 3000});
            return;
        }
        if (!t) {
            errors.price = 'Tax type is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.price, life: 3000});
            return;
        }
        const body = {
            "tax_type":t,
            "tax_value":price,
            "tax_name":name,
            "status":"Not Active"
        }
        const config = {
            method: 'post',
            url: process.env.REACT_APP_APIBASE+'home/tax',
            //url: process.env.REACT_APP_APIBASE+'home/tax',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: body
        }
        dealService.addDeals(config).then(data => {if (data.success === true) {
            toast.current.show({severity:'success', summary: 'Success', detail:'tax added successfully', life: 3000});
            history.push('/tax'); 
        }else{
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while adding a new taxes', life: 3000});
        }});
  }

    return (
        <>
        <Toast ref={toast} />
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Add Taxs</h5>
                    <div className="p-fluid formgrid grid">
                        
                        <div className="field col-12">
                            <label htmlFor="dealCategory">Tax Name</label>
                            <InputText id="taxname" type="text" onChange={(e)=>setName(e.target.value)}/>
                        </div>
                        <div className="field col-12">
                            <label htmlFor="dealCategory">Tax Value</label>
                            <InputText id="taxvalue" type="number" onChange={(e)=>setPrice(e.target.value)}/>
                        </div>
                        <div className="field col-12">
                            <label htmlFor="taxtype">Price Type</label>
                            <Dropdown value={selectedTypeD} options={["%","fixed"]} onChange={onTypeDChange} placeholder="Select a Type" />
                        </div>
                        
                        <div className="field col-12">
                        <Button className="p-button-rounded formButton" onClick={onSubmit}>Add Tax</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
