import React, { useState,useRef } from 'react';
import {useHistory} from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import "./customButton.css"
import { CategoryService } from '../service/CategoryService';
import { PreSignedUrlService } from '../service/PreSignedUrlService';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import {CustomLoader} from "../loader";
export const CategoryFormLayout = () => {
    const toast = useRef(null);
    const [name,setName] = useState("")
    const [image, setImage] = useState("")
    const [icon, setIcon] = useState("")
    const [loader, setLoader] = useState(false);
    const history = useHistory();
    
    const onTemplateUpload = (e) => {
        onUpload(e.files)
    }
    const onUpload = (e) => {
        setLoader(true);
        var data = new FormData();
        data.append('image', e[0]);

        const config = {
            method: 'post',
            url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: data
        }
        axios(config)
        .then(function (response) {
            console.log(response.data);
            let {url} = response.data;

            setImage(url[0]);
            setLoader(false);
            toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
        })
        .catch(function (error) {
            console.log(error);
            setLoader(false);
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        });
        // const data = {
        //     "fileName":e[0].name,
        //     "fileType":e[0].type.split("/")[1],
        // }
        // const objectUrl = e[0]
        // const type = e[0].type
        
        // const config = {
        //     method: 'post',
        //     url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
        //     //url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
        //     headers: {
        //         'Content-Type' : 'application/json'
        //     },
        //     data: data
        // }
        // const preSignedUrlService = new PreSignedUrlService();
        // preSignedUrlService.generatePreSignedUrl(config).then(data => {if (data.url !== "") {
        //     setImage(data.url);
        //     preSignedUrlService.uploadOnGCP(data.url,objectUrl,type ).then(resp =>{
        //         toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
        //     }).catch((error)=>{
        //         toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        //     })
        // }else{
        //     toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
        // }});
    }
    const onCategoryIconUpload = (e) => {
        onUploadIcon(e.files)
    }
    const onUploadIcon = (e) => {
        setLoader(true);
        var data = new FormData();
        data.append('image', e[0]);

        const config = {
            method: 'post',
            url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: data
        }
        axios(config)
        .then(function (response) {
            console.log(response.data);
            let {url} = response.data;

            setIcon(url[0]);
            setLoader(false);
            toast.current.show({severity:'success', summary: 'Success', detail:'Icon uploaded successfully', life: 3000});
        })
        .catch(function (error) {
            console.log(error);
            setLoader(false);
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload icon', life: 3000});
        });

        // const data = {
        //     "fileName":e[0].name,
        //     "fileType":e[0].type.split("/")[1],
        // }
        // const objectUrl = e[0]
        // const type = e[0].type
        
        // const config = {
        //     method: 'post',
        //     url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
        //     //url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
        //     headers: {
        //         'Content-Type' : 'application/json'
        //     },
        //     data: data
        // }
        // const preSignedUrlService = new PreSignedUrlService();
        // preSignedUrlService.generatePreSignedUrl(config).then(data => {if (data.url !== "") {
        //     setIcon(data.url);
        //     preSignedUrlService.uploadOnGCP(data.url,objectUrl,type ).then(resp =>{
        //         toast.current.show({severity:'success', summary: 'Success', detail:'Icon uploaded successfully', life: 3000});
        //     }).catch((error)=>{
        //         toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload icon', life: 3000});
        //     })
        // }else{
        //     toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload icon', life: 3000});
        // }});
    }
    const onSubmit = () =>{
        const categoryService = new CategoryService();

        let errors = {};

        if (!name) {
            errors.name = 'Name is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.name, life: 3000});
            return;
        }
       

        if (!icon) {
            errors.icon = 'icon is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.icon, life: 3000});
            return;
        }

        if (!image) {
            errors.image = 'image is required.';
            toast.current.show({severity:'error', summary: 'Error', detail: errors.image, life: 3000});
            return;
        }

        const category = {
            "name": name,
            "images": image,
            "icon": icon
        }
        const config = {
            method: 'post',
            url: process.env.REACT_APP_APIBASE+'admin/addCategory',
            //url: process.env.REACT_APP_APIBASE+'admin/addCategory',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: category
        }
        categoryService.addCategory(config).then(data => {if (data.status === "Success") {
            toast.current.show({severity:'success', summary: 'Success', detail:'Category added successfully', life: 3000});
            history.push('/managecategory');
        }else{
            toast.current.show({severity:'error', summary: 'Error', detail:'Error while adding a new category', life: 3000});
        }});
  }
    return (
        <>

        {loader && CustomLoader()}
       <Toast ref={toast} />
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Add Category</h5>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12">
                            <label htmlFor="categoryName">Category name</label>
                            <InputText id="categoryName" type="text" onChange={(e)=>setName(e.target.value)}/>
                        </div>
                        <div className="field col-12">
                        <label htmlFor="categoryImage">Category Icon</label>
                        <FileUpload name="icon[]" customUpload={true} uploadHandler={onCategoryIconUpload} onUpload={onCategoryIconUpload} accept="image/png,image/jpg,image/jpeg" maxFileSize={1000000}
                                emptyTemplate={<p className="p-m-3" style={{fontSize:"15px"}}>Drag and drop files to here to upload.</p>} auto />
                        </div>
                        <div className="field col-12">
                        <label htmlFor="categoryImage">Category image</label>
                        <FileUpload name="demo[]" customUpload={true} uploadHandler={onTemplateUpload} onUpload={onTemplateUpload} accept="image/png,image/jpg,image/jpeg" maxFileSize={1000000}
                                emptyTemplate={<p className="p-m-3" style={{fontSize:"15px"}}>Drag and drop files to here to upload.</p>} auto/>
                        </div>
                        <div className="field col-12">
                        <Button className="p-button-rounded formButton1" onClick={onSubmit}>Add category</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
