import React, { useState, useEffect, useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { ProductService } from "../service/ProductService";
import { BrandService } from "../service/BrandsService";
import { DashBoardCards } from "./Card";
import { DashBoardTopBrandsCards } from "./TopBrandsCard";
import { lineData } from "../Data/graphsData";
import { DashBoardTopSellingProducts } from "./TopSellingProductList";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { ProductTable } from "./ProductTable";
export const Dashboard = () => {
  const [products, setProducts] = useState(null);
  const [brands, setBrands] = useState(null);
  const [order, setOrders] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [profit, setProfit] = useState(0);
  const [pendingBalance, setPendingBalance] = useState(0);
  const [tproducts, setTProducts] = useState(0);
  const [shippingTotal, setShippingTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [users, setUsers] = useState(0);
  const [selectedDate, setSelectedDate] = useState([
    moment().startOf("month").toDate(),
    moment().toDate(),
  ]);
  const [selectedDateF, setSelectedDateF] = useState(null);
  const dt = useRef(null);
  const [fs, setFs] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentProductDetails, setCurrentProductDetails] = useState(null);

  const dealType = currentProductDetails?.deal?.split(" ")[1];
  const dealPrice = currentProductDetails?.deal?.split(" ")[0];
  let disCountPrice;
  if (dealType === "%") {
    disCountPrice =
      currentProductDetails?.price -
      (currentProductDetails?.price * parseInt(dealPrice, 10)) / 100;
  } else if (dealType === "off") {
    disCountPrice = currentProductDetails?.price - parseInt(dealPrice, 10);
  } else {
    disCountPrice = currentProductDetails?.price;
  }

  useEffect(() => {
    const productService = new ProductService();
    const brandsService = new BrandService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "home/top_selling_items",
      headers: {
        "Content-Type": "application/json",
      },
    };
    brandsService.getBrands(config).then((res) => {
      setBrands(res.result);
      console.log("top_selling", res.result);
    });
    const config22 = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/item?newest=true",
      headers: {
        "Content-Type": "application/json",
      },
    };
    productService.getProduct(config22).then((data) => {
      setProducts(data.reverse());
    });

    // let month = new Date().getMonth() + 1;
    let date1 = moment().startOf("month").format("YYYY-MM-DD");
    let date2 = moment().endOf("day").format("YYYY-MM-DD");
    const config3 = {
      method: "get",
      url:
        process.env.REACT_APP_APIBASE +
        "admin/dashboard?fromdate=" +
        date1 +
        "&todate=" +
        date2,
      //url: process.env.REACT_APP_APIBASE+"admin/dashboard?fromdate="+date1+"&todate="+date2,
      headers: {
        "Content-Type": "application/json",
      },
    };
    productService.getProduct(config3).then((data) => {
      //console.log("dashboard" +JSON.stringify(data));
      setShippingTotal(data.shippingTotal);
      setTaxTotal(data.taxTotal);
      setOrders(data.orders);
      setRevenue(data.revenue);
      setProfit(data.profit);
      setPendingBalance(data.pendingBalance);
      setTProducts(data.products);
      setUsers(data.users);
    });
  }, []);

  const formatDate = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return date.getFullYear() + "-" + month + "-" + day;
  };

  const onDateChange = (e) => {
    const productService = new ProductService();
    if (e.value[1] !== null) {
      let dat2 = moment(e.value[1]).endOf("day").format("YYYY-MM-DD");
      setSelectedDate(e.value);
      const config3 = {
        method: "get",
        url:
          process.env.REACT_APP_APIBASE +
          "admin/dashboard?fromdate=" +
          fs +
          "&todate=" +
          dat2,
        //url: process.env.REACT_APP_APIBASE+"admin/dashboard?fromdate="+fs+"&todate="+dat2,
        headers: {
          "Content-Type": "application/json",
        },
      };
      productService.getProduct(config3).then((data) => {
        //console.log("dashboard" +JSON.stringify(data));
        setOrders(data.orders);
        setRevenue(data.revenue);
        setProfit(data.profit);
        setPendingBalance(data.pendingBalance);
        setTProducts(data.products);
        setUsers(data.users);
      });

      return;
    }
    if (e.value[0] !== null && e.value[1] === null) {
      let dat2 = moment(e.value[0]).format("YYYY-MM-DD");
      console.log("dat1", dat2);
      setFs(dat2);
      setSelectedDate(e.value);
      return;
    }
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "NGN",
    });
  };
  const reset = () => {
    setSelectedDateF(null);
    setSelectedDate(undefined);
    //dt.current.reset();
  };

  const getAll = () => {
    setLoading2(true);
    const productService = new ProductService();
    setSelectedDateF(null);
    setSelectedDate(undefined);
    let dat1 = "2022-04-01";
    let dat2 = moment().endOf("day").format("YYYY-MM-DD");
    const config3 = {
      method: "get",
      url:
        process.env.REACT_APP_APIBASE +
        "admin/dashboard?fromdate=" +
        dat1 +
        "&todate=" +
        dat2,
      //url: process.env.REACT_APP_APIBASE+"admin/dashboard?fromdate="+dat1+"&todate="+dat2,
      headers: {
        "Content-Type": "application/json",
      },
    };
    productService.getProduct(config3).then((data) => {
      setLoading2(false);
      console.log("dashboardProfit" + JSON.stringify(data));
      setOrders(data.orders);
      setRevenue(data.revenue);
      setProfit(data.profit);
      setPendingBalance(data.pendingBalance);
      setTProducts(data.products);
      setUsers(data.users);
    });
  };

  const brandBodyTemplate = (rowData) => {
    return <>{rowData.brand.name}</>;
  };
  const categoryBodyTemplate = (rowData) => {
    return <>{rowData.category.name}</>;
  };

  return (
    <>
      <div className="table-header col-12 mb-5">
        <Button
          type="button"
          label="Clear"
          className="p-button-outlined"
          icon="pi pi-filter-slash"
          onClick={reset}
        />
        <span className="p-input-icon-left" style={{ float: "right" }}>
          <i className="pi pi-search" />
          <Calendar
            readOnlyInput
            value={selectedDate}
            onChange={onDateChange}
            dateFormat="yy-mm-dd"
            showIcon
            selectionMode="range"
            className="p-column-filter"
            placeholder="Select Date"
            style={{
              marginRight: "5px",
              width: "257px",
              marginBottom: "5px",
              marginTop: "5px",
              marginLeft: "auto",
              fontSize: "12px !important",
            }}
          />
        </span>

        <span
          className="p-input-icon-left"
          style={{ float: "right", marginRight: 20, marginTop: 5 }}
        >
          <Button
            type="button"
            label="Get All"
            className="p-button ml-7"
            icon="pi pi-spinner"
            onClick={getAll}
            loading={loading2}
          />
        </span>
      </div>

      <Dialog
        header="Product Details"
        visible={openModal}
        style={{ width: "50vw" }}
        onHide={() => setOpenModal(false)}
        modal
      >
        <div style={{ textAlign: "center" }}>
          {currentProductDetails &&
            currentProductDetails?.images.map((item, index) => {
              return (
                <img
                  style={{
                    width: 180,
                    margin: 10,
                    objectFit: "contain",
                  }}
                  src={item}
                />
              );
            })}
        </div>
        <ProductTable
          c1="Name"
          c2={currentProductDetails && currentProductDetails?.name}
          c3={currentProductDetails?.name}
        />
        <ProductTable
          c1="Brand"
          c2={currentProductDetails && currentProductDetails?.brand}
          c3={currentProductDetails?.brand.name}
        />
        <ProductTable
          c1="Catergory"
          c2={currentProductDetails && currentProductDetails?.category}
          c3={currentProductDetails?.category.name}
        />
        <ProductTable
          c1="Price"
          c2={currentProductDetails && currentProductDetails?.price}
          c3={
            currentProductDetails?.deal
              ? disCountPrice + " NGN"
              : currentProductDetails?.price + " NGN"
          }
        />
        <ProductTable
          c1="Cost"
          c2={currentProductDetails && currentProductDetails?.shop_price}
          c3={currentProductDetails?.shop_price + " NGN"}
        />
        <ProductTable
          c1="Deals"
          c2={currentProductDetails && currentProductDetails?.deal}
          c3={currentProductDetails?.deal}
        />
        <ProductTable
          c1="Quantity"
          c2={currentProductDetails && currentProductDetails?.quantity}
          c3={currentProductDetails?.quantity}
        />{" "}
        <ProductTable
          c1="Rating"
          c2={currentProductDetails && currentProductDetails?.rating}
          c3={currentProductDetails?.rating}
        />
      </Dialog>

      <div className="grid">
        <div className="col-12 lg:col-6 xl:col-3">
          <DashBoardCards
            name={"Orders"}
            numb={order}
            numb2={""}
            sectext={" "}
            iconcolor={"blue"}
            icon={"pi-shopping-cart"}
          />
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
          <DashBoardCards
            name={"Profit"}
            numb={profit.toFixed(2)}
            numb2={""}
            sectext={" "}
            iconcolor={"orange"}
            icon={"pi-money-bill"}
          />
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
          <DashBoardCards
            name={"Revenue"}
            numb={revenue.toFixed(2)}
            numb2={""}
            sectext={" "}
            iconcolor={"orange"}
            icon={"pi-money-bill"}
          />
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
          <DashBoardCards
            name={"Customers"}
            numb={users}
            numb2={""}
            sectext={" "}
            iconcolor={"cyan"}
            icon={"pi-users"}
          />
        </div>
      </div>

      <div className="grid flex justify-content-center">
        <div className="col-12 lg:col-6 xl:col-3">
          <DashBoardCards
            name={"Pending Balance"}
            numb={pendingBalance.toFixed(2)}
            numb2={""}
            sectext={" "}
            iconcolor={"yellow"}
            icon={"pi-money-bill"}
          />
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
          <DashBoardCards
            name={"Products"}
            numb={tproducts}
            numb2={""}
            sectext={" "}
            iconcolor={"green"}
            icon={"pi-list"}
          />
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
          <DashBoardCards
            name={"Shippings"}
            numb={shippingTotal.toFixed(2)}
            numb2={""}
            sectext={" "}
            iconcolor={"green"}
            icon={"pi-money-bill"}
          />
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
          <DashBoardCards
            name={"taxes"}
            numb={taxTotal.toFixed(2)}
            numb2={""}
            sectext={" "}
            iconcolor={"green"}
            icon={"pi-money-bill"}
          />
        </div>
      </div>

      <div className="grid">
        <div className="col-12 xl:col-6">
          <div className="card">
            <h5>Recent Products</h5>
            <DataTable
              value={products}
              className="p-datatable-customers"
              rows={5}
              paginator
            >
              <Column
                field="name"
                header="Name"
                sortable
                body={(rowData) => {
                  return (
                    <>
                      <span
                        style={{
                          fontSize: "12px",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setCurrentProductDetails(rowData);
                          setOpenModal(true);
                        }}
                      >
                        {rowData.name.substring(0, 30)}
                      </span>
                    </>
                  );
                }}
              />
              <Column
                field="price"
                header="Price"
                sortable
                body={(rowData) => {
                  const dealType = rowData?.deal?.split(" ")[1];
                  const dealPrice = rowData?.deal?.split(" ")[0];
                  let disCountPrice;
                  if (dealType === "%") {
                    disCountPrice =
                      rowData?.price -
                      (rowData?.price * parseInt(dealPrice, 10)) / 100;
                  } else if (dealType === "off") {
                    disCountPrice = rowData?.price - parseInt(dealPrice, 10);
                  } else {
                    disCountPrice = rowData?.price;
                  }
                  return rowData?.deal
                    ? disCountPrice + " NGN"
                    : rowData?.price + " NGN";
                }}
              />
              <Column
                field="category"
                body={categoryBodyTemplate}
                header="Category"
                sortable
              />
            </DataTable>
          </div>
        </div>

        <div className="col-12 xl:col-6">
          <DashBoardTopBrandsCards brands={brands} />
        </div>
      </div>
    </>
  );
};
