import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { OrderService } from "../service/OrderService";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { ProgressBar } from "primereact/progressbar";
import { setOrders } from "../store/slices";
import { useDispatch } from "react-redux";
import socket from "../socket";
import moment from "moment";
import { ProductTable } from "./ProductTable";

export const OrdersTable = () => {
  const toast = useRef(null);
  const [orderDetails, setOrderDetails] = useState([]);
  const [currentOrderDetails, setCurrentOrderDetails] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [cancelValue, setCancelValue] = useState("");
  const [refundValue, setRefundValue] = useState("");
  const [shippingIDValue, setShippingIDValue] = useState("");
  const [currentProductDetails, setCurrentProductDetails] = useState("");
  const dealType = currentProductDetails?.itemDeal?.split(" ")[1];
  const dealPrice = currentProductDetails?.itemDeal?.split(" ")[0];
  let disCountPrice;
  if (dealType === "%") {
    disCountPrice =
      currentProductDetails?.itemPrice -
      (currentProductDetails?.itemPrice * parseInt(dealPrice, 10)) / 100;
  } else if (dealType === "off") {
    disCountPrice = currentProductDetails?.itemPrice - parseInt(dealPrice, 10);
  } else {
    disCountPrice = currentProductDetails?.itemPrice;
  }
  const menu = useRef(null);
  const items = [
    {
      label: "Update Order Status",
      icon: "pi pi-refresh",
      command: () => {
        setStatusModal(true);
      },
    },
  ];
  const citems = [
    {
      label: "Process Refund",
      icon: "pi pi-refresh",
      command: () => {
        setRefundModal(true);
      },
    },
  ];
  const statuses = [
    { name: "Delivered" },
    { name: "Cancelled" },
    { name: "Shipped" },
  ];
  const paymentNotSuccess = [
    { name: "Change order type to COD" },
    { name: "Cancel order" },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    socket.on("new_order_done", (msg) => {
      getAllOrders();
    });

    (async () => {
      await getAllOrders();

      if (orderDetails.length === 0) {
        if (dt !== null && dt.current != null) {
          onDateChangeUseeffect([
            moment().startOf("month").toDate(),
            moment().toDate(),
          ]);
        }
      }
    })();
  }, []);

  const getAllOrders = () => {
    setOrderDetails([]);
    const orderService = new OrderService();

    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/orders",
      headers: {
        "Content-Type": "application/json",
      },
    };
    orderService.getOrders(config).then((res) => {
      setOrderDetails(res.data);

      let orders =
        res.data && res.data.length > 0
          ? res.data.filter((item) =>
              item.orderStatus == "Ordered" ? item : null
            )
          : [];

      dispatch(setOrders(orders));
      // console.log("orderDetails", orders);
    });
  };

  const updateOrder = () => {
    const orderService = new OrderService();

    const postData = {
      type: "Ordered",
      itemId: currentOrderDetails["ItemsOrdered"]["items"][0]._id,
      item: currentOrderDetails["ItemsOrdered"]["items"][0].itemName,
      name: currentOrderDetails["userDetails"].name,
      email: currentOrderDetails["userDetails"].email,
      message: "Your order has been " + selectedStatus?.name,
      status: "Active",
      userId: currentOrderDetails["userDetails"]._id,
    };

    const notify = {
      method: "post",
      url: process.env.REACT_APP_APIBASE + "home/add_notifications",
      //url: process.env.REACT_APP_APIBASE+"home/add_notifications",
      headers: {
        "Content-Type": "application/json",
      },
      data: postData,
    };

    orderService.addNotify(notify).then((data) => {
      console.log(data);
    });

    if (
      currentOrderDetails &&
      currentOrderDetails["orderStatus"] != "Pending"
    ) {
      let body = {};
      if (selectedStatus && selectedStatus.name === "Cancelled") {
        body = {
          orderStatus: "Cancelled",
          cancellationReason: cancelValue,
          ordercancelled: {
            at: moment().toDate(),
          },
        };
      }
      if (selectedStatus && selectedStatus.name === "Delivered") {
        body = {
          orderStatus: "Delivered",
          orderdelivered: {
            at: moment().toDate(),
          },
        };
      }
      if (selectedStatus && selectedStatus.name === "Shipped") {
        body = {
          orderStatus: "Shipped",
          shippingCourierId: shippingIDValue,
          ordershipped: {
            at: moment().toDate(),
          },
        };
      }
      const config = {
        method: "put",
        url:
          process.env.REACT_APP_APIBASE +
          "home/order/" +
          currentOrderDetails["_id"],
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      };
      orderService.updateOrders(config).then((data) => {
        if (data.success) {
          setOrderDetails([]);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Order Status Updated Successfully",
            life: 3000,
          });
          const config = {
            method: "get",
            url: process.env.REACT_APP_APIBASE + "admin/orders",
            headers: {
              "Content-Type": "application/json",
            },
          };
          // orderService.getOrders(config).then((data) => {
          //     setOrderDetails(data.data);
          //     setStatusModal(false)

          //     let orders = data.data.length > 0 ? data.data.filter(item=> item.orderStatus == "Ordered" ? item : null) : [];

          //     dispatch(setOrders(orders));

          //     console.log("orders", orders);
          // })

          setTimeout(() => {
            getAllOrders();
          }, 3000);
          setStatusModal(false);
        }
      });
    } else {
      let body = {};
      if (selectedResponse && selectedResponse.name === "Cancel order") {
        body = {
          orderStatus: "Cancelled",
          cancellationReason: "Payment not Successful",
          ordercancelled: {
            at: moment().toDate(),
          },
        };
      }
      if (
        selectedResponse &&
        selectedResponse.name === "Change order type to COD"
      ) {
        body = {
          orderStatus: "Ordered",
          orderplaced: {
            at: moment().toDate(),
          },
          paymentType: "COD",
        };
      }

      const config = {
        method: "put",
        url:
          process.env.REACT_APP_APIBASE +
          "home/order/" +
          currentOrderDetails["_id"],
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      };
      orderService.updateOrders(config).then((data) => {
        if (data.success) {
          setOrderDetails([]);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Order Status Updated Successfully",
            life: 3000,
          });
          const config = {
            method: "get",
            url: process.env.REACT_APP_APIBASE + "admin/orders",
            headers: {
              "Content-Type": "application/json",
            },
          };
          // orderService.getOrders(config).then((data) => {
          //     setOrderDetails(data.data);

          //     let orders = data.data.length > 0 ? data.data.filter(item=> item.orderStatus == "Ordered" ? item : null) : [];

          //     dispatch(setOrders(orders));

          //     console.log("orders", orders);

          // })
          setTimeout(() => {
            getAllOrders();
          }, 3000);
          setStatusModal(false);
        }
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 7000);
  };
  // const processRefund = ()=>{

  // }
  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.orderStatus !== "Cancelled" &&
          rowData.orderStatus !== "Delivered" && (
            <div className="actions">
              <Menu
                model={items}
                popup
                ref={menu}
                id="popup_menu"
                style={{ fontSize: "11px" }}
              />
              <img
                style={{ width: 20 }}
                src="https://img.icons8.com/tiny-color/64/000000/menu-2.png"
                onClick={(event) => {
                  menu["current"].toggle(event);
                  setCurrentOrderDetails(rowData);
                }}
              />
            </div>
          )}
        {/* {rowData.orderStatus == "Cancelled" && (
                    <div className="actions">
                        <Menu model={citems} popup ref={menu} id="popup_menu" style={{ fontSize: "11px" }} />
                        <img
                            src="https://img.icons8.com/fluency/27/000000/menu-2.png"
                            onClick={(event) => {
                                menu["current"].toggle(event);
                                setCurrentOrderDetails(rowData);
                            }}
                        />
                    </div>
                )} */}
      </>
    );
  };
  const [selectedStatusF, setSelectedStatusF] = useState(null);
  const dt = useRef(null);
  const statusesF = ["Delivered", "Shipped", "Ordered", "Cancelled"];
  const statusItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option}
      </span>
    );
  };
  const onStatusChange = (e) => {
    // console.log(e.value);
    dt.current.filter(e.value, "orderStatus", "equals");
    setSelectedStatusF(e.value);
  };
  const statusFilter = (
    <Dropdown
      value={selectedStatusF}
      options={statusesF}
      onChange={onStatusChange}
      itemTemplate={statusItemTemplate}
      placeholder="Select a Status"
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedDate, setSelectedDate] = useState([
    moment().startOf("month").toDate(),
    moment().toDate(),
  ]);

  const formatDate = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return date.getFullYear() + "-" + month + "-" + day;
  };
  const [fs, setFs] = useState("");
  const codeFilter = (value, filter) => {
    var date1 = moment(value).toDate();
    var date2 = moment(filter.split(":")[0], "YYYY-MM-DD").toDate();
    var date3 = moment(filter.split(":")[1], "YYYY-MM-DD")
      .endOf("day")
      .toDate();

    // console.log(value, filter.split(":")[0], filter.split(":")[1]);
    // console.log(date1, date2, date3);

    // var date1 = new Date(value);
    // var date2 = new Date(filter.split(":")[0]);
    // var date3= new Date(filter.split(":")[1]);

    console.log(value, filter.split(":")[0], filter.split(":")[1]);
    console.log(date1, date2, date3);

    return (
      date1.valueOf() > date2.valueOf() && date1.valueOf() < date3.valueOf()
    );
  };
  const onDateChangeUseeffect = (e) => {
    let dat2 = moment(e[0]).format("YYYY-MM-DD");
    let dat1 = moment(e[1]).endOf("day").format("YYYY-MM-DD");

    dt.current.filter(dat2 + ":" + dat1, "orderplaced.at", "custom");
    setSelectedDate(e);
  };
  const onDateChange = (e) => {
    if (e.value[1] !== null) {
      let dat2 = moment(e.value[1]).endOf("day").format("YYYY-MM-DD");
      dt.current.filter(fs + ":" + dat2, "orderplaced.at", "custom");

      console.log("e.value[1]", e.value[1]);
      console.log("fs", fs);

      setSelectedDate(e.value);
      return;
    }
    if (e.value[0] !== null && e.value[1] === null) {
      let dat2 = moment(e.value[0]).format("YYYY-MM-DD");
      setFs(dat2);
      setSelectedDate(e.value);
      return;
    }
  };
  const reset = () => {
    setSelectedStatusF(null);
    setSelectedDate(undefined);
    setGlobalFilter("");
    dt.current.reset();
  };
  const header = (
    <div className="table-header">
      <Button
        type="button"
        label="Clear"
        className="p-button-outlined"
        icon="pi pi-filter-slash"
        onClick={reset}
      />
      <span className="p-input-icon-left" style={{ float: "right" }}>
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search"
          style={{ marginRight: "15px" }}
        />
        <Calendar
          readOnlyInput
          value={selectedDate}
          onChange={onDateChange}
          dateFormat="yy-mm-dd"
          showIcon
          selectionMode="range"
          className="p-column-filter"
          placeholder="Select Date"
          style={{
            marginRight: "5px",
            width: "257px",
            marginBottom: "5px",
            marginTop: "5px",
            marginLeft: "auto",
            fontSize: "12px !important",
          }}
        />
      </span>
    </div>
  );
  const dateFilter = (
    <Calendar
      readOnlyInput
      value={selectedDate}
      onChange={onDateChange}
      dateFormat="yy-mm-dd"
      selectionMode="range"
      className="p-column-filter"
      placeholder="Select Date"
      style={{ display: "none" }}
    />
  );

  const priceBodyTemplate = (rowData) => {
    const dealType = rowData.itemDeal.split(" ")[1];
    const dealPrice = rowData.itemDeal.split(" ")[0];
    let disCountPrice;
    if (dealType === "%") {
      disCountPrice =
        rowData.itemPrice - (rowData.itemPrice * parseInt(dealPrice, 10)) / 100;
    } else if (dealType === "off") {
      disCountPrice = rowData.itemPrice - parseInt(dealPrice, 10);
    } else {
      disCountPrice = rowData.itemPrice;
    }
    return <>{disCountPrice}</>;
  };

  console.log("orderDetails", orderDetails);

  return (
    <>
      <Toast ref={toast} />
      <h1 style={{ fontSize: "16px" }}>Orders</h1>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <DataTable
              ref={dt}
              style={{
                wordBreak: "break-word",
                verticalAlign: "top",
              }}
              value={orderDetails}
              globalFilter={globalFilter}
              header={header}
              dataKey="_id"
              className="datatable-responsive"
              rows={10}
              paginator
            >
              <Column
                field="_id"
                header="Order ID"
                style={{ fontSize: "12px", overflowWrap: "break-word" }}
                body={(rowData) => (
                  <>
                    <span style={{ fontSize: "12px" }}>
                      {rowData && rowData._id && rowData._id}
                    </span>
                  </>
                )}
              />
              <Column
                field="orderStatus"
                header="Order Status"
                body={(rowData) => {
                  return (
                    <>
                      <span className="p-column-title">Order Status</span>
                      {rowData.orderStatus}
                    </>
                  );
                }}
                filter
                filterElement={statusFilter}
                style={{ fontSize: "12px" }}
              />
              <Column
                field="orderplaced.at"
                header="Order Placed on"
                filter
                filterElement={dateFilter}
                filterFunction={codeFilter}
                style={{ fontSize: "12px" }}
                body={(rowData) => (
                  <>
                    <span style={{ fontSize: "12px" }}>
                      {rowData &&
                        rowData.orderplaced &&
                        rowData.orderplaced.at &&
                        moment(rowData.orderplaced.at).format("YYYY-MM-DD")}
                    </span>
                  </>
                )}
              />
              <Column
                field="paymentsDetails.totalCharges"
                header="Order Amount"
                sortable
                style={{ fontSize: "12px" }}
              />
              <Column
                field="paymentType"
                header="Payment Method"
                sortable
                style={{ fontSize: "12px" }}
              />
              <Column
                field="orderdelivered.at"
                header="Delivered Date"
                sortable
                style={{ fontSize: "12px" }}
                body={(rowData) => (
                  <>
                    <span style={{ fontSize: "12px" }}>
                      {rowData &&
                      rowData.orderdelivered &&
                      rowData.orderdelivered.at
                        ? rowData.orderdelivered.at.split("T")[0]
                        : "Not Delivered"}
                    </span>
                  </>
                )}
              />
              <Column
                field="orderShipped.at"
                header="Shipping Status"
                style={{ fontSize: "12px", width: 200 }}
                body={(rowData) => (
                  <span style={{ fontSize: "12px" }}>
                    {rowData && rowData.ordershipped && rowData.ordershipped.at
                      ? `Shipped on: \n
                                        ${
                                          rowData.ordershipped.at.split("T")[0]
                                        }`
                      : "Not Shipped"}
                  </span>
                )}
              />
              <Column
                header="View"
                style={{ fontSize: "12px" }}
                body={(rowData) => (
                  <>
                    <Button
                      icon="pi pi-search"
                      type="button"
                      className="p-button-text"
                      onClick={() => {
                        setCurrentOrderDetails(rowData);
                        setOpenModal(true);
                      }}
                    />
                  </>
                )}
              />
              <Column
                body={actionBodyTemplate}
                style={{ fontSize: "12px" }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>
      {orderDetails && orderDetails.length === 0 && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: "6px" }}
        ></ProgressBar>
      )}
      {console.log('currentOrderDetails', currentOrderDetails)}
      <Dialog
        header="Order Details"
        visible={openModal}
        style={{ width: "50vw" }}
        onHide={() => setOpenModal(false)}
        modal
      >
        <h1 style={{ fontSize: "14px", color: "black" }}>User Details</h1>
        <Divider />

        {currentOrderDetails &&
          currentOrderDetails["userDetails"] &&
          currentOrderDetails["userDetails"]?.name && (
            <ProductTable
              c1="Name"
              c2={currentOrderDetails["userDetails"]?.name}
              c3={currentOrderDetails["userDetails"]?.name}
            />
          )}
        {currentOrderDetails &&
          currentOrderDetails["userDetails"] &&
          currentOrderDetails["userDetails"]?.email && (
            <ProductTable
              c1="Email"
              c2={currentOrderDetails["userDetails"]?.email}
              c3={currentOrderDetails["userDetails"]?.email}
            />
          )}
        {currentOrderDetails &&
          currentOrderDetails["userDetails"] &&
          currentOrderDetails["userDetails"]?.gender && (
            <ProductTable
              c1="Gender"
              c2={currentOrderDetails["userDetails"]?.gender}
              c3={currentOrderDetails["userDetails"]?.gender}
            />
          )}
        {currentOrderDetails &&
          currentOrderDetails["userDetails"] &&
          currentOrderDetails["userDetails"]?.dob && (
            <ProductTable
              c1="Date of birth"
              c2={currentOrderDetails["userDetails"]?.dob}
              c3={currentOrderDetails["userDetails"]?.dob}
            />
          )}
        <Divider />
        <h1 style={{ fontSize: "14px", color: "black" }}>Shipping Address</h1>
        <Divider />
        {currentOrderDetails &&
        currentOrderDetails["shippingAddress"] &&
        currentOrderDetails["shippingAddress"]?.addressLine1 ? (
          <ProductTable
            c1="Address line 1"
            c2={currentOrderDetails["shippingAddress"]?.addressLine1}
            c3={currentOrderDetails["shippingAddress"]?.addressLine1}
          />
        ) : (
          <ProductTable
            c1="Address line 1"
            c2={"Not available"}
            c3={"Not available"}
          />
        )}
        {currentOrderDetails &&
        currentOrderDetails["shippingAddress"] &&
        currentOrderDetails["shippingAddress"]?.addressLine2 ? (
          <ProductTable
            c1="Address line 2"
            c2={currentOrderDetails["shippingAddress"]?.addressLine2}
            c3={currentOrderDetails["shippingAddress"]?.addressLine2}
          />
        ) : (
          <ProductTable
            c1="Address line 2"
            c2={"Not available"}
            c3={"Not available"}
          />
        )}
        {currentOrderDetails &&
          currentOrderDetails["shippingAddress"] &&
          currentOrderDetails["shippingAddress"].city && (
            <ProductTable
              c1="City"
              c2={currentOrderDetails["shippingAddress"]?.city}
              c3={currentOrderDetails["shippingAddress"]?.city}
            />
          )}
        {currentOrderDetails &&
          currentOrderDetails["shippingAddress"] &&
          currentOrderDetails["shippingAddress"].state && (
            <ProductTable
              c1="State"
              c2={currentOrderDetails["shippingAddress"]?.state}
              c3={currentOrderDetails["shippingAddress"]?.state}
            />
          )}
        {currentOrderDetails &&
          currentOrderDetails["shippingAddress"] &&
          currentOrderDetails["shippingAddress"].country && (
            <ProductTable
              c1="Country"
              c2={currentOrderDetails["shippingAddress"]?.country}
              c3={currentOrderDetails["shippingAddress"]?.country}
            />
          )}
        {currentOrderDetails &&
          currentOrderDetails["shippingAddress"] &&
          currentOrderDetails["shippingAddress"].postalCode && (
            <ProductTable
              c1="Postal Code"
              c2={currentOrderDetails["shippingAddress"]?.postalCode}
              c3={currentOrderDetails["shippingAddress"]?.postalCode}
            />
          )}
        <Divider />
        <h1 style={{ fontSize: "14px", color: "black" }}>Payment Details</h1>
        <Divider />
        {currentOrderDetails && currentOrderDetails["paymentStatus"] && (
          <ProductTable
            c1="Payment Status"
            c2={currentOrderDetails["paymentStatus"]}
            c3={currentOrderDetails["paymentStatus"]}
          />
        )}
        {currentOrderDetails && currentOrderDetails["paymentType"] && (
          <ProductTable
            c1="Payment Type"
            c2={currentOrderDetails["paymentType"]}
            c3={currentOrderDetails["paymentType"]}
          />
        )}

        {currentOrderDetails &&
          currentOrderDetails["paymentsDetails"].sub_total && (
            <ProductTable
              c1="Product Price"
              c2={currentOrderDetails["paymentsDetails"].sub_total}
              c3={currentOrderDetails["paymentsDetails"].sub_total}
            />
          )}

        {currentOrderDetails &&
          currentOrderDetails["paymentsDetails"] &&
          currentOrderDetails["paymentsDetails"].taxAmount && (

            <ProductTable
              c1="Tax Amount"
              c2={currentOrderDetails["paymentsDetails"].taxAmount}
              c3={currentOrderDetails["paymentsDetails"].taxAmount}
            />
          )}
        {currentOrderDetails &&
          currentOrderDetails["paymentsDetails"] &&
          currentOrderDetails["paymentsDetails"].totalCharges && (
            <ProductTable
              c1="Shipping Fee"
              c2={currentOrderDetails["paymentsDetails"].shippingCharges}
              c3={currentOrderDetails["paymentsDetails"].shippingCharges}
            />
          )}
        {currentOrderDetails &&
          currentOrderDetails["paymentsDetails"] &&
          currentOrderDetails["paymentsDetails"].totalCharges && (
            <ProductTable
              c1="Total Amount"
              c2={currentOrderDetails["paymentsDetails"].totalCharges}
              c3={currentOrderDetails["paymentsDetails"].totalCharges}
            />
          )}
        {currentOrderDetails &&
          currentOrderDetails["paymentsDetails"] &&
          currentOrderDetails["paymentsDetails"].currency && (
            <ProductTable
              c1="Currency"
              c2={currentOrderDetails["paymentsDetails"].currency}
              c3={currentOrderDetails["paymentsDetails"].currency}
            />
          )}
        <Divider />
        <h1 style={{ fontSize: "14px", color: "black" }}>Purchased Items</h1>
        <Divider />
        {currentOrderDetails &&
          currentOrderDetails["ItemsOrdered"] &&
          currentOrderDetails["ItemsOrdered"]["items"] && (
            <DataTable value={currentOrderDetails["ItemsOrdered"]["items"]}>
              <Column
                field="itemName"
                header="Name"
                sortable
                body={(rowData) => (
                  <span
                    onClick={() => {
                      setCurrentProductDetails(rowData);
                      console.log(rowData);
                      setOpenProductModal(true);
                      setOpenModal(false);
                    }}
                    style={{
                      fontSize: "12px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {rowData.itemName.substring(0, 20)}
                  </span>
                )}
              ></Column>
              <Column
                field="itemPrice"
                header="Discount Price"
                body={priceBodyTemplate}
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="itemPrice"
                header="Price"
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="itemCategory"
                body={(rowData) => <>{rowData.itemCategory.name}</>}
                header="Category"
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="itemBrand"
                body={(rowData) => <>{rowData.itemBrand?.name}</>}
                header="Brand"
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="quantity"
                header="Quantity"
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="itemDeal"
                header="Deal"
                style={{ fontSize: "12px" }}
              ></Column>
            </DataTable>
          )}

        <Divider />
        {currentOrderDetails &&
          currentOrderDetails["ordercancelled"] &&
          currentOrderDetails["cancellationReason"] != "" && (
            <h1 style={{ fontSize: "14px", color: "black" }}>
              Cancellation Reason
              <h1 style={{ fontSize: "12px", color: "#898080" }}>
                Order Cancelled on:{" "}
              </h1>
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["ordercancelled"]["at"]}
              </span>
            </h1>
          )}
        {currentOrderDetails &&
          currentOrderDetails["cancellationReason"] != "" && (
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              <span style={{ fontSize: "12px", color: "#bcb7b7" }}>
                {currentOrderDetails["cancellationReason"]}
              </span>
            </h1>
          )}
      </Dialog>

      <Dialog
        header="Product Details"
        visible={openProductModal}
        style={{ width: "50vw" }}
        onHide={() => setOpenProductModal(false)}
        modal
      >
        <div style={{ textAlign: "center" }}>
          {currentProductDetails &&
            currentProductDetails?.itemImages.map((item, index) => {
              return (
                <img
                  style={{
                    width: 180,
                    margin: 10,
                    objectFit: "contain",
                  }}
                  src={item}
                />
              );
            })}
        </div>
        <ProductTable
          c1="Name"
          c2={currentProductDetails && currentProductDetails?.itemName}
          c3={currentProductDetails?.itemName}
        />
        <ProductTable
          c1="Brand"
          c2={currentProductDetails && currentProductDetails?.itemBrand}
          c3={currentProductDetails?.itemBrand?.name}
        />
        <ProductTable
          c1="Catergory"
          c2={currentProductDetails && currentProductDetails?.itemCategory}
          c3={currentProductDetails?.itemCategory?.name}
        />
        <ProductTable
          c1="Price"
          c2={currentProductDetails && currentProductDetails?.itemPrice}
          c3={
            currentProductDetails?.itemDeal
              ? disCountPrice + " NGN"
              : currentProductDetails?.itemPrice + " NGN"
          }
        />
        <ProductTable
          c1="Deals"
          c2={currentProductDetails && currentProductDetails?.itemDeal}
          c3={currentProductDetails?.itemDeal}
        />
        <ProductTable
          c1="Quantity"
          c2={currentProductDetails && currentProductDetails?.quantity}
          c3={currentProductDetails?.quantity}
        />{" "}
        {/*<ProductTable*/}
        {/*    c1="Rating"*/}
        {/*    c2={currentProductDetails && currentProductDetails?.rating}*/}
        {/*    c3={currentProductDetails?.rating}*/}
        {/*/>*/}
      </Dialog>

      <Dialog
        header="Change Order Status"
        visible={statusModal}
        style={{ width: "50vw" }}
        onHide={() => setStatusModal(false)}
        modal
      >
        {currentOrderDetails && currentOrderDetails["orderStatus"] && (
          <h1 style={{ fontSize: "12px", color: "#898080" }}>
            Current order status:{" "}
            <span style={{ fontSize: "12px", color: "black" }}>
              {currentOrderDetails["orderStatus"]}
            </span>
          </h1>
        )}

        {currentOrderDetails &&
          currentOrderDetails["orderStatus"] != "Pending" && (
            <>
              <h1 style={{ fontSize: "12px", color: "#898080" }}>
                Available order status:{" "}
              </h1>{" "}
            </>
          )}
        {currentOrderDetails &&
          currentOrderDetails["orderStatus"] != "Pending" && (
            <Dropdown
              value={selectedStatus}
              options={statuses}
              onChange={(e) => {
                setSelectedStatus(e.value);
              }}
              optionLabel="name"
              placeholder="Select a Status"
              style={{ width: "100%", fontSize: "12px" }}
            />
          )}
        {selectedStatus && selectedStatus.name === "Cancelled" && (
          <>
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              Cancellation reason:{" "}
            </h1>
            <InputTextarea
              value={cancelValue}
              onChange={(e) => setCancelValue(e.target.value)}
              rows={5}
              style={{ width: "100%" }}
            />
          </>
        )}
        {selectedStatus && selectedStatus.name === "Shipped" && (
          <>
            <h1 style={{ fontSize: "12px", color: "#898080" }}>
              Courier Shipping ID:{" "}
            </h1>
            <InputText
              value={shippingIDValue}
              onChange={(e) => setShippingIDValue(e.target.value)}
              style={{ width: "100%" }}
            />
          </>
        )}
        {currentOrderDetails &&
          currentOrderDetails["orderStatus"] === "Pending" && (
            <>
              <h1 style={{ fontSize: "12px", color: "#898080" }}>
                Payment Not Successful:{" "}
              </h1>
              <Dropdown
                value={selectedResponse}
                options={paymentNotSuccess}
                onChange={(e) => {
                  console.log(e.value);
                  setSelectedResponse(e.value);
                }}
                optionLabel="name"
                placeholder="Select a Response"
                style={{ width: "100%", fontSize: "12px" }}
              />
            </>
          )}
        <div style={{ float: "right" }}>
          <Button
            label="Update"
            icon="pi pi-check"
            className="p-button-sm"
            style={{ marginTop: "30px" }}
            onClick={updateOrder}
          />
        </div>
      </Dialog>

      {/* <Dialog header="Process Refund" visible={refundModal} style={{ width: "50vw" }} onHide={() => setRefundModal(false)} modal>
                {currentOrderDetails && currentOrderDetails["orderStatus"] && (
                    <h1 style={{ fontSize: "12px", color: "#898080" }}>
                        Current order status: <span style={{ fontSize: "12px", color: "black" }}>{currentOrderDetails["orderStatus"]}</span>
                    </h1>
                )}
                {currentOrderDetails && currentOrderDetails["paymentType"]!=='COD' && (
                    <>
                        <h1 style={{ fontSize: "12px", color: "#898080" }}>Refund Amount: </h1>
                        <InputText value={refundValue} onChange={(e) => setRefundValue(e.target.value)} rows={5} style={{ width: "100%" }} />
                    </>
                )}
                <div style={{ float: "right" }}>
                    <Button label="Update" icon="pi pi-check" className="p-button-sm" style={{ marginTop: "30px" }} onClick={processRefund} />
                </div>
            </Dialog> */}
    </>
  );
};
