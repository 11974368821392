import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CategoryService } from "../service/CategoryService";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { PreSignedUrlService } from "../service/PreSignedUrlService";
import axios from "axios";
import { CustomLoader } from "../loader";
import { ProductTable } from "./ProductTable";

export const CategoryTable = () => {
  let emptyCategory = {
    name: "",
    images: null,
  };
  let emptyProduct = {
    name: "",
    images: null,
    category: "",
    deal: "",
    brand: "",
    price: "",
    quantity: "",
  };
  const [category, setCategory] = useState(null);
  const toast = useRef(null);
  const [deleteCategoryDialog, setDeleteCategoryDialog] = useState(false);
  const [relistCategoryDialog, setRelistCategoryDialog] = useState(false);
  const [activeCategoryDialog, setActiveCategoryDialog] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState(emptyCategory);
  const [edit, setEditUpdate] = useState(false);
  const [images, setImage] = useState("");
  const [cid, setCid] = useState("");
  const [icon, setIcon] = useState("");
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [setInventory, setInventoryDetails] = useState(null);
  const [openModalP, setOpenModalP] = useState(false);
  const [currentProductDetails, setCurrentProductDetails] = useState(null);
  const dealType = currentProductDetails?.deal?.split(" ")[1];
  const dealPrice = currentProductDetails?.deal?.split(" ")[0];
  let disCountPrice;
  if (dealType === "%") {
    disCountPrice =
      currentProductDetails?.price -
      (currentProductDetails?.price * parseInt(dealPrice, 10)) / 100;
  } else if (dealType === "off") {
    disCountPrice = currentProductDetails?.price - parseInt(dealPrice, 10);
  } else {
    disCountPrice = currentProductDetails?.price;
  }

  useEffect(() => {
    const categoryService = new CategoryService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/getCategories",
      //url: process.env.REACT_APP_APIBASE+'admin/getCategories',
      headers: {
        "Content-Type": "application/json",
      },
    };
    categoryService
      .getCategories(config)
      .then((data) => setCategory(data.reverse()));
  }, []);

  useEffect(() => {
    const categoryService = new CategoryService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "admin/getCategories",
      //url: process.env.REACT_APP_APIBASE+'admin/getCategories',
      headers: {
        "Content-Type": "application/json",
      },
    };
    categoryService
      .getCategories(config)
      .then((data) => setCategory(data.reverse()));
  }, [
    deleteCategoryDialog,
    relistCategoryDialog,
    edit,
    categoryDetails,
    activeCategoryDialog,
  ]);
  const activestatusBodyTemplate = (rowData) => {
    if (rowData.status == "Active") {
      return (
        <>
          <span className={`product-badge status-instock`}>
            {rowData.status}
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className={`product-badge status-outofstock`}>Not Active</span>
        </>
      );
    }
  };

  const hideDeleteCategoryDialog = () => {
    setDeleteCategoryDialog(false);
  };
  const hideActiveCategoryDialog = () => {
    setActiveCategoryDialog(false);
  };
  const hideRelistCategoryDialog = () => {
    setRelistCategoryDialog(false);
  };
  const deleteCategory = () => {
    const categoryService = new CategoryService();
    const config = {
      method: "delete",
      url: process.env.REACT_APP_APIBASE + "admin/category/" + cid,
      headers: {
        "Content-Type": "application/json",
      },
      data: { _id: cid },
    };
    categoryService.deleteCategory(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Category delete successfully",
          life: 3000,
        });
        setDeleteCategoryDialog(false);
        setCategoryDetails(emptyCategory);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while delete category",
          life: 3000,
        });
      }
    });
  };

  const activeCategory = () => {
    const categoryService = new CategoryService();
    const config = {
      method: "put",
      url: process.env.REACT_APP_APIBASE + "admin/category/" + cid,
      headers: {
        "Content-Type": "application/json",
      },
      data: { status: "Active" },
    };
    categoryService.deleteCategory(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Category unlisted successfully",
          life: 3000,
        });
        setActiveCategoryDialog(false);
        setCategoryDetails(emptyCategory);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while unlisting category",
          life: 3000,
        });
      }
    });
  };

  const relistCategory = () => {
    const categoryService = new CategoryService();
    const config = {
      method: "put",
      url: process.env.REACT_APP_APIBASE + "admin/category/" + cid,
      headers: {
        "Content-Type": "application/json",
      },
      data: { status: "Not Active" },
    };
    categoryService.relistCategory(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Category relisted successfully",
          life: 3000,
        });
        setRelistCategoryDialog(false);
        setCategoryDetails(emptyCategory);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while relisting category",
          life: 3000,
        });
      }
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _category = { ...categoryDetails };
    _category[`${name}`] = val;

    setCategoryDetails(_category);
  };

  const [categoryUpdateDialog, setCategoryUpdateDialog] = useState(false);
  const updateCategory = () => {
    const categoryService = new CategoryService();

    let errors = {};
    if (!categoryDetails.name) {
      errors.name = "Name is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.name,
        life: 3000,
      });
      return;
    }

    const body = {
      name: categoryDetails.name,
      images: images,
      icon: icon,
    };
    // return console.log('images in save', images);
    if (edit === true) {
      const config = {
        method: "put",
        url: process.env.REACT_APP_APIBASE + "admin/category/update/" + cid,
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      };
      categoryService.updateCategory(config).then((data) => {
        if (data.status === "success") {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Category updated successfully",
            life: 3000,
          });
          setCategoryUpdateDialog(false);
          setCategoryDetails(emptyCategory);
          setEditUpdate(false);
          setCid("");
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error while updating category",
            life: 3000,
          });
        }
      });
      return;
    }
  };

  const onTemplateUpload = (e) => {
    onUpload(e.files);
  };
  const onUpload = (e) => {
    setLoader(true);
    var data = new FormData();
    data.append("image", e[0]);

    const config = {
      method: "post",
      url: process.env.REACT_APP_APIBASE + "home/util/presignedurl",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        let { url } = response.data;

        setImage(url[0]);
        setLoader(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Image uploaded successfully",
          life: 3000,
        });
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while upload image",
          life: 3000,
        });
      });

    // const data = {
    //     "fileName":e[0].name,
    //     "fileType":e[0].type.split("/")[1],
    // }
    // const objectUrl = e[0]
    // const type = e[0].type

    // const config = {
    //     method: 'post',
    //     url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
    //     //url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
    //     headers: {
    //         'Content-Type' : 'application/json'
    //     },
    //     data: data
    // }
    // const preSignedUrlService = new PreSignedUrlService();
    // preSignedUrlService.generatePreSignedUrl(config).then(data => {if (data.url !== "") {
    //     setImage(data.url);
    //     preSignedUrlService.uploadOnGCP(data.url,objectUrl,type ).then(resp =>{
    //         toast.current.show({severity:'success', summary: 'Success', detail:'Image uploaded successfully', life: 3000});
    //     }).catch((error)=>{
    //         toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
    //     })
    // }else{
    //     toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload image', life: 3000});
    // }});
  };
  const onCategoryIconUpload = (e) => {
    onUploadIcon(e.files);
  };
  const onUploadIcon = (e) => {
    setLoader(true);
    var data = new FormData();
    data.append("image", e[0]);

    const config = {
      method: "post",
      url: process.env.REACT_APP_APIBASE + "home/util/presignedurl",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        let { url } = response.data;

        // console.log('url', url);

        setIcon(url[0]);
        setLoader(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Icon uploaded successfully",
          life: 3000,
        });
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while upload icon",
          life: 3000,
        });
      });

    // const data = {
    //     "fileName":e[0].name,
    //     "fileType":e[0].type.split("/")[1],
    // }
    // const objectUrl = e[0]
    // const type = e[0].type

    // const config = {
    //     method: 'post',
    //     url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
    //     //url: process.env.REACT_APP_APIBASE+'home/util/presignedurl',
    //     headers: {
    //         'Content-Type' : 'application/json'
    //     },
    //     data: data
    // }
    // const preSignedUrlService = new PreSignedUrlService();
    // preSignedUrlService.generatePreSignedUrl(config).then(data => {if (data.url !== "") {
    //     setIcon(data.url);
    //     preSignedUrlService.uploadOnGCP(data.url,objectUrl,type ).then(resp =>{
    //         toast.current.show({severity:'success', summary: 'Success', detail:'Icon uploaded successfully', life: 3000});
    //     }).catch((error)=>{
    //         toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload icon', life: 3000});
    //     })
    // }else{
    //     toast.current.show({severity:'error', summary: 'Error', detail:'Error while upload icon', life: 3000});
    // }});
  };

  const categoryUpdateDialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          setCategoryUpdateDialog(false);
        }}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={updateCategory}
      />
    </>
  );

  const deleteCategoryDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteCategoryDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteCategory}
      />
    </>
  );

  const activeCategoryDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideActiveCategoryDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={activeCategory}
      />
    </>
  );
  const relistCategoryDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideRelistCategoryDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={relistCategory}
      />
    </>
  );

  const editCategory = (category) => {
    setCategoryDetails({ ...category });
    setEditUpdate(true);
    setCid(category._id);
    // console.log(category, category.images);
    setImage(category.images);
    setIcon(category.icon);
    setCategoryUpdateDialog(true);
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editCategory(rowData)}
        />
        {rowData.status == "Active" && (
          <Button
            icon="pi pi-lock "
            className="p-button-rounded p-button-warning mr-2"
            onClick={() => {
              setCategoryDetails(rowData);
              setCid(rowData["_id"]);
              setActiveCategoryDialog(true);
            }}
          />
        )}
        {rowData.status == "Not Active" && (
          <Button
            icon="pi pi-unlock"
            className="p-button-rounded p-button-info mr-2"
            onClick={() => {
              setCategoryDetails(rowData);
              setCid(rowData["_id"]);
              setRelistCategoryDialog(true);
            }}
          />
        )}
        {/* { <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2" onClick={() => {setCategoryDetails(rowData);setCid(rowData["_id"]);setDeleteCategoryDialog(true);}} />} */}
      </div>
    );
  };

  const [selectedStatF, setSelectedStatF] = useState(null);
  const dt = useRef(null);
  const statItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option}
      </span>
    );
  };
  const onStatChange = (e) => {
    dt.current.filter(e.value, "status", "equals");
    setSelectedStatF(e.value);
  };
  const statFilter = (
    <Dropdown
      value={selectedStatF}
      options={["Active", "Not Active"]}
      onChange={onStatChange}
      itemTemplate={statItemTemplate}
      placeholder="Select a Status"
      option
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const reset = () => {
    setGlobalFilter("");
    dt.current.reset();
  };
  const header = (
    <div className="table-header">
      <Button
        type="button"
        label="Clear"
        className="p-button-outlined"
        icon="pi pi-filter-slash"
        onClick={reset}
      />
      <span className="p-input-icon-left" style={{ float: "right" }}>
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search"
          style={{ marginRight: "15px" }}
        />
      </span>
    </div>
  );

  const getProduct = (rowData) => {
    const id = rowData._id;
    const categoryService = new CategoryService();
    const config = {
      method: "get",
      url: process.env.REACT_APP_APIBASE + "home/item?category=" + id,
      headers: {
        "Content-Type": "application/json",
      },
    };
    categoryService.getCategories(config).then((data) => {
      if (data) {
        setInventoryDetails(data);
      }
    });
  };

  const onOpenModal = (rowData) => {
    setOpenModal(true);
    setInventoryDetails(emptyProduct);
    getProduct(rowData);
  };

  const setCurrentProduct = (rowData) => {
    setCurrentProductDetails(rowData);
    setOpenModal(false);
    setOpenModalP(true);
    console.log(rowData);
  };

  return (
    <>
      {loader && CustomLoader()}
      <Toast ref={toast} />
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <h5>Categories</h5>
            <DataTable
              ref={dt}
              value={category}
              className="datatable-responsive"
              rows={10}
              paginator
              globalFilter={globalFilter}
              header={header}
              dataKey="_id"
            >
              <Column
                header="Image"
                body={(data) => <img src={data.images} alt={data} width="50" />}
                style={{ fontSize: "12px", width: "15%" }}
              />
              <Column
                header="Icon"
                body={(data) => <img src={data.icon} alt={data} width="50" />}
                style={{ fontSize: "12px", width: "15%" }}
              />
              <Column
                field="name"
                header="Name"
                body={(rowData) => (
                  <>
                    <span
                      style={{
                        fontSize: "12px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        onOpenModal(rowData);
                      }}
                    >
                      {rowData && rowData.name}
                    </span>
                  </>
                )}
                sortable
                style={{ fontSize: "12px", width: "15%" }}
              />
              <Column
                field="added.at"
                header="Date"
                body={(rowData) => (
                  <>
                    <span style={{ fontSize: "12px" }}>
                      {rowData && new Date(rowData.added.at).toLocaleString()}
                    </span>
                  </>
                )}
                sortable
                style={{ fontSize: "12px", width: "15%" }}
              />
              <Column
                field="status"
                header="Status"
                body={activestatusBodyTemplate}
                filter
                filterElement={statFilter}
                style={{ fontSize: "12px", width: "15%" }}
              />
              <Column body={actionBodyTemplate}></Column>
            </DataTable>
          </div>
        </div>
      </div>

      <Dialog
        header="Inventory Details"
        visible={openModal}
        style={{ width: "70vw" }}
        onHide={() => setOpenModal(false)}
        modal
      >
        {setInventory &&
          setInventory["data"] &&
          setInventory["data"].length > 0 && (
            <DataTable value={setInventory["data"]} spinner rows={10}>
              <Column
                field="name"
                header="Name"
                body={(rowData) => (
                  <>
                    <span
                      style={{
                        fontSize: "12px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setCurrentProduct(rowData);
                      }}
                    >
                      {rowData && rowData.name}
                    </span>
                  </>
                )}
                sortable
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="price"
                header="Price"
                sortable
                style={{ fontSize: "12px" }}
                body={(rowData) => {
                  const dealType = rowData?.deal?.split(" ")[1];
                  const dealPrice = rowData?.deal?.split(" ")[0];
                  let disCountPrice;
                  if (dealType === "%") {
                    disCountPrice =
                      rowData?.price -
                      (rowData?.price * parseInt(dealPrice, 10)) / 100;
                  } else if (dealType === "off") {
                    disCountPrice = rowData?.price - parseInt(dealPrice, 10);
                  } else {
                    disCountPrice = rowData?.price;
                  }
                  return rowData?.deal
                    ? disCountPrice + " NGN"
                    : rowData?.price + " NGN";
                }}
              ></Column>
              <Column
                field="category"
                body={(rowData) => <>{rowData.category.name}</>}
                header="Category"
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="brand"
                body={(rowData) => <>{rowData.brand.name}</>}
                header="Brand"
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="quantity"
                header="Inventory"
                sortable
                style={{ fontSize: "12px" }}
              ></Column>
              <Column
                field="deal"
                header="Deal"
                style={{ fontSize: "12px" }}
              ></Column>
            </DataTable>
          )}
      </Dialog>

      <Dialog
        header="Product Details"
        visible={openModalP}
        style={{ width: "50vw" }}
        onHide={() => setOpenModalP(false)}
        modal
      >
        <div style={{ textAlign: "center" }}>
          {currentProductDetails &&
            currentProductDetails?.images.map((item, index) => {
              return (
                <img
                  style={{
                    width: 180,
                    margin: 10,
                    objectFit: "contain",
                  }}
                  src={item}
                />
              );
            })}
        </div>
        <ProductTable
          c1="Name"
          c2={currentProductDetails && currentProductDetails?.name}
          c3={currentProductDetails?.name}
        />
        <ProductTable
          c1="Brand"
          c2={currentProductDetails && currentProductDetails?.brand}
          c3={currentProductDetails?.brand.name}
        />
        <ProductTable
          c1="Catergory"
          c2={currentProductDetails && currentProductDetails?.category}
          c3={currentProductDetails?.category.name}
        />
        <ProductTable
          c1="Price"
          c2={currentProductDetails && currentProductDetails?.price}
          c3={
            currentProductDetails?.deal
              ? disCountPrice + " NGN"
              : currentProductDetails?.price + " NGN"
          }
        />
        <ProductTable
          c1="Cost"
          c2={currentProductDetails && currentProductDetails?.shop_price}
          c3={currentProductDetails?.shop_price + " NGN"}
        />
        <ProductTable
          c1="Deals"
          c2={currentProductDetails && currentProductDetails?.deal}
          c3={currentProductDetails?.deal}
        />
        <ProductTable
          c1="Quantity"
          c2={currentProductDetails && currentProductDetails?.quantity}
          c3={currentProductDetails?.quantity}
        />{" "}
        <ProductTable
          c1="Rating"
          c2={currentProductDetails && currentProductDetails?.rating}
          c3={currentProductDetails?.rating}
        />
        {/* {currentProductDetails && currentProductDetails.name &&
                    <h1 style={{fontSize: "12px", color: "#000"}}>Name: <span
                        style={{fontSize: "12px", color: "#bcb7b7"}}>{currentProductDetails.name}</span></h1>}
                {currentProductDetails && currentProductDetails.brand &&
                    <h1 style={{fontSize: "12px", color: "#000"}}>Brand: <span
                        style={{fontSize: "12px", color: "#bcb7b7"}}>{currentProductDetails.brand.name}</span></h1>}
                {currentProductDetails && currentProductDetails.price &&
                    <h1 style={{fontSize: "12px", color: "#000"}}>Price: <span
                        style={{fontSize: "12px", color: "#bcb7b7"}}>{currentProductDetails.price}</span></h1>}
                {currentProductDetails && currentProductDetails.category &&
                    <h1 style={{fontSize: "12px", color: "#000"}}>Category: <span
                        style={{fontSize: "12px", color: "#bcb7b7"}}>{currentProductDetails.category.name}</span></h1>}
                {currentProductDetails && currentProductDetails.deal &&
                    <h1 style={{fontSize: "12px", color: "#000"}}>Deal: <span
                        style={{fontSize: "12px", color: "#bcb7b7"}}>{currentProductDetails.name}</span></h1>}
                {currentProductDetails && currentProductDetails.quantity &&
                    <h1 style={{fontSize: "12px", color: "#000"}}>Quantity: <span
                        style={{fontSize: "12px", color: "#bcb7b7"}}>{currentProductDetails.quantity}</span></h1>}
                {currentProductDetails && currentProductDetails.shop_price &&
                    <h1 style={{fontSize: "12px", color: "#000"}}>Shop Price: <span
                        style={{fontSize: "12px", color: "#bcb7b7"}}>{currentProductDetails.shop_price}</span></h1>}
                {currentProductDetails && <h1 style={{fontSize: "12px", color: "#000"}}>Rating: <span
                    style={{fontSize: "12px", color: "#bcb7b7"}}>{currentProductDetails.rating}</span></h1>} */}
      </Dialog>

      <Dialog
        visible={categoryUpdateDialog}
        style={{ width: "550px" }}
        header="Category Details"
        modal
        className="p-fluid"
        footer={categoryUpdateDialogFooter}
        onHide={() => {
          setCategoryUpdateDialog(false);
        }}
      >
        {categoryDetails.images && (
          <img
            src={categoryDetails.images}
            alt={categoryDetails.images}
            width="150"
            className="mt-0 mx-auto mb-5 block shadow-2"
          />
        )}
        <div className="field col-12">
          <label htmlFor="categoryImage">Category image</label>
          <FileUpload
            name="demo[]"
            customUpload={true}
            uploadHandler={onTemplateUpload}
            onUpload={onTemplateUpload}
            accept="image/png,image/jpg,image/jpeg"
            maxFileSize={1000000}
            emptyTemplate={
              <p className="p-m-3" style={{ fontSize: "15px" }}>
                Drag and drop files to here to upload.
              </p>
            }
            auto
          />
        </div>
        {categoryDetails.icon && (
          <img
            src={categoryDetails.icon}
            alt={categoryDetails.icon}
            width="150"
            className="mt-0 mx-auto mb-5 block shadow-2"
          />
        )}
        <div className="field col-12">
          <label htmlFor="categoryImage">Category Icon</label>
          <FileUpload
            name="icon[]"
            customUpload={true}
            uploadHandler={onCategoryIconUpload}
            onUpload={onCategoryIconUpload}
            accept="image/png,image/jpg,image/jpeg"
            maxFileSize={1000000}
            emptyTemplate={
              <p className="p-m-3" style={{ fontSize: "15px" }}>
                Drag and drop files to here to upload.
              </p>
            }
            auto
          />
        </div>

        <div className="field">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            value={categoryDetails.name}
            onChange={(e) => onInputChange(e, "name")}
            required
            autoFocus
          />
        </div>
      </Dialog>
      <Dialog
        visible={deleteCategoryDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteCategoryDialogFooter}
        onHide={hideDeleteCategoryDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {categoryDetails && (
            <span>
              Are you sure you want to delete category{" "}
              <b>{categoryDetails.name}</b> ?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={activeCategoryDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={activeCategoryDialogFooter}
        onHide={hideActiveCategoryDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {categoryDetails && (
            <span>
              Are you sure you want to unlist category{" "}
              <b>{categoryDetails.name}</b> ?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={relistCategoryDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={relistCategoryDialogFooter}
        onHide={hideRelistCategoryDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {categoryDetails && (
            <span>
              Are you sure you want to relist category{" "}
              <b>{categoryDetails.name}</b> ?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};
