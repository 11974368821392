import axios from 'axios';

export class ProductService {

    getProduct(config) {
        return axios(config).then(res => res.data);
    }

    addProduct(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
    updateProduct(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
    deleteProduct(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
    relistProduct(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
}